<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: auto">
    <v-row no-gutters>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-card-title class="pb-2">
          <span>{{ $lang.header.event }} #{{ eventId }}</span>
          <v-btn
            icon
            color="primary"
            text
            large
            class="ml-2"
            @click="fetchEventData(eventId)"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            small
            @click="$emit('closeDialog', true)"
          >
            <v-icon size="22">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          absolute
          color="primary"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12">
        <v-tabs
          v-model="tab0"
          class="pb-2"
        >
          <v-tabs-slider color="accent"></v-tabs-slider>

          <v-tab
            :key="9"
          >
            {{ $lang.header.details }}
          </v-tab>

          <v-tab
            :key="0"
          >
            {{ $lang.header.input }}
          </v-tab>
          <v-tab
            :key="1"
          >
            {{ $lang.header.output }}
          </v-tab>
          <v-tab
            :key="2"
          >
            {{ $lang.header.exception }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab0">
          <v-tab-item
            :key="110"
            eager
          >
            <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters>
              <v-col cols="6">
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.status }}:</p> <p class="color-primary">{{ $lang.status[data.status] }}</p>
                </div>
                <br />
                <div v-if="data.parentEventId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentEvent }}:</p> <p class="color-primary clickable-simple" @click="openProcess()">{{ data.parentEventId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.parentEventId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.parentEventId" />
                <div v-if="data.triggerId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.triggerId }}:</p> <p class="color-primary clickable-simple" @click="openTriggerByTypeAndId(data.triggerId, data.type)">{{ data.triggerId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.triggerId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.triggerId" />
                <div v-if="data.processId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processId }}:</p> <p class="color-primary clickable-simple" @click="openProcessById(data.processId)">{{ data.processId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.processId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.processId" />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.priority }}:</p> <p class="color-primary">{{ data.priority ? data.priority : 64 }}</p><br />
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.eventCreatedOn }}:</p> <p class="color-primary">{{ data.createdOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processingStartOn }}:</p> <p class="color-primary">{{ data.processingStartOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.shouldFinishBy }}:</p> <p class="color-primary">{{ data.shouldFinishBy | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.heartbeat }}:</p> <p class="color-primary">{{ data.heartbeat | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.receivingInstanceId }}:</p> <p class="color-primary">{{ data.receivingInstanceId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.receivingInstanceId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br />
              </v-col>
              <v-col cols="6" class="pl-2">
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.type }}:</p> <p class="color-primary">{{ $lang.status[data.type] }}</p><br />
                </div>
                <br />
                <div v-if="data.triggerId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.triggerName }}:</p> <p class="color-primary clickable-simple" @click="openTriggerByTypeAndId(data.triggerId, data.type)">{{ triggerObj ? triggerObj.name : '' }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(triggerObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.triggerId" />
                <div v-if="data.processId && processObj" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processName }}:</p> <p class="color-primary clickable-simple" @click="openProcessById(data.processId)">{{ processObj.name }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(processObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.processId" />
                <div v-if="parentProcessObj" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentProcess }}:</p> <p class="color-primary clickable-simple" @click="openProcess()">{{ parentProcessObj.name }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(parentProcessObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="parentProcessObj" />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.respondSent }}:</p> <p class="color-primary">{{ data.respondSent ? isRespontSentAndWhen : $lang.labels.no }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.eventModifiedOn }}:</p> <p class="color-primary">{{ data.modifiedOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.estimatedTimeOfExecution }}:</p> <p class="color-primary">{{ estimatedTimeOfExecutionData }}</p>
                </div>
                <br />
                <div v-if="['FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(data.status)" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.finishedOn }}:</p> <p class="color-primary">{{ actualTimeOfExecutionData }}</p>
                </div>
                <br v-if="['FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(data.status)" />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.lastHeartbeatBefore }}:</p> <p class="color-primary">{{ lastHeartbeatBeforeData }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.instanceIdlong }}:</p> <p class="color-primary">{{ data.instanceId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.instanceId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="12">
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.status }}:</p> <p class="color-primary">{{ $lang.status[data.status] }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.type }}:</p> <p class="color-primary">{{ $lang.status[data.type] }}</p><br />
                </div>
                <br />
                <div v-if="data.triggerId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.triggerName }}:</p> <p class="color-primary clickable-simple" @click="openTriggerByTypeAndId(data.triggerId, data.type)">{{ triggerObj ? triggerObj.name : '' }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(triggerObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.triggerId" />
                <div v-if="data.triggerId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.triggerId }}:</p> <p class="color-primary clickable-simple" @click="openTriggerByTypeAndId(data.triggerId, data.type)">{{ data.triggerId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.triggerId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.triggerId" />
                <div v-if="data.processId && processObj" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processName }}:</p> <p class="color-primary clickable-simple" @click="openProcessById(data.processId)">{{ processObj.name }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(processObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.processId" />
                <div v-if="data.processId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processId }}:</p> <p class="color-primary clickable-simple" @click="openProcessById(data.processId)">{{ data.processId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.processId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.processId" />
                <div v-if="data.parentEventId" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentEvent }}:</p> <p class="color-primary clickable-simple" @click="openProcess()">{{ data.parentEventId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.parentEventId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="data.parentEventId" />
                <div v-if="parentProcessObj" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.parentProcess }}:</p> <p class="color-primary clickable-simple" @click="openProcess()">{{ parentProcessObj.name }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(parentProcessObj.name)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br v-if="parentProcessObj" />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.priority }}:</p> <p class="color-primary">{{ data.priority ? data.priority : 64 }}</p><br />
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.respondSent }}:</p> <p class="color-primary">{{ data.respondSent ? isRespontSentAndWhen : $lang.labels.no }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.eventCreatedOn }}:</p> <p class="color-primary">{{ data.createdOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.eventModifiedOn }}:</p> <p class="color-primary">{{ data.modifiedOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.processingStartOn }}:</p> <p class="color-primary">{{ data.processingStartOn | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.estimatedTimeOfExecution }}:</p> <p class="color-primary">{{ estimatedTimeOfExecutionData }}</p>
                </div>
                <br />
                <div v-if="['FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(data.status)" class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.finishedOn }}:</p> <p class="color-primary">{{ actualTimeOfExecutionData }}</p>
                </div>
                <br v-if="['FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(data.status)" />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.shouldFinishBy }}:</p> <p class="color-primary">{{ data.shouldFinishBy | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.heartbeat }}:</p> <p class="color-primary">{{ data.heartbeat | formatDateTimePrecise }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.lastHeartbeatBefore }}:</p> <p class="color-primary">{{ lastHeartbeatBeforeData }}</p>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.receivingInstanceId }}:</p> <p class="color-primary">{{ data.receivingInstanceId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.receivingInstanceId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
                <br />
                <div class="d-inline-flex">
                  <p class="color-secondary" style="width: 180px">{{ $lang.labels.instanceIdlong }}:</p> <p class="color-primary">{{ data.instanceId }}</p>
                  <v-btn
                    icon
                    color="primary"
                    class="ml-2"
                    style="margin-top: -8px"
                    @click="clipboardCopy(data.instanceId)"
                  >
                    <v-icon>mdi-vector-combine</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="1110"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs
                  v-model="tab1"
                  class="pb-2"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="220"
                  >
                    {{ $lang.labels.list }}
                  </v-tab>
                  <v-tab
                    :key="221"
                  >
                    {{ $lang.labels.json }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab1">
                  <v-tab-item
                    :key="220"
                    eager
                  >
                    <template v-for="(item, i) in inputArr">
                      <span v-if="item.value === ''" :key="i" class="headline">{{ `${item.text} / ` }}</span>
                      <p v-else :key="i" class="color-secondary">{{ item.text }}: <span class="color-primary">{{ item.value }}</span></p>
                    </template>
                  </v-tab-item>
                  <v-tab-item
                    :key="221"
                    eager
                  >
                    <v-lazy class="my-2">
                      <json-custom-viewer :data="input" @success="success = $event" />
                    </v-lazy>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="331"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-tabs
                  v-model="tab2"
                  class="pb-1"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="440"
                  >
                    {{ $lang.labels.list }}
                  </v-tab>
                  <v-tab
                    :key="441"
                  >
                    {{ $lang.labels.json }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab2">
                  <v-tab-item
                    :key="550"
                    eager
                  >
                    <template v-for="(item, i) in outputArr">
                      <span v-if="item.value === ''" :key="i" class="headline">{{ `${item.text} / ` }}</span>
                      <p v-else :key="i" class="color-secondary">{{ item.text }}: <span class="color-primary">{{ item.value }}</span></p>
                    </template>
                  </v-tab-item>
                  <v-tab-item
                    :key="661"
                    eager
                  >
                    <v-lazy class="my-2">
                      <json-custom-viewer :data="output" @success="success = $event" />
                    </v-lazy>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            :key="772"
            eager
          >
            <v-row no-gutters>
              <v-col cols="12">
                <p v-if="data && data.exceptionName" class="pl-2">{{ $lang.labels.exceptionName }}: {{ data.exceptionName }}</p>
                <v-tabs
                  v-model="tab3"
                  class="pb-1"
                >
                  <v-tabs-slider color="accent"></v-tabs-slider>

                  <v-tab
                    :key="880"
                  >
                    {{ $lang.header.exceptionMessage }}
                  </v-tab>
                  <v-tab
                    :key="881"
                  >
                    {{ $lang.header.exceptionStackTrace }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab3">
                  <v-tab-item
                    :key="880"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="pt-2">
                        <div class="calculated-height" style="overflow-y: auto;">
                          <v-tooltip v-if="exception && exception.exceptionMessage" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                text
                                large
                                v-bind="attrs"
                                v-on="on"
                                @click="clipboardCopy(exception.exceptionMessage)"
                              >
                                <v-icon>mdi-vector-combine</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $lang.labels.copy }}</span>
                          </v-tooltip>
                          <p class="color-primary font-change" v-html="exception && exception.exceptionMessage ? exception.exceptionMessage : $lang.hints.noExceptionMessage"></p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item
                    :key="991"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" class="pt-2">
                        <div class="calculated-height" style="overflow-y: auto;">
                          <v-tooltip v-if="exception && exception.exceptionStackTrace" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                text
                                large
                                v-bind="attrs"
                                v-on="on"
                                @click="clipboardCopy(exception.exceptionStackTrace)"
                              >
                                <v-icon>mdi-vector-combine</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $lang.labels.copy }}</span>
                          </v-tooltip>
                          <p class="color-primary font-change" v-html="exception && exception.exceptionStackTrace ? exception.exceptionStackTrace : $lang.hints.noExceptionStackTrace"></p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import jsonCustomViewer from '@/components/ui/JsonCustomViewer'
import {
  getEventByIdUsingGET as getEventDetails,
  getProcessByIdUsingGET as getProcess,
  getTriggerCronByIdUsingGET as getCron,
  getTriggerEventHandlerByIdUsingGET as getEvent,
  getTriggerMessagingByIdUsingGET as getMessage,
  getTriggerRestByIdUsingGET as getRest
} from '@/utils/api'
import { estimatedTimeOfExecution } from '@/utils/helpers'
import { mapActions } from 'vuex'

export default {
  components: {
    jsonCustomViewer
  },
  props: {
    eventId: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      data: {
        status: '',
        parentEventId: '',
        triggerId: '',
        priority: 64,
        createdOn: null,
        processingStartOn: null,
        shouldFinishBy: null,
        heartbeat: null,
        receivingInstanceId: '',
        instanceId: '',
        type: '',
        finishedOn: '',
        respondSent: false,
        modifiedOn: null,
        parentProcessId: null,
        exceptionMessage: null,
        exceptionStackTrace: null,
        input: null,
        output: null,
        sentOn: null
      },
      tab0: 0,
      tab1: 0,
      tab2: 0,
      tab3: 0,
      input: null,
      output: null,
      exception: {
        exceptionMessage: null,
        exceptionStackTrace: null
      },
      inputArr: [],
      outputArr: [],
      success: '',
      parentProcessObj: null,
      processObj: null,
      triggerObj: null,
      isLoading: false
    }
  },
  computed: {
    isRespontSentAndWhen() {
      if (this.data.respondSent && !this.data.sentOn) {
        return this.$lang.labels.yes
      }
      
      return this.data.respondSent ? `${this.$lang.labels.yes} / ${this.$options.filters.formatDateTimePrecise(this.data.sentOn)} (${this.estimatedTimeOfExecution(this.data.processingStartOn, this.data.sentOn)})` : this.$lang.labels.no
    },
    lastHeartbeatBeforeData() {
      if (this.data.heartbeat) {
        return this.estimatedTimeOfExecution(this.data.heartbeat, new Date())
      }

      return 'N/A'
    },
    estimatedTimeOfExecutionData() {
      if (this.data.shouldFinishBy && this.data.processingStartOn) {
        return this.estimatedTimeOfExecution(this.data.processingStartOn, this.data.shouldFinishBy)
      }

      return 'N/A'
    },
    actualTimeOfExecutionData() {
      if (this.data.processingStartOn && this.data.modifiedOn) {
        return this.data.finishedOn ? `${this.$options.filters.formatDateTimePrecise(this.data.finishedOn)} (${this.estimatedTimeOfExecution(this.data.processingStartOn, this.data.finishedOn)})`
          : 'N/A'
      }

      return 'N/A'
    }
  },
  created() {
    if (this.eventId) {
      this.fetchEventData(this.eventId)
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    fetchEventData(id) {
      this.isLoading = true
      getEventDetails({ id })
        .then((res) => {
          if (res.status !== 200) {
            this.isLoading = false
            this.addSnackbar({
              message: res?.response?.data?.statusText || 'Error',
              timeout: 5000,
              color: 'error'
            })

            return
          }
          if (res?.data?.data?.processId) {
            getProcess({ id: res?.data?.data?.processId })
              .then(async (res2) => {
                this.processObj = res2.data.data
              })
              .catch((err) => {
                console.log(err)
              })
          }
          this.isLoading = false
          this.data = res.data.data
          this.afterEventDataFetch()
        })
        .catch((err) => {
          this.isLoading = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    afterEventDataFetch() {
      if (this.data?.parentProcessId) {
        getProcess({ id: this.data?.parentProcessId })
          .then(async (res) => {
            this.parentProcessObj = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (this.data?.triggerId && this.data?.type) {
        this.fetchTriggerByIdAndType(this.data.triggerId, this.data.type).then((res) => {
          this.triggerObj = res
        }).catch((err) => {
          console.log(err)
        })
      }
      const formatInputObj = (obj) => {
        if (obj && Object.keys(obj).length > 0) {
          for (const row in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(row)) {
              if (typeof  obj[row] !== 'object') {
                this.inputArr.push({ text: row , value: obj[row] })
              } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length > 0) {
                this.inputArr.push({ text: row , value: '' })
                formatInputObj(obj[row])
              } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length === 0) {
                this.inputArr.push({ text: row , value: '{}' })
              } else {
                this.inputArr.push({ text: row , value: 'null' })
              }
            }
          }
        }
      }

      const formatOutputObj = (obj) => {
        if (obj && JSON.stringify(obj) !== '{}') {
          for (const row in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(row)) {
              if (typeof  obj[row] !== 'object') {
                this.outputArr.push({ text: row , value: obj[row] })
              } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length > 0) {
                this.outputArr.push({ text: row , value: '' })
                formatOutputObj(obj[row])
              } else if (obj[row] && typeof  obj[row] === 'object' && Object.keys(obj[row]).length === 0) {
                this.outputArr.push({ text: row , value: '{}' })
              } else {
                this.outputArr.push({ text: row , value: 'null' })
              }
            }
          }
        }
      }

      if (this.data && this.data.exceptionMessage) {
        this.exception.exceptionMessage = this.data.exceptionMessage
      }

      if (this.data && this.data.exceptionStackTrace) {
        this.exception.exceptionStackTrace = this.data.exceptionStackTrace
      }

      if (this.data && this.data.input) {
        this.input = this.data.input
        formatInputObj(this.input)
      }
      if (this.data && this.data.output) {
        this.output = this.data.output
        formatOutputObj(this.output)
      }
    },
    estimatedTimeOfExecution,
    openTriggerByTypeAndId(id, type) {
      if (id && type) {
        switch (type) {
        case 'CRON':
          window.open(`/${localStorage.selectedLanguage || 'en'}/triggers/cron/edit/${id}`, '_blank')
          break
        case 'REST':
          window.open(`/${localStorage.selectedLanguage || 'en'}/triggers/rest/edit/${id}`, '_blank')
          break
        case 'MESSAGING':
          window.open(`/${localStorage.selectedLanguage || 'en'}/triggers/messaging/edit/${id}`, '_blank')
          break
        case 'EVENT_HANDLER':
          window.open(`/${localStorage.selectedLanguage || 'en'}/triggers/event-handler/edit/${id}`, '_blank')
          break
        case 'RUN_PROCESS':
          window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${id}`, '_blank')
          break
        default:
          break
        }
      }
    },
    async fetchTriggerByIdAndType(id, type) {
      return new Promise((resolve, reject) => {
        switch (type) {
        case 'CRON':
          getCron({ id })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'REST':
          getRest({ id })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'MESSAGING':
          getMessage({ id })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'EVENT_HANDLER':
          getEvent({ id })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
          break
        case 'RUN_PROCESS':
          getProcess({ id })
            .then((res) => {
              resolve(res.data.data)
            })
            .catch((err) => {
              reject(err)
            })
          break
        default:
          resolve(null)
        }
      })
    },
    openProcessById(id) {
      window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${id}?eventId=${this.eventId}`, '_blank')
    },
    openProcess() {
      if (this.parentProcessObj?.id && this.data?.parentEventId) {
        window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.parentProcessObj.id}?eventId=${this.data.parentEventId}`, '_blank')

      } else if (this.parentProcessObj?.id) {
        window.open(`/${localStorage.selectedLanguage || 'en'}/processes/edit/${this.parentProcessObj.id}`, '_blank')

      }
    },
    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.success = this.$lang.success.copiedClipboard

      setTimeout(() => this.success = '', 5000)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-lazy {
  max-height: calc(80vh - 230px);
}
.calculated-height {
  max-height: calc(80vh - 230px);
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}
</style>
