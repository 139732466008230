<template>
  <v-card class="pa-2" style="min-height: 80vh; max-height: 80vh; overflow-y: hidden; display: flex; flex-direction: column">
    <v-alert v-if="localData && err" color="error" :data-cy="err" style="position: absolute; left: 30%; width: 40%">{{ err }}</v-alert>
    <v-alert v-if="localData && success" color="success" :data-cy="success" style="position: absolute; left: 30%; width: 40%">{{ success }}</v-alert>
    <v-row no-gutters style="flex-direction: column; flex: 1">
      <v-col cols="12">
        <v-card-title class="pb-2 pl-2">
          <span>{{ $lang.labels.import }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            @click="$emit('closeDialog', true)"
          >
            <v-icon dark size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
      </v-col>
      <v-col cols="12" style="display: flex; flex: 1">
        <textarea
          ref="textareaRef"
          v-model="localData"
          class="calculated-height"
        ></textarea>
      </v-col>
    </v-row>
    <v-row no-gutters justify="space-between" class="pt-2" style="flex: 0">
      <v-btn

        color="secondary"
        @click="$emit('closeDialog', true)"
      >
        {{ $lang.actions.cancel }}
      </v-btn>
      <v-btn
        color="success"

        :disabled="!!err || !localData"
        @click="$emit('import', formattedData)"
      >
        {{ $lang.actions.import }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localData: '',
      formattedData: '',
      err: '',
      success: ''
    }
  },
  watch: {
    localData(val) {
      if (val) {
        try {
          this.formattedData = JSON.parse(val)
          if (this.validateByType(this.formattedData).success) {
            this.err = ''
            this.success = this.validateByType(this.formattedData).message
          } else {
            this.success = ''
            this.err = this.validateByType(this.formattedData).message
          }
        } catch (e) {
          this.success = ''
          this.err = `Invalid JSON: ${e}`
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.textareaRef.focus()
    }, 100)
  },
  methods: {
    validateByType() {
      if (this.type === 'JS') {
        return this.validateJSDataStructure(this.formattedData)
      } else if (this.type === 'VALIDATION_RULE') {
        return this.validateValidationDataStructure(this.formattedData)
      } else {
        return {
          success: false,
          message: 'Invalid type'
        }
      }
    },
    validateJSDataStructure(data) {
      // Check if data has all required fields
      const requiredFields = [
        'id',
        'name',
        'steps',
        'isGdprRelevant',
        'isStepLogsEnabled',
        'isCacheStepLogsEnabled'
      ]

      for (const field of requiredFields) {
        if (!Object.prototype.hasOwnProperty.call(data, field)) {
          return {
            success: false,
            message: `Missing required field: ${field}`
          }
        }
      }

      // Check if steps has all required fields
      const stepsRequiredFields = [
        'scriptEngine',
        'steps'
      ]

      const { steps } = data

      for (const field of stepsRequiredFields) {
        if (!Object.prototype.hasOwnProperty.call(steps, field)) {
          return {
            success: false,
            message: `Missing required field in 'steps': ${field}`
          }
        }
      }

      // Check if scriptEngine is 'JS'
      if (steps.scriptEngine !== 'JS') {
        return {
          success: false,
          message: 'Property \'scriptEngine\' in \'steps\' must be \'JS\''
        }
      }

      // All checks passed
      return {
        success: true,
        message: 'Data structure is valid'
      }
    },
    validateValidationDataStructure(data) {
      // Check if data has all required fields
      const requiredFields = ['id', 'name', 'fields', 'includes']

      for (const field of requiredFields) {
        if (!Object.prototype.hasOwnProperty.call(data, field)) {
          return {
            success: false,
            message: `Missing required field: ${field}`
          }
        }
      }

      // Check if each field in 'fields' has all required sub-fields
      const fieldsRequiredFields = ['name', 'isRequired', 'dataType', 'rules']

      for (const field of data.fields) {
        for (const subField of fieldsRequiredFields) {
          if (!Object.prototype.hasOwnProperty.call(field, subField)) {
            return {
              success: false,
              message: `Missing required sub-field in 'fields': ${subField}`
            }
          }
        }

        if (!Array.isArray(field.rules)) {
          return {
            success: false,
            message: 'Field\'s \'rules\' must be an array'
          }
        }
      }

      // Check if each field in 'fields' has valid rules
      for (const field of data.fields) {
        for (const rule of field.rules) {
          if (!rule.type || !rule.value) {
            return {
              success: false,
              message: `Invalid rule in 'fields': ${JSON.stringify(rule)}`
            }
          }
        }
      }

      // Check if each included ID in 'includes' is a number
      for (const includedId of data.includes) {
        if (typeof includedId !== 'number') {
          return {
            success: false,
            message: `Invalid included ID: ${includedId}`
          }
        }
      }

      // All checks passed
      return {
        success: true,
        message: 'Data structure is valid'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.calculated-height {
  height: calc(80vh - (60px + 32px + 52px));
  max-height: calc(80vh - (60px + 32px + 52px));
}

.font-change {
  font-family: "Courier New", Courier, monospace !important;
}

.v-input__control, .v-input__slot, .v-text-field__slot, textarea {
  height: 100%;
  width: 100%;
  color: #9e9e9e;
  border: 1px #2d2d2d solid;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex: 1;
}

.v-text-field fieldset, .v-text-field .v-input__control {
  height: 100%;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
  height: 100%;
}
</style>
