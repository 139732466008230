<template>
  <v-row wrap no-gutters class="pt-0 mt-0">
    <v-row class="mt-0 ml-3 align-center">
      <v-btn
        icon
        color="primary"
        class="mb-1 mt-1"
        @click="savePng"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-progress-circular
        v-if="isDiagramLoading"
        color="primary"
        indeterminate
        class="mt-1 ml-2"
      />
    </v-row>
    <div id="diagram" class="diagram"></div>
  </v-row>

</template>

<script>
import cytoscape from 'cytoscape'
import cytoscapeDomNode from 'cytoscape-dom-node'
import panzoom from 'cytoscape-panzoom'
import dagre from 'cytoscape-dagre'
import download from 'downloadjs'
import html2canvas from 'html2canvas'

export default {
  name: 'StepDiagram',
  props: {
    steps: {
      type: Array,
      required: true
    },
    isHorizontal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stepsData: [],
      isDiagramLoading: false,
      selectedStepId: null,
      stepsElementsArray: []
    }
  },
  watch: {
    '$vuetify.theme.isDark': {
      handler() {
        this.renderDiagram()
      }
    },
    isHorizontal() {
      this.renderDiagram()
    }
  },
  mounted() {
    this.renderDiagram()
  },
  methods: {
    renderDiagram() {
      this.stepsData = []
      this.stepsElementsArray = []
      this.isDiagramLoading = true
      this.stepsData.push({
        id: 'start',
        localId: 'start',
        name: 'start',
        type: 'START'
      })

      this.steps.forEach((step) => {
        this.stepsData.push({
          id: step.id,
          name: step.name,
          type: step.type,
          mermaidId: step.mermaidId,
          enabled: step.enabled,
          isSelected: step.isSelected,
          properties: step.properties,
          localId: step.localId,
          logStatus: step.logStatus
        })
      })

      cytoscape.use(cytoscapeDomNode)
      cytoscape.use(dagre)
      panzoom(cytoscape)

      const diagram = cytoscape({
        container: document.getElementById('diagram'),
        elements: [],
        pan: { x: 0, y: 0 },
        wheelSensitivity: 0.2,
        style: [
          {
            selector: 'node',
            style: {
              shape: 'rectangle',
              'background-opacity': 0
            }
          },
          {
            selector: 'edge',
            style: {
              'label': 'data(text)',
              'target-arrow-shape': 'triangle',
              'curve-style': 'straight',
              'color': this.$vuetify.theme.currentTheme.textbase,
              'width': 6
            }
          }
        ]
      })

      diagram.domNode()
      /* const pan =  */diagram.panzoom({
        zoomOnly: true,
        fitPadding: 0
      })

      this.stepsData.push({
        id: 'finish',
        localId: 'finish',
        name: 'finish',
        type: 'FINISH'
      })

      // Add step nodes
      this.stepsData.forEach((stepData) => {
        this.addStepNodeRecursively(stepData)
      })

      // Add relations if any
      this.stepsData.forEach((step, index, stepsData) => {
        this.addRelationsRecursively(step, stepsData[index + 1]?.localId, false, stepsData[index + 1]?.localId)
      })

      diagram.ready(() => {
        diagram.add(this.stepsElementsArray)
        diagram.layout({
          name: 'dagre',
          nodeSep: 100, // the separation between adjacent nodes in the same rank
          edgeSep: 50, // the separation between adjacent edges in the same rank
          minLen: () => this.isHorizontal ? 2 : 0.4,
          rankDir: this.isHorizontal ? 'LR' : 'TB',
          spacingFactor: 2
        }).run()
        this.isDiagramLoading = false
      })

      if (this.stepsData.length <= 1) {
        diagram.zoom({
          level: 1,
          renderedPosition: { x: 350, y: defaultYPan + 100 }
        })
      }
    },
    addStepNodeRecursively(stepData, isCatch = false, skipFirstNode = false) {
      const step = isCatch
        ? { steps: [...stepData], name: 'catch', localId: 'catch' }
        : { ...stepData }

      // Additional node before the actual step is redundant on the diagram
      !skipFirstNode && this.stepsElementsArray.push(this.renderStepNode(step))

      if (step.properties?.conditions?.length > 0) {
          step.properties?.conditions.forEach((condition) => {
            this.addStepNodeRecursively(condition)
          })
      }

      const innerSteps = step.properties?.steps || step.steps || []

      if (innerSteps.length > 0) {
        innerSteps.forEach((subStep) => {
          this.addStepNodeRecursively(subStep)
        })
      }

      if (step.type === 'TRY_CATCH') {
        step?.properties?.try && this.addStepNodeRecursively(step.properties.try, false, true)
        step?.properties?.catch && this.addStepNodeRecursively(step.properties.catch, true)
        step.properties?.finally && this.addStepNodeRecursively(step.properties.finally, false, true)
      }

      return this.stepsElementsArray
    },
    renderStepNode(stepData) {
      const { id, localId, logStatus, name, query, type, enabled, mermaidId } = stepData

      const node = document.createElement('div')

      const nodeId = `step-node-${localId}`

      node.id = nodeId
      node.classList.add('step-node')
      node.classList.add(this.$vuetify.theme.isDark ? 'step-node_dark' : 'step-node_light')

      if (logStatus === 'FAILED') {
        node.classList.add('step-node-failed')
      }

      const isQuery = stepData?.subType && (stepData.subType === 'QUERY' || stepData.subType === 'EXCEPTION')

      if (type === 'START') {
        node.classList.add('step-node-start')

        return {
          group: 'nodes',
          data: {
            id: nodeId,
            label: name,
            dom: node
          }
        }
      } else if (type === 'FINISH') {
        node.classList.add('step-node-finish')

        return {
          group: 'nodes',
          data: {
            id: nodeId,
            label: name,
            dom: node
          }
        }
      } else if (type === 'SWITCH'
        || isQuery
        || name === 'catch'
        || type === 'TRY_CATCH'
        || type === 'FOREACH'
        || type === 'WHILE') {
        node.classList.add('step-node-flow-background')
      } else {
        node.classList.add('step-node-simple-background')
      }

      if (!enabled && !isQuery && name !== 'catch') {
        node.classList.add('step-node-disabled')
      }

      if (type !== 'START' && type !== 'FINISH') {
        node.addEventListener('dblclick', () => {
          this.$emit('goToStep', mermaidId)
          this.selectedStepId = mermaidId
          const previousSelectedNode = document.querySelector('.step-node-current')

          previousSelectedNode && previousSelectedNode.classList.remove('step-node-current')
          node.classList.add('step-node-current')
        })
      }

      const visibleName = name || query || localId || id

      let innerHTML = `<div class='step-node-wrapper'>
        <div class='step-name-row'>
          ${isQuery ? this.renderIconHTMLString('arrow-decision-outline') : this.renderIconHTMLString(this.getStepIconName(type))}
          <span class='step-name'><strong>${visibleName}</strong></span>
        </div>`

      innerHTML += '</div>'

      node.innerHTML = innerHTML

      return {
        group: 'nodes',
        data: {
          'id': nodeId,
          'label': visibleName,
          'dom': node
        }
      }

    },
    addRelationsRecursively(stepData, nextStepId, insideLoop = false, nextStepPreviousLevelId, loopStep = 0) {
      const stepId = stepData.localId

      if (stepId === 'finish') {
        return
      }

      // To not return to the wrong step when a loop is nested
      const newLoopStep = insideLoop ? loopStep + 1 : 0
      const firstLevelOfTheLoop = insideLoop && loopStep === 1
      const outsideLoop = !insideLoop || loopStep === 0 || loopStep > 1

      if (stepData.properties?.conditions?.length > 0) {
        stepData.properties?.conditions.forEach((condition) => {
          this.stepsElementsArray.push(this.getRelationData(stepId, condition.localId))
          this.addRelationsRecursively(condition, condition.steps?.[0].localId, insideLoop, nextStepId, newLoopStep)
        })
      }

      const innerSteps = stepData.properties?.steps || stepData.steps || []

      if (innerSteps.length > 0) {
        innerSteps.forEach((subStep, index) => {
          const nextLocalStepId =
              innerSteps[index + 1]?.localId
              || nextStepPreviousLevelId

          if (index === 0) {
            this.stepsElementsArray.push(this.getRelationData(stepId, subStep.localId))
          }

          // Last step of the loop - add a relation to the beginning of the loop
          if (firstLevelOfTheLoop && index === innerSteps.length - 1) {
            this.stepsElementsArray.push(this.getRelationData(subStep.localId, stepId))
          }

          const loopWithSubsteps = subStep.properties?.steps?.length > 0
            && (subStep.type === 'FOREACH' || subStep.type === 'WHILE')

          if (loopWithSubsteps) {
            this.addRelationsRecursively(subStep, subStep.properties.steps[0].localId, true, nextLocalStepId/* nextStepPreviousLevelId */, 1)

            // We have to go to the next step after the loop,
            // but not when we are already inside another loop
            if (outsideLoop) {
              this.stepsElementsArray.push(this.getRelationData(subStep.localId, nextLocalStepId))
            }
          } else if (index !== innerSteps.length - 1) {
            this.addRelationsRecursively(subStep, innerSteps[index + 1].localId, insideLoop, nextStepPreviousLevelId, newLoopStep)
          } else if (outsideLoop && !stepData.subType === 'EXCEPTION') {
            this.stepsElementsArray.push(this.getRelationData(subStep.localId, nextLocalStepId))
          } else {
            if ((subStep.localId !== nextStepId && subStep.type !== 'SWITCH') && (subStep.properties?.steps?.length || subStep.steps?.length)) {
              this.addRelationsRecursively(subStep, nextStepId, insideLoop, nextStepPreviousLevelId, newLoopStep)
            }
            if (
              (firstLevelOfTheLoop && (subStep.subType === 'FOREACH' || subStep.subType === 'WHILE'))
            || (index === innerSteps.length - 1 && outsideLoop)
          || subStep.type === 'SWITCH') {
              this.addRelationsRecursively(subStep, nextStepPreviousLevelId, insideLoop, nextStepPreviousLevelId, newLoopStep)
            }
          }
        })
      }

      if (stepData.type === 'TRY_CATCH') {
        this.addTryCatchRelations(stepData, nextStepPreviousLevelId)

        return
      }

      if (stepData.type === 'SWITCH') {
        return
      }
      if (outsideLoop && stepData.subType !== 'QUERY') {
        this.stepsElementsArray.push(this.getRelationData(stepId, nextStepId))
      }
    },
    addTryCatchRelations(stepData, nextStepPreviousLevelId) {
      const stepId = stepData.localId

      const nextBigStep = stepData.properties?.finally?.steps?.[0].localId || nextStepPreviousLevelId

      const trySteps = stepData.properties?.try?.steps || []

      if (trySteps.length) {
        trySteps.forEach((step, index, steps) => {
          if (index === 0) {
            this.stepsElementsArray.push(this.getRelationData(stepId, step.localId))
          }

          if (index !== steps.length - 1) {
            this.addRelationsRecursively(step, steps[index + 1].localId, false, nextBigStep)
          } else {
            this.stepsElementsArray.push(this.getRelationData(step.localId, nextBigStep))
          }
        })
      }

      const catchSteps = stepData.properties?.catch || []

      if (catchSteps.length) {
        this.stepsElementsArray.push(this.getRelationData(stepId, 'catch'))
        catchSteps.forEach((catchStep) => {
          this.stepsElementsArray.push(this.getRelationData('catch', catchStep.localId))
          const stepsInsideException = catchStep.steps || []

          if (stepsInsideException.length) {
            stepsInsideException.forEach((step, index, steps) => {
              if (index === 0) {
                this.stepsElementsArray.push(this.getRelationData(catchStep.localId, step.localId))
              }

              if (index !== steps.length - 1) {
                this.addRelationsRecursively(step, steps[index + 1].localId, false, nextBigStep)
              } else {
                this.stepsElementsArray.push(this.getRelationData(step.localId, nextBigStep))
              }
            } )
          }
        })
      }

      const finallySteps = stepData.properties?.finally?.steps || []

      if (finallySteps.length) {
        finallySteps.forEach((step, index, steps) => {
          if (index !== steps.length - 1) {
            this.addRelationsRecursively(step, steps[index + 1].localId, false, nextStepPreviousLevelId || 'finish')
          } else {
            this.stepsElementsArray.push(this.getRelationData(step.localId, nextStepPreviousLevelId || 'finish'))
          }
        })
      }
    },
    getRelationData(sourceId, targetId) {
      return {
        group: 'edges',
        data: {
          id: `relation-${sourceId}-${targetId}`,
          source: `step-node-${sourceId}`,
          text: '',
          target: `step-node-${targetId}`,
          'curve-style': 'straight'
        }
      }
    },
    getStepIconName(stepType) {
      switch (stepType) {
      case 'EMAIL':
        return 'email-outline'
      case 'GROOVY':
        return 'code-tags'
      case 'JS':
        return 'language-javascript'
      case 'IMAGE':
        return 'image'
      case 'JDBC':
        return 'database'
      case 'MONGODB':
        return 'database-outline'
      case 'IMAP':
        return 'email-open-outline'
      case 'PDF':
        return 'file-pdf-box'
      case 'PLUGIN':
        return 'power-plug-outline'
      case 'REST':
        return 'web'
      case 'S3':
        return 'warehouse'
      case 'SLACK':
        return 'slack'
      case 'SWITCH':
        return 'shuffle'
      case 'FOREACH':
        return 'swap-horizontal'
      case 'USER':
        return 'account'
      case 'TWILIO':
        return 'message-processing'
      case 'WHILE':
        return 'rotate-left'
      case 'CSV':
        return 'file-delimited'
      case 'TRY':
        return 'flask-empty-outline'
      case 'FINALLY':
        return 'flask-plus'
      case 'EXCEPTION':
        return 'flask-minus'
      case 'QUERY':
        return 'help'
      case 'UNSET_VARIABLES':
        return 'broom'
      case 'SECURITY':
        return 'lock'
      case 'MESSAGING':
        return 'message'
      case 'TRY_CATCH':
        return 'flask'
      case 'CATCH':
        return 'flask-minus'
      case 'STORAGE':
        return 'server'
      default:
        return 'circle'
      }
    },
    renderIconHTMLString(iconName) {
      switch (iconName) {
      // case 'js' || 'slack':
      //   return `<span class='mdi mdi-${iconName}'></span>` /* `<i class='fab fa-${iconName} step-name-icon'></i>` */
      // case 'hard-drive':
      //   return `<i class='far fa-${iconName} step-name-icon'></i>`
      default:
        return `<span class='process-diagram-icon mdi mdi-${iconName}'></span>`/* `<i class='fa fa-${iconName} step-name-icon'></i>` */
      }
    },
    onResetClick() {
      this.stepsData = []
      this.renderDiagram()
    },
    async savePng() {
      const canvas = await html2canvas(document.getElementById('diagram'), {
        backgroundColor: this.$vuetify.theme.currentTheme.background
      })
      const url = canvas.toDataURL('image/png')

      download(url, `${this.name}.png`)
    }
  }
}
</script>
<style lang="scss">
@import '~cytoscape-panzoom/cytoscape.js-panzoom.css';

#diagram {
  width: 100%;
  height: calc(100vh - 185px);
}

.step {
  &-node {
    min-width: 200px;
    padding: 3px 5px;
    background-color: var(--v-background-base);
    border: 1px solid var(--v-secondary-base);
    border-radius: 6px;
    color: var(--v-secondary-base);
    font-size: 14px;
    cursor: pointer;

    &-start, &-finish {
      min-width: 50px;
      min-height: 50px;
      border-radius: 90%;
      cursor: default;
    }

    &_dark {
      color: var(--v-secondary-dark-base);
    }

    &_light {
      color: var(--v-secondary-light-base);
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    &-current {
      border: var(--v-primary-base) solid 4px;
    }

    &-failed {
      color: var(--v-error-base);
    }

    &-disabled {
      color: gray;
      text-decoration: line-through;
    }

    &-flow-background {
      background-color: var(--v-processDiagramFlowNodeBackground-base);
      color: black
    }

    &-simple-background {
      background-color: var(--v-processDiagramSimpleNodeBackground-base);
      color: black
    }
  }

  &-name {
    &-row {
      display: flex;
      align-items: center;
      padding: 5px;
    }

    &-icon {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }

  &-fields {
    border-bottom: 1px double var(--v-primary-base);
  }

  &-field {
    &-name {
      &-wrapper {
        display: flex;
        align-items: center;
      }
    }
    &-row {
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }
  }
}

.cy-panzoom {
  &-zoom-button {
    width: 30px;
    height: 30px;
    background-color: var(--v-primary-base);
    color: #fff;
    border: none;
    border-radius: 90px;
    left: 20px;
  }

  &-zoom-in {
    top: 60px !important;
  }

  &-zoom-out {
    top: 100px !important;
  }

  & .svg-inline--fa.icon {
    margin-top: 10px !important;
    margin-left: 1px !important;
  }
}

.svg-inline--fa {
  vertical-align: -0.6em;
}

.relations-level-diagram-select {
  max-width: 120px;
  margin-left: 20px !important;

  .v-text-field__details {
    display: none;
  }
}

.process-diagram-icon {
  margin-right: 5px;

  &::before {
    font-size: 30px;
  }
}
</style>
