<template>
  <v-container style="overflow: hidden" class="mx-1 process-details-container custom-container-process pa-2">
    <div
      style="overflow: hidden"
      class="this-screen-max-height process-container"
    >
      <v-card
        flat
        color="transparent"
        :class="{'d-inline-flex justify-space-between pr-1 py-1 mb-1': true, 'flex-column': $vuetify.breakpoint.smAndDown }"
        :style="`width: 100%; position: sticky; top: 0; z-index: 1; align-items: ${ $vuetify.breakpoint.smAndDown ? 'start' : 'center' }`"
      >
        <div class="d-flex w-100 flex-grow-1">
          <h1 class="text-h4 ma-0 pa-0 align-self-center">
            <v-icon color="primary">mdi-console-network</v-icon>
            <span class="process-name" @click="selectProcessName()">
              {{ process.name ? process.name.length > 50 ? process.name.substring(0, 47) + '...' : process.name : $lang.labels.addProcessName }}
            </span>
          </h1>
        </div>
        <div :class="{'actions-btn-container': true, 'align-items-end': $vuetify.breakpoint.smAndDown, 'mt-2': $vuetify.breakpoint.smAndDown }">
          <v-btn
            style="border: 1px solid var(--v-customInputBorderColor-base)"
            class="ml-auto align-self-center grayOnHover"
            width="28"
            min-width="unset"
            data-cy="undo-btn"
            :disabled="lock || !userCanEdit || !isDirty || processUndoStack.length <= 1"
            @click="undo()"
          >
            <v-icon size="24" color="accent">mdi-undo</v-icon>
          </v-btn>
          <v-btn
            style="border: 1px solid var(--v-customInputBorderColor-base)"
            width="28"
            min-width="unset"
            class="ml-1 align-self-center grayOnHover"
            data-cy="redo-btn"
            :disabled="lock || !userCanEdit || !processRedoStack.length"
            @click="redo()"
          >
            <v-icon size="24" color="accent">mdi-redo</v-icon>
          </v-btn>
          <div class="d-flex align-center ml-1">
            <v-btn
              outlined
              style="border: 1px solid var(--v-customInputBorderColor-base)"
              :icon="$vuetify.breakpoint.smAndDown"
              class="clickable-simple text-subtitle-2 grayOnHover"
              data-cy="process-name-btn"
              @click="selectProcessName()"
            >
              <v-icon small color="accent" :class="!$vuetify.breakpoint.sm && 'mr-1'">mdi-console-network</v-icon>
              <span v-if="!$vuetify.breakpoint.smAndDown">{{ $lang.labels.details }}</span>
            </v-btn>
          </div>
          <div class="d-flex align-center ml-1">
            <v-btn
              outlined
              style="border: 1px solid var(--v-customInputBorderColor-base)"
              :icon="$vuetify.breakpoint.smAndDown"
              class="clickable-simple text-subtitle-2 grayOnHover "
              data-cy="events-and-steps-btn"
              @click="mini = true; showDetailsWrapper = false; showEventsAndStepWrapper = true"
            >
              <v-icon small color="accent" :class="!$vuetify.breakpoint.sm && 'mr-1'">mdi-bug-check</v-icon>
              <span v-if="!$vuetify.breakpoint.smAndDown">{{ $lang.labels.debugger }}</span>
            </v-btn>
          </div>
          <div class="d-inline-flex mx-1 align-center mt-md-0 ">
            <v-menu
              offset-y
              nudge-left="64"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  outlined
                  :icon="$vuetify.breakpoint.smAndDown"
                  class="clickable-simple text-subtitle-2 grayOnHover"
                  style="align-self: center; border: 1px solid var(--v-customInputBorderColor-base)"
                  v-bind="attrs"
                  :disabled="lock || process.steps.steps.length === 0"
                  data-cy="view-btn"
                  v-on="on"
                >
                  <v-icon
                    v-if="radioButtonsVal === '1'"
                    small
                    color="accent"
                    :class="!$vuetify.breakpoint.smAndDown && 'mr-1'"
                  >mdi-format-list-bulleted</v-icon>
                  <v-icon
                    v-if="radioButtonsVal === '2'"
                    small
                    color="accent"
                    :class="!$vuetify.breakpoint.smAndDown && 'mr-1'"
                  >mdi-align-horizontal-center</v-icon>
                  <v-icon
                    v-if="radioButtonsVal === '3'"
                    small
                    color="accent"
                    :class="!$vuetify.breakpoint.smAndDown && 'mr-1'"
                  >mdi-align-vertical-center</v-icon>
                  <span v-if="!$vuetify.breakpoint.smAndDown">{{ $lang.labels.view }}</span>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  link
                  data-cy="steps-view"
                  @click="radioButtonsVal = '1'"
                >
                  <v-list-item-avatar>
                    <v-icon :color="radioButtonsVal === '1' ? 'accent' : ''">mdi-format-list-bulleted</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>{{ $lang.labels.steps }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  data-cy="horizontal-cytoscape-view"
                  @click="radioButtonsVal = '2'"
                >
                  <v-list-item-avatar>
                    <v-icon :color="radioButtonsVal === '2' ? 'accent' : ''">mdi-align-horizontal-center</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Vertical Diagram</v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  data-cy="vertical-cytoscape-view"
                  @click="radioButtonsVal = '3'"
                >
                  <v-list-item-avatar>
                    <v-icon :color="radioButtonsVal === '3' ? 'accent' : ''">mdi-align-vertical-center</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Horizontal Diagram</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              v-show="$vuetify.breakpoint.md"
              vertical
              class="mx-1"
            />
          </div>
          <div v-if="process.steps.steps.length === 0" class="d-flex flex-row mt-1 mt-md-0">
            <div>
              <span class="mr-1 text-subtitle-2">{{ $lang.labels.actions }}:</span>
              <v-btn
                :disabled="lock || !userCanEdit"
                icon
                color="primary"
                data-cy="import-btn"
                @click="showImport = true"
              >
                <v-icon>mdi-import</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
      <v-row
        wrap
        no-gutters
        class="fill-height"
        style="max-height: calc(100% - 40px)"
      >
        <v-col cols="12" class="fill-height pt-2">
          <v-row
            v-if="topAlarmHint"
            dense
            no-gutters
            class="px-0"
          >
            <v-col cols="12">
              <v-alert color="secondary" :data-cy="topAlarmLabel">
                {{ topAlarmHint }}
              </v-alert>
            </v-col>
          </v-row>
          <div class="d-inline-flex fill-height" style="width: 100%">
            <div
              ref="steps"
              class="pr-1 flex-fill"
              :style="showProcessMermaid || showProcessDiagram ? 'overflow: hidden' : 'overflow-y: auto; overflow-x: hidden'"
            >
              <v-alert
                v-if="process.steps.steps.length === 0"
                color="warning"
                style="color: black"
              >
                {{ $lang.errors.zeroSteps }}
              </v-alert>
              <v-alert v-if="ttlLogsHint" color="warning" style="color: black">
                <v-row align="center">
                  <v-col class="grow">
                    {{ $lang.hints.ttlLogsHint }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      data-cy="ttl-logs-hint"
                      @click="ttlLogsHint = false"
                    >OK</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <v-alert
                v-if="ttlErrLogsHint"
                color="warning"
                style="color: black"
              >
                <v-row align="center">
                  <v-col class="grow">
                    {{ $lang.hints.ttlLogsHint }}
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      data-cy="ttl-err-logs-hint"
                      @click="ttlErrLogsHint = false"
                    >OK</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <process-mermaid
                v-if="
                  stepsToDisplay.length > 0 &&
                    !loading &&
                    showProcessMermaid
                "
                :mermaid-data="stepsToDisplay"
                :process-name="process.name || ''"
                :mermaid-type="processMermaidType"
                :highest-id="highestId"
                :mermaid-id="mermaidId"
                :mermaid-grouping="mermaidGrouping"
                class="mermaid-style"
                @goToStep="goToStep"
              />
              <process-diagram
                v-if="
                  stepsToDisplay.length > 0 &&
                    !loading &&
                    showProcessDiagram
                "
                :steps="stepsToDisplay"
                :process-name="process.name || ''"
                :highest-id="highestId"
                :is-horizontal="isCytoscapeDiagramHorizontal"
                class="mermaid-style"
                @goToStep="goToStep"
              />
              <timeline
                v-if="
                  stepsToDisplay.length > 0 &&
                    !loading &&
                    !showProcessMermaid &&
                    !showProcessDiagram
                "
                :key="tableKey"
                style="overflow: auto; width: 100%;"
                :steps="stepsToDisplay"
                :can-edit="userCanEdit"
                :drag-lock="dragLock"
                @previewData="
                  previewData(
                    $event.item,
                    $event.isSpecial,
                    $event.innerStep,
                    $event.isAfter,
                    $event.tryCatch
                  )
                "
                @addStep="addStep"
                @moveStep="moveStep"
                @deleteStep="
                  selectedDeleteItem = $event;
                  yesNoShow = true;
                "
                @showSnack="showSnack"
                @pasteStep="pasteStep"
                @pasteStepBefore="($event) => {
                  addStep({
                    beforeItem: $event,
                    isSpecial: false,
                    innerStep: false,
                    isAfter: false,
                    isPasteAfterOrBefore: true,
                    tryCatch: ''
                  })
                }"
                @pasteStepAfter="($event) => {
                  addStep({
                    beforeItem: $event || {id:0},
                    isSpecial: false,
                    innerStep: false,
                    isAfter: true,
                    isPasteAfterOrBefore: true,
                    tryCatch: ''
                  })
                }"
                @duplicateStep="duplicateStep"
                @dragSteps="dragSteps"
                @showHideChildren="showHideChildren"
              ></timeline>
              <div v-else class="justify-center text-center">
                <add-row
                  v-if="!loading && !showProcessMermaid && !showProcessDiagram"
                  single
                  data-cy="add-standard-new"
                  @click.native="userCanEdit ? addStep({ id: 0 }) : ''"
                ></add-row>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                />
              </div>
            </div>
            <div
              ref="resizeme"
              :class="{
                'resize-me toggle-right-list': true,
                'is-close': !mini,
                'background-transparent': !mini,
              }"
              data-cy="toggle-right-list"
              style="position: relative; max-height: 100%; overflow: hidden;"
            >
              <div v-if="!mini" class="toggle-menu">
                <v-btn
                  class="toggle-left"
                  icon
                  text
                  data-cy="toggle-left"
                  @click.stop="mini = !mini"
                >
                  <v-icon> mdi-chevron-left </v-icon>
                </v-btn>
              </div>
              <div v-show="mini" class="border-me h-100 "></div>
              <v-card v-show="mini" flat class="fill-height ml-2 pa-1 pb-2">
                <div class="toggle-menu">
                  <v-btn
                    class="toggle-right"
                    icon
                    data-cy="toggle-right"
                    @click.stop="mini = !mini"
                  >
                    <v-icon>mdi-chevron-right </v-icon>
                  </v-btn>
                </div>
                <v-row
                  :key="logKey"
                  wrap
                  no-gutters
                  :class="{ 'visibility-hidden': !mini, 'fill-height': true }"
                >
                  <v-col cols="12" class="fill-height">
                    <v-row
                      v-if="showDetailsWrapper"
                      no-gutters
                      justify="center"
                      class="pl-1"
                    >
                      <v-tabs
                        v-model="tab"
                        class="custom-tab-design"
                      >
                        <v-tabs-slider
                          :color="
                            topPanelIndex.includes(0)
                              ? 'accent'
                              : 'transparent'
                          "
                        ></v-tabs-slider>

                        <v-tab :key="0" data-cy="tab-process" class="ml-2">
                          <div>
                            {{ $lang.labels.process }}
                          </div>
                        </v-tab>
                        <v-tab :key="1" data-cy="tab-permissions" class="ml-2">
                          <div
                            class="ml-0"
                          >
                            {{ $lang.labels.permissions }}
                          </div>
                        </v-tab>
                        <v-tab :key="2" data-cy="tab-history" class="ml-2">
                          <div
                            class="ml-0"
                          >
                            {{ $lang.labels.history }}
                          </div>
                        </v-tab>
                        <v-tab :key="3" data-cy="tab-comment" class="ml-2">
                          <div
                            class="ml-0"
                          >
                            {{ $lang.labels.comment }}
                          </div>
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tab" style="width: 100%; overflow-y: auto">
                        <v-tab-item :key="0">
                          <v-card
                            class="px-1 py-2 fill-height pb-12"
                            color="transparent"
                          >
                            <v-form ref="form" v-model="valid">
                              <v-row
                                no-gutters
                                justify="space-between"
                              >
                                <v-col cols="12">
                                  <div class="d-inline-flex max-width">
                                    <v-text-field
                                      v-if="showProcessOrStepName"
                                      id="processName"
                                      ref="processName"
                                      v-model="process.name"
                                      outlined
                                      dense
                                      :label="$lang.labels.name"
                                      required
                                      :rules="[
                                        v => !!v.trim() || $lang.errors.required,
                                        v => (v.trim().length > 1 && v.trim().length <= 250) || $lang.errors.nameTooShortOrLong
                                      ]"
                                      class="required-asterisk mt-3 mb-4 copy-name-icon"
                                      :persistent-hint="isEdit"
                                      :hint="formatHint"
                                      :readonly="!userCanEdit"
                                      append-icon="mdi-vector-combine"
                                      data-cy="process-name-input"
                                      @click:append="copyToClipboard(process.name)"
                                    ></v-text-field>
                                  </div>
                                </v-col>
                                <v-col
                                  v-if="isEdit"
                                  cols="12"
                                  class="justify-space-between"
                                >
                                  <v-row
                                    no-gutters
                                    justify="space-between"
                                  >
                                    <v-btn
                                      outlined
                                      class="pl-2"
                                      color="primary"
                                      :disabled="!userCanEdit"
                                      data-cy="check-active-triggers"
                                      @click="checkActiveTriggers(process)"
                                    >
                                      <v-icon
                                        left
                                        dark
                                      >
                                        {{ process.status === 'ACTIVE' ? 'mdi-toggle-switch-off ' : 'mdi-toggle-switch' }}
                                      </v-icon>
                                      {{
                                        process.status === "ACTIVE"
                                          ? $lang.actions.deactivate
                                          : $lang.actions.activate
                                      }}
                                    </v-btn>
                                    <div>
                                      <div
                                        class="d-flex flex-row justify-content-center"
                                      >
                                        <div>
                                          <p class="px-1" style="line-height: 36px">
                                            {{ $lang.labels.open }}:
                                          </p>
                                        </div>
                                        <div>
                                          <v-btn
                                            class="clickable-simple color-primary events-button"
                                            data-cy="open-process-events"
                                            @click="openStepLogs()"
                                          >
                                            {{
                                              $lang.labels.stepLogs
                                            }}
                                          </v-btn>
                                        </div>
                                        <div class="align-self-center">
                                          <v-divider
                                            vertical
                                            style="
                                                  min-height: unset;
                                                  height: 21px;
                                                  transform: translateY(-8px);
                                                "
                                          />
                                        </div>
                                        <div>
                                          <v-btn
                                            class="clickable-simple color-primary events-button"
                                            data-cy="open-process-events"
                                            @click="openProcessEvents()"
                                          >
                                            {{
                                              $lang.labels.openProcessEvents
                                            }}
                                          </v-btn>
                                        </div>
                                      </div>
                                    </div>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row v-if="!loading" no-gutters>
                                <v-col cols="12">
                                  <v-expansion-panels
                                    id="expansionProcessAdvanced"
                                    v-model="panelIndex"
                                  >
                                    <v-expansion-panel
                                      :key="0"
                                      data-cy="triggers"
                                    >
                                      <v-expansion-panel-header>
                                        <v-row
                                          no-gutters
                                          justify="start"
                                          align="center"
                                          class="mr-1"
                                        >
                                          <v-icon
                                            class="mr-1"
                                          >mdi-rocket-launch</v-icon>
                                          {{ $lang.routes.triggers }}
                                        </v-row>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-row
                                          v-if="!loading"
                                          wrap
                                          no-gutters
                                        >
                                          <v-col cols="12">
                                            <v-data-table
                                              :headers="triggerHeaders"
                                              :items="connectedTriggers"
                                              item-key="id"
                                              class="background-transparent"
                                              :items-per-page="-1"
                                              hide-default-footer
                                            >

                                              <template v-slot:item.type="{ item }">
                                                <div>{{ $lang.status[item.type] }}</div>
                                              </template>

                                              <template v-slot:item.status="{ item }">
                                                <div>{{ $lang.status[item.status] }}</div>
                                              </template>

                                              <template v-slot:item.actions="{ item }">
                                                <v-btn
                                                  small
                                                  text
                                                  color="primary"
                                                  class="mx-0"
                                                  :data-cy="`open-trigger-${item.id}`"
                                                  @click="openTriggerItem(item)"
                                                >{{ $lang.actions.open }}</v-btn>
                                              </template>

                                            </v-data-table>
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel
                                      :key="1"
                                      data-cy="validations"
                                    >
                                      <v-expansion-panel-header>
                                        <v-row
                                          no-gutters
                                          justify="start"
                                          align="center"
                                          class="mr-1"
                                        >
                                          <v-icon
                                            class="mr-1"
                                          >mdi-magnify-scan</v-icon>
                                          {{ $lang.routes.validations }}
                                        </v-row>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-row
                                          v-if="!loading"
                                          wrap
                                          no-gutters
                                        >
                                          <v-col cols="12">
                                            <process-validations-search-field
                                              :label="$lang.labels.inputValidation"
                                              :type="'input'"
                                              :user-can-edit="userCanEdit"
                                              :required="false"
                                              :resource-is-system="process.isSystem"
                                              :item-validation-id="process.inputValidationRuleId"
                                              data-cy="input-validation"
                                              @validationRuleIdChanged="process.inputValidationRuleId = $event"
                                            />
                                            <process-validations-search-field
                                              :label="$lang.labels.outputValidation"
                                              :type="'output'"
                                              :user-can-edit="userCanEdit"
                                              :required="false"
                                              :resource-is-system="process.isSystem"
                                              :item-validation-id="process.outputValidationRuleId"
                                              data-cy="output-validation"
                                              @validationRuleIdChanged="process.outputValidationRuleId = $event"
                                            />
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel
                                      :key="2"
                                      data-cy="advanced-options"
                                    >
                                      <v-expansion-panel-header>
                                        <v-row
                                          no-gutters
                                          justify="start"
                                          align="center"
                                          class="mr-1"
                                        >
                                          <v-icon
                                            class="mr-1"
                                          >mdi-cog</v-icon>
                                          {{ $lang.labels.advancedOptions }}
                                        </v-row>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-row
                                          v-if="!loading"
                                          wrap
                                          no-gutters
                                        >
                                          <v-col cols="6" class="pr-1">
                                            <v-text-field
                                              v-model="tempErrorsTtlInMSec"
                                              outlined
                                              dense
                                              :label="
                                                $lang.labels
                                                  .logsErrTtlInMSec
                                              "
                                              required
                                              :rules="[
                                                (v) => !!v || v === 0 || $lang.errors.required,
                                                (v) => validateTtlLimit(v, timeUnitErrTtl) || $lang.errors.max10years
                                              ]"
                                              data-cy="logs-err-ttl"
                                              class="required-asterisk"
                                              :readonly="!userCanEdit"
                                              type="number"
                                              @change="
                                                tempErrorsTtlInMSec === 0 ||
                                                  tempErrorsTtlInMSec === '0'
                                                  ? (ttlErrLogsHint = true)
                                                  : (ttlErrLogsHint = false)
                                              "
                                              @keypress="filterNumber($event)"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="pl-1">
                                            <v-select
                                              v-model="timeUnitErrTtl"
                                              :items="timeUnits"
                                              :label="$lang.labels.unit"
                                              outlined
                                              dense
                                              required
                                              :readonly="!userCanEdit"
                                              style="min-width: 115px"
                                              data-cy="time-unit-err-ttl"
                                            >
                                              <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                  <v-list-item-title :data-cy="`${item} ErrTtl`">{{ item }}</v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </v-select>
                                          </v-col>
                                          <v-col cols="6" class="pr-1">
                                            <v-text-field
                                              v-model="tempLogsTtlInMSec"
                                              outlined
                                              dense
                                              :label="
                                                $lang.labels.logsTtlInMSec
                                              "
                                              required
                                              data-cy="logs-ttl"
                                              :rules="[
                                                (v) => !!v || v === 0 || $lang.errors.required,
                                                (v) => validateTtlLimit(v, timeUnitTtl) || $lang.errors.max10years
                                              ]"
                                              class="required-asterisk"
                                              :readonly="!userCanEdit"
                                              type="number"
                                              @change="
                                                tempLogsTtlInMSec === 0 ||
                                                  tempLogsTtlInMSec === '0' ||
                                                  process.logsTtlInMSec === 0 ||
                                                  process.errorsTtlInMSec === 0 ||
                                                  process.logsTtlInMSec === '0' ||
                                                  process.errorsTtlInMSec === '0'
                                                  ? (ttlLogsHint = true)
                                                  : (ttlLogsHint = false)
                                              "
                                              @keypress="filterNumber($event)"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="pl-1">
                                            <v-select
                                              v-model="timeUnitTtl"
                                              :items="timeUnits"
                                              :label="$lang.labels.unit"
                                              outlined
                                              dense
                                              required
                                              :readonly="!userCanEdit"
                                              style="min-width: 115px"
                                              data-cy="time-unit-ttl"
                                            >
                                              <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                  <v-list-item-title :data-cy="`${item} Ttl`">{{ item }}</v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </v-select>
                                          </v-col>
                                          <v-col cols="6" class="pr-1">
                                            <v-text-field
                                              v-model="
                                                tempMaxProcessingDurationInMSec
                                              "
                                              outlined
                                              dense
                                              :label="
                                                $lang.labels
                                                  .maxProcessingDuration
                                              "
                                              required
                                              data-cy="max-processing-duration"
                                              :rules="[
                                                (v) => !!v || $lang.errors.required, // Required validation
                                                (v) => validateMaxProcessingDuration(v, timeUnitPDur) || $lang.errors.between1Msecand7day
                                              ]"
                                              class="required-asterisk"
                                              :readonly="!userCanEdit"
                                              type="number"
                                              @keypress="filterNumber($event)"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="6" class="pl-1">
                                            <v-select
                                              v-model="timeUnitPDur"
                                              :items="timeUnits"
                                              :label="$lang.labels.unit"
                                              outlined
                                              dense
                                              required
                                              :readonly="!userCanEdit"
                                              style="min-width: 115px"
                                              data-cy="time-unit-pdur"
                                            >
                                              <template v-slot:item="{ item }">
                                                <v-list-item-content>
                                                  <v-list-item-title :data-cy="`${item} PDur`">{{ item }}</v-list-item-title>
                                                </v-list-item-content>
                                              </template>
                                            </v-select>
                                          </v-col>
                                          <v-col cols="6" class="pr-1">
                                            <v-text-field
                                              v-model="process.simultaneousExecutionsPerInstance"
                                              outlined
                                              dense
                                              :label="$lang.labels.simultaneousExecutionsPerInstance"
                                              required
                                              class="required-asterisk"
                                              :rules="[
                                                (v) => !!v || $lang.errors.required,
                                                (v) =>
                                                  v >= 1 ||
                                                  'Must be greater than 0',
                                                (v) =>
                                                  Number(v) <=
                                                  Number(process.overallSimultaneousExecutions) ||
                                                  'Must be less than or equal to overall simultaneous executions',
                                              ]"
                                              :readonly="!userCanEdit"
                                              data-cy="simultaneous-executions-per-instance"
                                              type="number"
                                              @keypress="filterNumber($event)"
                                            />
                                          </v-col>
                                          <v-col cols="6" class="pl-1">
                                            <v-text-field
                                              v-model="
                                                process.overallSimultaneousExecutions
                                              "
                                              outlined
                                              dense
                                              :label="
                                                $lang.labels
                                                  .overallSimultaneousExecutions
                                              "
                                              required
                                              :rules="[
                                                (v) => !!v || $lang.errors.required,
                                                (v) =>
                                                  v >= 1 ||
                                                  'Must be greater than 0',
                                              ]"
                                              class="required-asterisk"
                                              :readonly="!userCanEdit"
                                              data-cy="overall-simultaneous-executions"
                                              type="number"
                                              @change="
                                                $refs.form.validate()
                                              "
                                              @keypress="filterNumber($event)"
                                            ></v-text-field>
                                          </v-col>
                                          <v-col cols="12">
                                            <v-row
                                              wrap
                                              no-gutters
                                              justify="space-between"
                                              align="center"
                                            >
                                              <v-checkbox
                                                v-model="
                                                  process.isCacheStepLogsEnabled
                                                "
                                                hide-details
                                                :label="
                                                  $lang.labels
                                                    .isCacheStepLogsEnabled
                                                "
                                                data-cy="cache-step-logs"
                                              ></v-checkbox>
                                              <v-checkbox
                                                v-model="
                                                  process.isStepLogsEnabled
                                                "
                                                hide-details
                                                :label="
                                                  $lang.labels
                                                    .isStepLogsEnabled
                                                "
                                                data-cy="step-logs"
                                                @change="
                                                  process.isStepLogsEnabled
                                                    ? ''
                                                    : (process.isCacheStepLogsEnabled = false)
                                                "
                                              ></v-checkbox>
                                              <v-checkbox
                                                v-model="process.isDebugLogEnabled"
                                                hide-details
                                                :label="$lang.labels.isDebugLogEnabled"
                                                data-cy="step-logs"
                                              ></v-checkbox>
                                            </v-row>
                                            <v-row
                                              wrap
                                              no-gutters
                                              justify="space-between"
                                              align="center"
                                            >
                                              <v-checkbox
                                                v-model="
                                                  process.isGdprRelevant
                                                "
                                                hide-details
                                                :label="$lang.labels.gdpr"
                                                data-cy="gdpr"
                                              ></v-checkbox>
                                            </v-row>
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel
                                      :key="3"
                                      data-cy="mass-modify-all-steps"
                                    >
                                      <v-expansion-panel-header>
                                        <v-row
                                          no-gutters
                                          justify="start"
                                          align="center"
                                          class="mr-1"
                                        >
                                          <v-icon
                                            class="mr-1"
                                          >mdi-radar</v-icon>
                                          {{ $lang.labels.massModifyAllSteps }}
                                        </v-row>
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-row
                                          v-if="!loading"
                                          wrap
                                          no-gutters
                                        >
                                          <v-col cols="12">
                                            <div
                                              class="d-inline-flex justify-space-between max-width"
                                            >
                                              <v-checkbox
                                                v-model="massChange.stepsEnabled"
                                                hide-details
                                                class="pt-1"
                                                dense
                                                :label="$lang.labels.enabled"
                                                data-cy="enable-all-steps"
                                              ></v-checkbox>
                                              <v-btn
                                                small
                                                color="primary"
                                                data-cy="apply-enable-all-steps"
                                                @click="setValueOnAllSteps('enabled', massChange.stepsEnabled)"
                                              >
                                                <v-icon small left dark class="ma-0 mr-1">
                                                  mdi mdi-floppy
                                                </v-icon>
                                                {{
                                                  $lang.actions.apply
                                                }}
                                              </v-btn>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div
                                              class="d-inline-flex justify-space-between max-width"
                                            >
                                              <v-checkbox
                                                v-model="massChange.stepsCache"
                                                class="pt-1"
                                                hide-details
                                                dense
                                                :label="$lang.labels.enableCacheLog"
                                                data-cy="enable-cache-log"
                                              ></v-checkbox>
                                              <v-btn
                                                small
                                                color="primary"
                                                data-cy="apply-enable-cache-log"
                                                @click="setValueOnAllSteps('enableCacheLog', massChange.stepsCache)"
                                              >
                                                <v-icon small left dark class="ma-0 mr-1">
                                                  mdi mdi-floppy
                                                </v-icon>
                                                {{
                                                  $lang.actions.apply
                                                }}
                                              </v-btn>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div
                                              class="d-inline-flex justify-space-between max-width"
                                            >
                                              <v-checkbox
                                                v-model="massChange.stepsLogs"
                                                class="pt-1"
                                                hide-details
                                                dense
                                                :label="$lang.labels.enableStepLog"
                                                data-cy="enable-step-log"
                                              ></v-checkbox>
                                              <v-btn
                                                small
                                                color="primary"
                                                data-cy="apply-enable-step-log"
                                                @click="setValueOnAllSteps('enableStepLog', massChange.stepsLogs)"
                                              >
                                                <v-icon small left dark class="ma-0 mr-1">
                                                  mdi mdi-floppy
                                                </v-icon>
                                                {{
                                                  $lang.actions.apply
                                                }}
                                              </v-btn>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div
                                              class="d-inline-flex justify-space-between max-width"
                                            >
                                              <v-checkbox
                                                v-model="massChange.debugLogs"
                                                class="pt-1"
                                                hide-details
                                                dense
                                                :label="$lang.labels.enableDebugLog"
                                                data-cy="enable-step-log"
                                              ></v-checkbox>
                                              <v-btn
                                                small
                                                color="primary"
                                                data-cy="apply-enable-debug-log"
                                                @click="setValueOnAllSteps('enableDebugLog', massChange.debugLogs)"
                                              >
                                                <v-icon small left dark class="ma-0 mr-1">
                                                  mdi mdi-floppy
                                                </v-icon>
                                                {{
                                                  $lang.actions.apply
                                                }}
                                              </v-btn>
                                            </div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>

                                  </v-expansion-panels>
                                </v-col>
                              </v-row>
                              <v-row
                                no-gutters
                                wrap
                                justify="space-between"
                              >
                                <v-col cols="6" class="text-left">
                                  <div
                                    class="d-inline-flex align-center mt-2"
                                  >
                                    <v-btn
                                      outlined
                                      color="primary"
                                      :to="{
                                        name: 'processes',
                                        params: {
                                          lang: $lang.current_lang,
                                        },
                                      }"
                                      data-cy="process-back"
                                    >
                                      <v-icon
                                        left
                                        dark
                                      >
                                        mdi-arrow-left
                                      </v-icon>
                                      <span class="ml-1">{{
                                        $lang.actions.back
                                      }}</span>
                                    </v-btn>
                                    <action-button-with-confirmation
                                      v-if="isEdit"
                                      outlined
                                      :action-text="
                                        $lang.actions
                                          .areYouSureYouWantToDelete
                                      "
                                      :action-text-suffix="process.name"
                                      :title="$lang.actions.delete"
                                      type="process"
                                      :is-disabled="!userCanDelete"
                                      :button-text="$lang.actions.delete"
                                      :button-color="'error'"
                                      :data-cy="'process-delete'"
                                      :forced-option="true"
                                      :trigger-force-logic="
                                        triggerForceLogic
                                      "
                                      :regular-delete-errors-usages="
                                        regularDeleteErrorsUsages
                                      "
                                      class="ml-2 btn-size"
                                      :delete-success="deleteSuccess"
                                      :simple-error="err"
                                      @submit="deleteProcessFunct($event)"
                                      @closeDialog="''"
                                      @closeAfterDelete="
                                        $router.push({ name: 'processes' })
                                      "
                                    />
                                  </div>
                                </v-col>
                                <v-col
                                  cols="6"
                                  class="text-right mt-1 pt-1"
                                >
                                  <v-btn
                                    outlined
                                    :disabled="lock ||
                                      !userCanEdit ||
                                      process.steps.steps.length === 0 ||
                                      !isEdit"
                                    color="primary"
                                    class="ml-1 mr-1   color-accent-text"
                                    :to="{ name: 'processCreate', params: { lang: $lang.current_lang, copy: { id: process.id } } }"
                                  >
                                    <v-icon
                                      left
                                      dark
                                    >
                                      mdi-content-copy
                                    </v-icon>
                                    {{ $lang.actions.copyCreate }}
                                  </v-btn>
                                  <v-btn
                                    :disabled="!isFormValid"
                                    color="primary"
                                    class="btn-size"
                                    data-cy="save-process"
                                    @click="submit()"
                                  >
                                    <v-icon left dark class="mr-1">
                                      mdi mdi-floppy
                                    </v-icon>
                                    {{
                                      isEdit
                                        ? $lang.actions.save
                                        : $lang.actions.create
                                    }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-divider class="mt-4 mb-3"></v-divider>
                              <v-row
                                v-if="
                                  statistics &&
                                    statistics.eventStatuses &&
                                    statistics.eventStatuses.length > 0
                                "
                                wrap
                                no-gutters
                                justify="space-between"
                                align="center"
                                class="pb-1 pt-3"
                              >
                                <v-col cols="12">
                                  <v-row
                                    wrap
                                    no-gutters
                                    class="pl-1 pb-1"
                                    justify="space-between"
                                  >
                                    <h4>
                                      {{ $lang.labels.executions }}
                                    </h4>
                                    <div>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            small
                                            light
                                            color="error"
                                            data-cy="stop-execution"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="confirmStopExecutionShow = true"
                                          >
                                            <v-icon
                                              dense
                                              small
                                            >
                                              mdi-stop
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span>
                                          {{ $lang.labels.stopExecution }}
                                        </span>
                                      </v-tooltip>
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                            icon
                                            small
                                            light
                                            color="primary"
                                            class="ml-1"
                                            data-cy="refresh-statistics"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="fetchStatistics()"
                                          >
                                            <v-icon
                                              dense
                                              small
                                            >
                                              mdi-refresh
                                            </v-icon>
                                          </v-btn>
                                        </template>
                                        <span>
                                          {{ $lang.labels.refreshStatistics }}
                                        </span>
                                      </v-tooltip>
                                    </div>
                                  </v-row>
                                  <v-data-table
                                    :items="statArr"
                                    :headers="headersStatArr"
                                    item-key="text"
                                    class="elevation-0"
                                    dense
                                    hide-default-footer
                                    :items-per-page="-1"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-row wrap no-gutters class="pl-1 pt-2 pb-1">
                                    <h4>{{ $lang.labels.eventStates }}</h4>
                                  </v-row>
                                  <v-data-table
                                    :items="statistics.eventStatuses"
                                    :headers="headersStatistics"
                                    item-key="status"
                                    class="elevation-0"
                                    dense
                                    hide-default-footer
                                    :items-per-page="-1"
                                  >
                                    <!-- Customizing the status column -->
                                    <template v-slot:item.status="{ item }">
                                      <div>{{ $lang.status[item.status] }}</div>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                              <v-row
                                v-else
                                wrap
                                no-gutters
                                justify="space-between"
                                class="pt-3"
                              >
                                <v-progress-circular
                                  v-if="loadingStatistic"
                                  indeterminate
                                />
                                <h4 v-if="!loadingStatistic" class="pb-0 mb-0">
                                  {{ $lang.errors.noEvents }}
                                </h4>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      small
                                      light
                                      color="primary"
                                      class="ml-1"
                                      data-cy="refresh-statistics"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="fetchStatistics()"
                                    >
                                      <v-icon
                                        dense
                                        small
                                      >
                                        mdi-refresh
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    {{ $lang.labels.refreshStatistics }}
                                  </span>
                                </v-tooltip>
                              </v-row>
                            </v-form>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item :key="1">
                          <v-card
                            class="px-1 py-2"
                            color="transparent"
                          >
                            <v-row wrap no-gutters class="pb-4">
                              <v-col cols="12" class="pb-2">
                                <div
                                  v-if="allRoles && allRoles.length > 0"
                                  style="width: 100%; height: 100%"
                                >
                                  <h3 class="pb-1">
                                    {{ $lang.status.EDIT }}
                                  </h3>
                                  <user-roles-select
                                    :role="editRolesIds"
                                    :options="allRoles"
                                    data-cy="roles-edit"
                                    :required="false"
                                    :readonly="!userCanEdit"
                                    @changeRoles="editRolesIds = $event"
                                  ></user-roles-select>
                                  <h3 class="pb-1">
                                    {{ $lang.status.USE }}
                                  </h3>
                                  <user-roles-select
                                    :role="useRolesIds"
                                    :options="useRolePool"
                                    data-cy="roles-use"
                                    :required="false"
                                    :readonly="!userCanEdit"
                                    @changeRoles="useRolesIds = $event"
                                  ></user-roles-select>
                                  <h3 class="pb-1">
                                    {{ $lang.status.VIEW }}
                                  </h3>
                                  <user-roles-select
                                    :role="viewRolesIds"
                                    :options="viewRolePool"
                                    data-cy="roles-view"
                                    :required="false"
                                    :readonly="!userCanEdit"
                                    @changeRoles="viewRolesIds = $event"
                                  ></user-roles-select>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item :key="2" style="max-height: calc(100vh - 202px);">
                          <div style="width: 100%">
                            <v-row dense no-gutters>
                              <v-col cols="12">
                                <Table
                                  no-padding
                                  :items="history"
                                  @fetchHistory="fetchHistory($event)"
                                  @restoreHistory="restoreProcess($event)"
                                ></Table>
                              </v-col>
                            </v-row>
                          </div>
                        </v-tab-item>
                        <v-tab-item :key="3" style="width: 100%; height: 100%">
                          <v-card
                            class="px-1 py-2"
                            color="transparent"
                            style="width: 100%; height: 100%"
                          >
                            <div style="width: 100%; height: 100%">
                              <v-row dense no-gutters>
                                <v-col cols="12">
                                  <span
                                    class="clickable-simple color-primary pa-0 ma-0"
                                    @click="
                                      lock || !userCanEdit
                                        ? ''
                                        : (showMarkdown = true)
                                    "
                                  >
                                    {{ $lang.actions.edit }}
                                  </span>
                                </v-col>
                                <v-col cols="12">
                                  <div
                                    class="mark-class pa-1 clickable-simple"
                                    style="width: 100%"
                                    data-cy="process-comment"
                                    @click="
                                      lock || !userCanEdit
                                        ? ''
                                        : (showMarkdown = true)
                                    "
                                    v-html="compiledMarkdown"
                                  ></div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-row>
                    <v-row
                      v-show="showEventsAndStepWrapper"
                      no-gutters
                      justify="center"
                      class="fill-height"
                      style="overflow-y: auto; overflow-x: hidden"
                    >
                      <v-expansion-panels
                        id="expansionProcessAdvanced"
                        v-model="topPanelIndex"
                        multiple
                        flat
                        class="d-block"
                      >
                        <v-expansion-panel
                          :key="0"
                          readonly
                          data-cy="events-and-steps"
                        >
                          <v-expansion-panel-header class="pr-1">
                            <v-row
                              wrap
                              no-gutters
                              class="pl-1 justify-space-between align-center"
                            >
                              <div class="d-inline-flex align-center">
                                <v-text-field
                                  id="eventId"
                                  ref="eventId"
                                  v-model="selectedEventId"
                                  class="custom-placeholder"
                                  style="position: relative; width: 142px;"
                                  outlined
                                  dense
                                  type="number"
                                  :label="$lang.labels.eventId"
                                  hide-details
                                  data-cy="event-id"
                                  @input="stepLogsSearch = false"
                                  @keyup.enter="
                                    selectedEventId && selectedEventId.length > 0
                                      ? fetchLogs(selectedEventId)
                                      : fetchEventsLogs()
                                  "
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      v-if="selectedEventId"
                                      dense
                                      :color="iconColorChanger()"
                                      style="position: absolute; right: 9px;top: 10px;"
                                    >mdi-circle</v-icon>
                                    <v-progress-circular
                                      v-if="selectedEventId"
                                      indeterminate
                                      size="30"
                                      :color="
                                        loadingAutoRefresh
                                          ? 'primary'
                                          : 'transparent'
                                      "
                                      style="
                                        position: absolute;
                                        right: 4px;
                                        top: 5px;
                                      "
                                    />
                                  </template>
                                </v-text-field>
                                <v-btn
                                  :disabled="
                                    lock || process.steps.steps.length === 0
                                  "
                                  icon
                                  light
                                  small
                                  color="primary"
                                  class="mx-1"
                                  data-cy="search-logs"
                                  @click="
                                    selectedEventId && selectedEventId.length > 0
                                      ? fetchLogs(selectedEventId)
                                      : fetchEventsLogs()
                                  "
                                >
                                  <v-icon
                                    dense
                                    small
                                  >
                                    mdi-refresh
                                  </v-icon>
                                </v-btn>
                              </div>
                              <div class="d-inline-flex align-center">
                                <v-btn
                                  :disabled="
                                    lock ||
                                      process.steps.steps.length === 0 ||
                                      !selectedEventId
                                  "
                                  small
                                  color="primary"
                                  class="mx-1"
                                  data-cy="fetch-event"
                                  @click="fetchEvent()"
                                >{{ $lang.labels.event }}
                                </v-btn>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      :disabled="
                                        lock ||
                                          process.steps.steps.length === 0 ||
                                          selectedLogIndex === 0
                                      "
                                      icon
                                      small
                                      color="primary"
                                      class="mx-1"
                                      v-bind="attrs"
                                      data-cy="arrow-up-log"
                                      v-on="on"
                                      @click="
                                        selectedLogIndex
                                          ? selectedLogIndex--
                                          : (selectedLogIndex = 0)
                                      "
                                    >
                                      <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $lang.labels.previousLogStep }}</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      v-if="$vuetify.breakpoint.lgAndUp"
                                      :disabled="
                                        lock ||
                                          process.steps.steps.length === 0 ||
                                          selectedLogIndex === logs.length - 1
                                      "
                                      icon
                                      small
                                      color="primary"
                                      v-bind="attrs"
                                      class="mx-1"
                                      data-cy="arrow-down-log"
                                      v-on="on"
                                      @click="
                                        selectedLogIndex || selectedLogIndex === 0
                                          ? selectedLogIndex++
                                          : (selectedLogIndex = 0)
                                      "
                                    >
                                      <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $lang.labels.nextLogStep }}</span>
                                </v-tooltip>
                              </div>
                              <div class="d-inline-flex align-center">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      :disabled="
                                        lock || process.steps.steps.length === 0
                                      "
                                      icon
                                      small
                                      color="primary"
                                      class="mx-1"
                                      :loading="lastEventsLogsLoader"
                                      v-bind="attrs"
                                      data-cy="fetch-events-logs"
                                      v-on="on"
                                      @click="fetchEventsLogs()"
                                    >
                                      <v-icon>mdi-page-last</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $lang.labels.fetchLastEventLogs }}</span>
                                </v-tooltip>
                              </div>
                            </v-row>

                            <template v-slot:actions>
                              <v-btn
                                icon
                                small
                                :style="
                                  topPanelIndex.includes(0)
                                    ? 'display: block'
                                    : 'display: none'
                                "
                                data-cy="close-events-and-steps"
                                @click.stop="headerTopRemove(0)"
                              >
                                <v-icon>{{
                                  topPanelIndex.includes(0)
                                    ? "mdi-chevron-down"
                                    : ""
                                }}</v-icon>
                              </v-btn>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content v-if="Boolean($route.query.eventId)">
                            <v-row
                              id="logsScrollElement"
                              wrap
                              no-gutters
                              class="align-center"
                              style="max-height: 32vh; overflow-y: auto"
                            >
                              <v-data-table
                                :headers="headers"
                                :items="logs"
                                item-key="id"
                                class="pt-1 background-transparent"
                                dense
                                style="width: 100%"
                                :item-class="selectedRow"
                                :options.sync="logsOptionsPagination"
                                :server-items-length="totalLogItems"
                                :footer-props="{
                                  'items-per-page-options': rowsPerPageItemsGlobal,
                                }"
                                @click:row="selectTableRow"
                              >
                                <template v-slot:top>
                                  <v-row no-gutters>
                                    <v-col class="pr-1">
                                      <v-select
                                        v-model="logsOptions.status"
                                        :items="formattedStatuses"
                                        :label="$lang.labels.status"
                                        outlined
                                        dense
                                        clearable
                                      ></v-select>
                                    </v-col>
                                    <v-col class="pr-1">
                                      <v-select
                                        v-model="logsOptions.stepType"
                                        :items="formattedStepTypes"
                                        :label="$lang.labels.stepTypes"
                                        outlined
                                        dense
                                        clearable
                                      ></v-select>
                                    </v-col>
                                    <v-col class="pr-1">
                                      <v-autocomplete
                                        v-model="logsOptions.stepId"
                                        :search-input.sync="logSearchStepId"
                                        hide-selected
                                        outlined
                                        dense
                                        clearable
                                        item-text="title"
                                        :items="formattedSteps"
                                        hide-no-data
                                        :label="$lang.labels.step"
                                        :placeholder="$lang.actions.startTyping"
                                        :disabled="logs?.length === 0"
                                        prepend-inner-icon="mdi-format-list-bulleted"
                                      ></v-autocomplete>
                                    </v-col>
                                  </v-row>
                                </template>

                                <template v-slot:item.stepId="{ item, index }">
                                  <div
                                    :id="`row-${
                                      foundLogsSteps[item.stepId]
                                    }-${index}`"
                                    class="font-weight-bold"
                                  >
                                    {{
                                      foundLogsSteps[item.stepId] || "Not present"
                                    }}
                                  </div>
                                </template>

                                <template v-if="logs && logs.length > 0" v-slot:item.finishedOn="{ item }">
                                  <div>
                                    {{ item.finishedOn | formatDateTimePrecise }}
                                  </div>
                                </template>

                                <template v-slot:item.status="{ item }">
                                  <div v-if="item.stepType === 'EXECUTE_PROCESS'">
                                    {{ $lang.status[item.status]
                                    }}{{
                                      item.stepType === "EXECUTE_PROCESS" &&
                                        item.childEventId
                                        ? ": "
                                        : ""
                                    }}<span
                                      class="clickable-simple underline-simple"
                                      style="z-index: 1000"
                                      data-cy="open-step-from-debug"
                                      @click="
                                        openStepFromDebug(
                                          item.stepId,
                                          item.childEventId
                                        )
                                      "
                                    >{{
                                      item.stepType === "EXECUTE_PROCESS" &&
                                        item.childEventId
                                        ? item.childEventId
                                        : ""
                                    }}</span>
                                  </div>
                                  <div
                                    v-else-if="
                                      ['JDBC', 'MONGODB', 'LOG'].includes(
                                        item.stepType
                                      )
                                    "
                                  >
                                    {{ $lang.status[item.status]
                                    }}{{ item.isExistDebugLog ? ": " : ""
                                    }}<span
                                      class="clickable-simple underline-simple"
                                      style="z-index: 1000"
                                      @click="fetchStepDebugLog(item.id)"
                                    >{{
                                      item.isExistDebugLog ? $lang.labels.debug : ""
                                    }}</span>
                                  </div>
                                  <div v-else>
                                    {{ $lang.status[item.status]
                                    }}{{ item.isExistDebugLog ? ": " : ""
                                    }}<span
                                      v-if="item.isExistDebugLog"
                                      class="clickable-simple underline-simple"
                                      style="z-index: 1000"
                                      data-cy="debug-log-show"
                                      @click="fetchStepDebugLog(item.id)"
                                    >{{
                                      item.isExistDebugLog ? $lang.labels.debug : ""
                                    }}</span>
                                  </div>
                                </template>

                                <template v-slot:item.stepType="{ item }">
                                  <div>{{ $lang.status[item.stepType] }}</div>
                                </template>

                                <template v-slot:item.actions="{ item }">
                                  <div class="d-inline-flex">
                                    <v-btn
                                      small
                                      color="primary"
                                      class="mx-1"
                                      data-cy="open-log-cache"
                                      @click="openLogCache(item.id)"
                                    >
                                      <span class="color-customStepLogSelect">{{
                                        $lang.labels.showCache
                                      }}</span>
                                    </v-btn>
                                    <v-btn
                                      small
                                      color="primary"
                                      class="mx-1"
                                      :disabled="!item.isExistException"
                                      data-cy="open-exception-stack-trace"
                                      @click="fetchStepStackTrace(item)"
                                    >{{
                                      $lang.labels.est
                                    }}
                                    </v-btn>
                                  </div>
                                </template>
                              </v-data-table>
                            </v-row>
                            <v-row
                              v-if="logs.length === 0 && stepLogsSearch"
                              no-gutters
                            >
                              <v-col cols="12" class="">
                                <v-alert
                                  dense
                                  color="warning"
                                  style="color: black"
                                >{{ $lang.errors.noLogs }} {{ selectedEventId }}
                                </v-alert>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :key="1" class="mt-2" readonly>
                          <v-expansion-panel-header id="toScrollToStep">
                            <v-row no-gutters class="align-center ms-2">
                              <h4 class="font-weight-light">
                                <span v-if="selectedItem && topPanelIndex.includes(1)">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span v-bind="attrs" v-on="on" @click="copyToClipboard(selectedItem.localId)">
                                        {{ `${$lang.labels.visualId}: ${selectedItem.localId}` }}
                                      </span>
                                    </template>
                                    <span>{{ $lang.labels.copyIdToClipboard }}</span>
                                  </v-tooltip>
                                  {{
                                    selectedItem && topPanelIndex.includes(1)
                                      ? `, ${$lang.labels.type}: ${
                                        selectedItem.type
                                          ? $lang.status[selectedItem.type]
                                          : selectedItem.subType
                                            ? $lang.status[selectedItem.subType]
                                            : ""
                                      }`
                                      : ""
                                  }}
                                </span>
                              </h4>
                            </v-row>

                            <template v-slot:actions>
                              <v-btn
                                icon
                                small
                                class="mr-1"
                                :style="
                                  topPanelIndex.includes(1)
                                    ? 'display: block'
                                    : 'display: none'
                                "
                                @click.stop="headerTopRemove(1)"
                              >
                                <v-icon>{{
                                  topPanelIndex.includes(1)
                                    ? "mdi-chevron-down"
                                    : ""
                                }}</v-icon>
                              </v-btn>
                            </template>
                          </v-expansion-panel-header >
                          <v-expansion-panel-content style="min-height: 100%">
                            <v-form v-if="selectedItem" v-model="validStep">
                              <v-row no-gutters style="min-height: 100%">
                                <div class="pr-1 pb-1" style="width: 100%; min-height: 100%;">
                                  <v-text-field
                                    v-if="selectedItem && showProcessOrStepName"
                                    v-show="selectedItem.type && !selectedItem.subType"
                                    v-model="selectedItem.name"
                                    dense
                                    outlined
                                    color="primary"
                                    :label="$lang.labels.stepName"
                                    required
                                    class="mb-2 required-asterisk"
                                    :rules="[
                                      v => v && v.toString().trim() ? true : $lang.errors.required, // Ensures input is not empty or only spaces
                                      v => v && v.toString().trim().length >= 1 ? true : $lang.errors.nameTooShort // Checks if the length is 1 or more
                                    ]"
                                    :readonly="!userCanEdit"
                                    persistent-hint
                                    :hint="formatStepHint"
                                    data-cy="step-name"
                                    append-icon="mdi-vector-combine"
                                    @click:append="copyToClipboard(selectedItem.name)"
                                  />
                                  <h3
                                    v-show="
                                      selectedItem.subType &&
                                        selectedItem.subType === 'QUERY'
                                    "
                                  >
                                    Query
                                  </h3>
                                  <h3
                                    v-show="
                                      selectedItem.subType &&
                                        selectedItem.subType === 'EXCEPTION'
                                    "
                                  >
                                    Exceptions
                                  </h3>
                                  <step-properties
                                    :key="`${selectedItem.id}-${selectedItem.localId}-step-properties-wrapper`"
                                    :can-edit="userCanEdit"
                                    :is-new-selected="isNewSelected"
                                    :step="selectedItem"
                                    :steps="process.steps.steps"
                                    :filtered-exceptions="filteredExceptions"
                                    :resource-is-system="process.isSystem"
                                    @save="submit()"
                                    @showSnack="showSnack($event.text, $event.type)"
                                    @err="showError($event)"
                                  />
                                  <!-- @saveSelected="saveSelected($event)" -->
                                  <div
                                    class="d-inline-flex justify-space-between max-width"
                                  >
                                    <v-checkbox
                                      v-if="selectedItem.type"
                                      v-model="selectedItem.enabled"
                                      hide-details
                                      class="pt-1"
                                      dense
                                      :label="$lang.labels.enabled"
                                      data-cy="enable-step"
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-if="selectedItem.type"
                                      v-model="selectedItem.enableCacheLog"
                                      class="pt-1"
                                      hide-details
                                      dense
                                      :label="$lang.labels.enableCacheLog"
                                      data-cy="enable-cache-log"
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-if="selectedItem.type"
                                      v-model="selectedItem.enableStepLog"
                                      class="pt-1"
                                      hide-details
                                      dense
                                      :label="$lang.labels.enableStepLog"
                                      data-cy="enable-step-log"
                                      @change="
                                        selectedItem.enableStepLog
                                          ? ''
                                          : (selectedItem.enableCacheLog = false)
                                      "
                                    ></v-checkbox>
                                    <v-checkbox
                                      v-if="selectedItem.type"
                                      v-model="selectedItem.enableDebugLog"
                                      class="pt-1"
                                      hide-details
                                      dense
                                      :label="$lang.labels.enableDebugLog"
                                      data-cy="enable-debug-log"
                                    ></v-checkbox>
                                  </div>
                                </div>
                              </v-row>
                            </v-form>
                            <v-row v-else no-gutters>
                              <p>Select step first</p>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
        data-cy="snack-default"
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
      <multi-errors-snackbar
        :show="isErrorShow"
        :errors="errorsNew"
        @errorClick="openStepFromError"
        @close="isErrorShow = false; isError = ''; errorsNew = []"
      />
      <v-dialog
        v-if="showTypeSelector"
        v-model="showTypeSelector"
        max-width="30%"
      >
        <create-dialog
          is-step
          :types="[...stepTypes, 'PASTE']"
          @create="createStep"
          @closeDialog="showTypeSelector = false"
        ></create-dialog>
      </v-dialog>
      <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
        <yes-no-modal
          :title="$lang.actions.delete"
          :action-text="
            doesHaveChildren(selectedDeleteItem)
              ? $lang.header.sureDeleteStepChildren
              : $lang.header.sureDeleteStep
          "
          @submit="deleteStep"
          @closeDialog="
            selectedDeleteItem = null;
            yesNoShow = false;
          "
        ></yes-no-modal>
      </v-dialog>
      <v-dialog
        v-if="showCacheDetails"
        v-model="showCacheDetails"
        max-width="50%"
        style="min-height: 80vh; max-height: 80vh"
      >
        <log-cache
          :data="selectedCacheData"
          :cache-size="cacheSize"
          @closeDialog="
            selectedCacheData = null;
            showCacheDetails = false;
          "
          @downloadCache="downloadCache()"
        ></log-cache>
      </v-dialog>
      <v-dialog
        v-if="showSelectedEst"
        v-model="showSelectedEst"
        max-width="71%"
        style="min-height: 80vh; max-height: 80vh"
      >
        <log-est
          :exception="selectedEst"
          @closeDialog="
            selectedEst = null;
            showSelectedEst = false;
          "
        />
      </v-dialog>
      <v-dialog
        v-if="showDebugLog"
        v-model="showDebugLog"
        max-width="71%"
        style="min-height: 80vh; max-height: 80vh"
      >
        <log-debug-modal
          :debug-log-data="debugLogData"
          @closeDialog="
            debugLogData = null;
            showDebugLog = false;
          "
        />
      </v-dialog>
      <v-dialog
        v-if="showExceptionDetails"
        v-model="showExceptionDetails"
        max-width="1280"
        style="min-height: 80vh; max-height: 80vh"
      >
        <log-cache-event-modal
          :event-id="String(selectedEventId)"
          @closeDialog="showExceptionDetails = false;"
        ></log-cache-event-modal>
      </v-dialog>
      <v-dialog v-if="cacheLoading" v-model="cacheLoading" max-width="30%">
        <cache-loading
          :title="$lang.labels.showCache"
          :action-text="$lang.hints.dataTooLArge"
          :loading="cacheInnerLoading"
          @submit="downloadCache()"
          @closeDialog="
            cacheDownload = null;
            cacheLoading = false;
            cacheInnerLoading = false;
          "
        />
      </v-dialog>
      <v-dialog
        v-if="showMarkdown"
        v-model="showMarkdown"
        max-width="80%"
        width="80%"
      >
        <markdown-modal
          :markdown-data="process.comment"
          @save="process.comment = $event"
          @closeDialog="showMarkdown = false"
        />
      </v-dialog>
      <v-dialog
        v-if="showImport"
        v-model="showImport"
        max-width="80%"
        width="80%"
      >
        <import-json-modal
          v-if="showImport"
          type="JS"
          @import="
            showImport = false;
            importFunction($event);
          "
          @closeDialog="showImport = false"
        />
      </v-dialog>
      <v-dialog v-if="yesNoDisableShow" v-model="yesNoDisableShow" max-width="55%">
        <yes-no-modal
          :title="$lang.labels.activeTriggers"
          :action-text="textForRunModal"
          :handle-no="true"
          @submit="deactivateTriggers()"
          @noSelected="changeStatus()"
          @closeDialog="yesNoDisableShow = false; processToDisable = null; triggerListToDeactivate = []"
        ></yes-no-modal>
      </v-dialog>

      <v-dialog v-if="confirmLeaveShow" v-model="confirmLeaveShow" max-width="55%">
        <yes-no-modal
          :title="$lang.hints.unsavedTitle"
          :action-text="$lang.hints.unsavedChanges"
          :handle-no="true"
          @submit="confirmNext"
          @noSelected="confirmLeaveShow = false; confirmNext = null"
          @closeDialog="confirmLeaveShow = false; confirmNext = null"
        ></yes-no-modal>
      </v-dialog>
      <v-dialog
        v-if="showChangedName && connectedTriggers.length > 0"
        v-model="showChangedName"
        max-width="55%"
      >
        <v-card class="pa-4">
          <v-row
            wrap
            no-gutters
          >
            <v-col cols="12">
              <div class="d-flex max-width justify-space-between align-center">
                <h4>{{ $lang.labels.affectedTriggers }}</h4>
                <v-btn
                  icon
                  small
                  dark
                  text
                  @click="showChangedName = false; connectedTriggers = []"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="triggerHeaders"
                :items="connectedTriggers"
                item-key="id"
                class="background-transparent"
                :items-per-page="-1"
                hide-default-footer
              >

                <template v-slot:item.type="{ item }">
                  <div>{{ $lang.status[item.type] }}</div>
                </template>

                <template v-slot:item.status="{ item }">
                  <div>{{ $lang.status[item.status] }}</div>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn
                    small
                    text
                    color="primary"
                    class="mx-0"
                    :data-cy="`open-trigger-${item.id}`"
                    @click="openTriggerItem(item)"
                  >{{ $lang.actions.open }}</v-btn>
                </template>
              </v-data-table>
              <div class="d-flex mt-2 w-100 justify-end">
                <v-btn
                  color="primary"
                  text
                  data-cy="submit-renaming-triggers-dialog"
                  @click="submit(true)"
                >
                  {{ $lang.actions.confirmAndSave }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog v-if="confirmStopExecutionShow" v-model="confirmStopExecutionShow" max-width="55%">
        <yes-no-modal
          :title="$lang.labels.stopExecution"
          :action-text="$lang.labels.areYouSureExecutionStop"
          :handle-no="true"
          @submit="stopExecutionOfEvents()"
          @noSelected="confirmStopExecutionShow = false"
          @closeDialog="confirmStopExecutionShow = false"
        ></yes-no-modal>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import {
  createProcessUsingPOST as createProcess,
  deleteProcessUsingDELETE as deleteProcess,
  disableProcessUsingGET as disableProcess,
  disableTriggerCronUsingGET as disableCron,
  disableTriggerEventHandlerUsingGET as disableEventHandler,
  disableTriggerMessagingUsingGET as disableMessaging,
  disableTriggerRestUsingGET as disableRest,
  enableProcessUsingGET as enableProcess,
  getEventByIdUsingGET as getEvent,
  getHistoriesUsingGET as getHistories,
  getMostRecentEventIdUsingGET as getEventsLogs,
  getProcessByIdUsingGET as getProcess,
  getProcessesUsingGET as getProcesses,
  getProcessExceptionUsingGET as getException,
  getProcessStatisticsUsingGET as getStatistics,
  getProcessStepLogCacheUsingGET as getProcessStepLogCache,
  getProcessStepLogDebugLogUsingGET as getDebugLog,
  getProcessStepLogsUsingGET as getLogs,
  getTriggersByProcessIdUsingGET as getTriggersByProcessId,
  getValidationRuleByIdUsingGET as getValidationRule,
  getValidationRulesUsingGET as getValidations,
  interruptEventsByProcessIdUsingPOST as interruptEvents,
  updateProcessUsingPUT as updateProcess
} from '@/utils/api'
import {
  calculateDuration,
  canUserEditResource,
  castAllStepsValues,
  doesProcessNameExist,
  filterNumber,
  getAllStepsData,
  getRolesWithoutAuth,
  predictBestTimeUnitFromMsValue,
  removeAdditionalPropertiesFromSteps,
  safeJSONParse
} from '@/utils/helpers'
import addRow from './AddRow'
import stepProperties from './StepProperties'
import CreateDialog from '../../components/ui/modals/CreateDialogModal'
import YesNoModal from '@/components/ui/modals/YesNoModal'
import MarkdownModal from '@/components/ui/modals/MarkdownModal'
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import LogCache from './LogCache'
import LogEst from './LogEst'
import LogDebugModal from '../../components/ui/modals/LogDebugModal'
import LogCacheEventModal from '../../components/ui/modals/LogCacheEventModal'
import MultiErrorsSnackbar from '../../components/ui/MultiErrorsSnackbar.vue'
import CacheLoading from '../../components/ui/modals/LoadingModal'
import { format, sub } from 'date-fns'
import { marked } from 'marked'
import copy from 'copy-to-clipboard'
import ProcessMermaid from '../../components/ui/ProcessMermaid'
import ProcessDiagram from '../../components/ui/ProcessDiagram.vue'
import { MAX_KB_CACHE_TO_DISPLAY, stepExceptions } from '@/utils/constants'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import ImportJsonModal from '@/components/ui/modals/ImportJsonModal.vue'
import ProcessValidationsSearchField from './ProcessValidationsSearchField.vue'
import { mapActions, mapState } from 'vuex'
import { cloneDeep, debounce, isEqual, merge } from 'lodash'
import { definitions } from '@/utils/definitions'
import { bus } from '@/main'

export default {
  name: 'ProcessDetails',
  components: {
    ActionButtonWithConfirmation,
    addRow,
    stepProperties,
    CreateDialog,
    YesNoModal,
    Table,
    LogCache,
    LogEst,
    LogCacheEventModal,
    CacheLoading,
    MarkdownModal,
    LogDebugModal,
    ProcessMermaid,
    ProcessDiagram,
    UserRolesSelect,
    ImportJsonModal,
    ProcessValidationsSearchField,
    MultiErrorsSnackbar
  },
  data() {
    return {
      validStep: true,
      colorByStatus: {
        FAILED: '#FF5252',
        IN_PROGRESS: '#59bbbb',
        NEW: '#2196F3',
        ON_HOLD: '#696773',
        PROCESS_INACTIVE: '#FFC107',
        SUCCESSFUL: '#05c075',
        TIMEOUT: '#511127'
      },
      confirmStopExecutionShow: false,
      confirmNext: null,
      confirmLeaveShow: false,
      isDirty: false,
      needsFirstSavingToStore: true,
      originalProcess: null,
      showImport: false,
      mini: false,
      radioButtonsVal: '1',
      mermaidGrouping: false,
      showProcessMermaid: false,
      showProcessDiagram: false,
      processMermaidType: false,
      isCytoscapeDiagramHorizontal: true,
      showMarkdown: false,
      showSelectedEst: false,
      selectedEst: null,
      showDebugLog: false,
      debugLogData: null,
      selectedCacheData: null,
      showCacheDetails: false,
      isErrorShow: false,
      isError: '',
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      showTypeSelector: false,
      isNewSelected: false,
      tab: 0,
      borderSize: 2,
      err: '',
      success: '',
      valid: false,
      isEdit: false,
      loading: true,
      loadingStatistic: true,
      lock: false,
      process: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        status: '',
        logsTtlInMSec: '',
        errorsTtlInMSec: '',
        maxProcessingDurationInMSec: '',
        overallSimultaneousExecutions: 1,
        simultaneousExecutionsPerInstance: 1,
        steps: { steps: [] },
        isGdprRelevant: false,
        roles: [],
        isCacheStepLogsEnabled: false,
        isStepLogsEnabled: true,
        isDebugLogEnabled: true,
        comment: '',
        inputValidationRuleId: '',
        outputValidationRuleId: '',
        isSystem: false
      },
      timeUnits: ['MSec', 'Sec', 'Min', 'Hr', 'Day'],
      timeUnitPDur: 'Sec',
      timeUnitTtl: 'Hr',
      timeUnitErrTtl: 'Hr',
      tempMaxProcessingDurationInMSec: '60',
      tempLogsTtlInMSec: '24',
      tempErrorsTtlInMSec: '24',
      selectedItem: null,
      tableKey: 0,
      stepTypes: definitions.StepStepProperties.properties.type.enum.sort(),
      beforeStep: null,
      addIsSpecial: false,
      addIsInner: false,
      addIsAfter: false,
      tryCatch: '',
      newStep: {
        enabled: true,
        id: 0,
        isSelected: true,
        name: '',
        properties: {},
        type: '',
        enableStepLog: true,
        enableCacheLog: false,
        enableDebugLog: true
      },
      newQuery: {
        query: '',
        name: '',
        isSelected: true,
        steps: [],
        subType: 'QUERY'
      },
      newException: {
        exceptions: [],
        name: '',
        isSelected: true,
        steps: [],
        subType: 'EXCEPTION'
      },
      previewLock: false,
      highestId: 0,
      firstKey: 0,
      moveLock: false,
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      yesNoShow: false,
      selectedDeleteItem: null,
      duplicateLock: false,
      dragLock: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      logs: [],
      totalLogItems: 0,
      logsOptions: {
        stepId: null,
        stepType: null,
        status: null
      },
      logsOptionsPagination: {},
      logSearchStepId: null,
      logKey: 0,
      selectedLogIndex: null,
      headers: [
        { text: this.$lang.labels.stepId, value: 'stepId' },
        { text: this.$lang.labels.status, value: 'status', sortable: false },
        {
          text: this.$lang.labels.duration,
          value: 'duration',
          sortable: false
        },
        {
          text: this.$lang.labels.actions,
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      headersStatArr: [
        { text: this.$lang.header.timeSpan, value: 'text' },
        { text: this.$lang.header.count, value: 'value' }
      ],
      headersStatistics: [
        { text: this.$lang.header.state, value: 'status' },
        { text: this.$lang.header.count, value: 'count' }
      ],
      selectedEventId: '',
      stepLogsSearch: false,
      showExceptionDetails: false,
      selectedEventData: null,
      selectedExceptionData: null,
      idIncreaseItem: null,
      foundLogsSteps: {},
      cacheLoading: false,
      cacheInnerLoading: false,
      cacheDownload: null,
      cacheSize: null,

      statistics: null,
      statArr: [],
      errorItems: [],
      errorsNew: [],
      timeSpanArr: [1, 2, 4, 8, 12, 16, 20, 24],
      lastEventsLogsLoader: false,
      autoRefreshTimeout: null,
      loadingAutoRefresh: false,
      mermaidId: 0,
      filteredExceptions: {},
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      ttlLogsHint: false,
      ttlErrLogsHint: false,
      panelIndex: null,
      topPanelIndex: [],
      searchValidations: '',
      isLoadingValidations: false,
      validations: [],
      resizeMeWidth: 0,
      showDetailsWrapper: true,
      showEventsAndStepWrapper: false,
      showEvents: false,
      massChange: {
        stepsEnabled: false,
        stepsLogs: false,
        stepsCache: false,
        debugLogs: false
      },
      yesNoDisableShow: false,
      processToDisable: null,
      textForRunModal: '',
      triggerListToDeactivate: [],
      connectedTriggers: [],
      triggerHeaders: [
        { text: this.$lang.labels.type, value: 'type' },
        { text: this.$lang.labels.name, value: 'name' },
        { text: this.$lang.labels.status, value: 'status' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      showProcessOrStepName: false,
      afterUndoOrRedo: false,
      originalProcessNameOnEdit: '',
      showChangedName: false,
      statuses: ['SUCCESSFUL', 'FAILED']
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'processEdit') {
      next()

      return
    }
    if (this.isDirty) {
      this.confirmLeaveShow = true
      this.confirmNext = next
    } else {
      // eslint-disable-next-line callback-return
      next()
    }
  },
  computed: {
    ...mapState('app', [
      'rowsPerPageItemsGlobal',
      'userSettings',
      'processCurrent',
      'processUndoStack',
      'processRedoStack'
    ]),
    isFormValid() {
      return this.valid && this.validStep && !this.lock && this.userCanEdit && this.process.steps.steps.length > 0
    },
    formattedStepTypes() {
      return this.stepTypes.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedSteps() {
      return getAllStepsData(this.process?.steps?.steps || [], (step) => ({ value: step.id, title: step.name })) || []
    },
    formattedStatuses() {
      return this.statuses.map((x) => ({ text: this.$lang.status[x], value: x }))
    },
    compiledMarkdown: function () {
      return marked.parse(this.process?.comment) || ''
    },
    formatHint() {
      return `${
        this.$lang.labels.createdOn
      }: ${this.$options.filters.formatDateTime(this.process.createdOn)}, ${
        this.$lang.labels.modifiedOn
      }: ${this.$options.filters.formatDateTime(
        this.process.modifiedOn
      )}, ID: ${this.process.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter(
        (x) =>
          !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id)
      )
    },
    formatStepHint() {
      return `${this.$lang.labels.stepId}: ${
        this.selectedItem && this.selectedItem.id ? this.selectedItem.id : ''
      }`
    },
    stepsToDisplay() {
      return this.process?.steps?.steps || []
    },
    topAlarmLabel() {
      return this.process.isSystem
        ? 'isSystem-hint'
        : this.process.logsTtlInMSec === 0 && this.tempLogsTtlInMSec === 0
          ? 'nonErrorLogsIndefiniteWarning-hint'
          : this.process.errorsTtlInMSec === 0 && this.tempErrorsTtlInMSec === 0
            ? 'errorLogsIndefiniteWarning-hint'
            : ''
    },
    topAlarmHint() {
      return this.process.isSystem
        ? this.$lang.hints.resourceIsSystem
        : this.process.logsTtlInMSec === 0 && this.tempLogsTtlInMSec === 0
          ? this.$lang.hints.nonErrorLogsIndefiniteWarning
          : this.process.errorsTtlInMSec === 0 && this.tempErrorsTtlInMSec === 0
            ? this.$lang.hints.errorLogsIndefiniteWarning : ''
    }
  },
  watch: {
    process: {
      handler: debounce(function(val) {
        if (this.originalProcess !== null) {
          const localOriginalProcess = cloneDeep(this.originalProcess)

          delete localOriginalProcess.steps
          delete localOriginalProcess.status

          const localProcess = cloneDeep(this.process)

          delete localProcess.steps
          delete localProcess.status

          const localOriginalSteps = this.removeAdditionalPropertiesFromSteps(
            cloneDeep(this.originalProcess.steps.steps), true
          )

          const localSteps = this.removeAdditionalPropertiesFromSteps(
            cloneDeep(this.process.steps.steps), true
          )

          setTimeout(() => {
            const wasProcessChanged =
            !isEqual(localProcess, localOriginalProcess)
            || !isEqual(localSteps, localOriginalSteps)

            this.isDirty = wasProcessChanged

            if ((wasProcessChanged || this.needsFirstSavingToStore) && !this.afterUndoOrRedo) {
              localProcess.steps = { steps: localSteps }

              this.$store.dispatch(
                'app/saveProcessState',
                {
                  process: localProcess
                })
              this.needsFirstSavingToStore = false
            } else if (this.afterUndoOrRedo) {
              this.afterUndoOrRedo = false
            }
          }, 100)
        }
      }, 500),
      deep: true
    },
    isFormValid(val) {
      this.formValid(val)
    },
    tab: {
      handler() {
        if (this.tab === 2) {
          this.fetchHistory()
        }
        setTimeout(() => {
          this.headerTopControl(0)
        }, 1)
      }
    },
    searchValidations: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchValidationsFunction(val)
        }
      }
    },
    selectedLogIndex: {
      handler(val) {
        this.logs.map((x, i) => {
          x.isSelected = val === i

          return x
        })
        this.selectStepFromLog(
          this.foundLogsSteps[this.logs[val].stepId] || 'Not present'
        )
      }
    },
    radioButtonsVal: {
      handler(val) {
        if (val === '1') {
          this.showProcessMermaid = false
          this.processMermaidType = false
          this.showProcessDiagram = false
        }
        if (val === '2') {
          this.showProcessDiagram = true
          this.showProcessMermaid = false
          this.isCytoscapeDiagramHorizontal = false
        }
        if (val === '3') {
          this.showProcessDiagram = true
          this.showProcessMermaid = false
          this.isCytoscapeDiagramHorizontal = true
        }
      }
    },
    panelIndex: {
      handler(val) {
        if (val === 0) {
          if (this.process.id) this.fetchActiveTriggersForProcess()
        } else {
          this.connectedTriggers = []
        }
      }
    },
    selectedItem: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.showProcessOrStepName = true
          }, 100)
        } else {
          this.showProcessOrStepName = false
        }
      }
    },
    logsOptions: {
      handler() {
        this.selectedEventId && this.fetchLogs(this.selectedEventId)
        this.savePreFill()
      },
      deep: true
    },
    logsOptionsPagination: {
      handler() {
        this.selectedEventId && this.fetchLogs(this.selectedEventId)
        this.savePreFill()
      },
      deep: true
    },
    logSearchStepId: {
      handler(val) {
        if (!val) this.logsOptions.stepId = null
      }
    }
  },
  provide() {
    return {
      openValidation: this.openValidation
    }
  },
  mounted() {
    this.setBorderWidth()
    this.setEvents()
    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.processDebugLogs) {
        this.logsOptions.stepId = preFill.processDebugLogs.stepId
        this.logsOptions.stepType = preFill.processDebugLogs.stepType
        this.logsOptions.status = preFill.processDebugLogs.status
        this.logsOptionsPagination = preFill.processDebugLogs.logsOptionsPagination
      }
    }
    window.addEventListener('keydown', this.handleKeyDown)
    bus.$on('saveResource', this.submit)
  },
  async created() {
    this.isEdit = this.$route.name === 'processEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'PROCESS_DELETER') || this.isSuperUser
    }

    if (this.userSettings.display.showStepType) {
      this.headers.splice(1, 0, {
        text: this.$lang.labels.stepType,
        value: 'stepType'
      })
    }

    if (this.userSettings.display.showFinishedOn) {
      this.headers.splice(1, 0, {
        text: this.$lang.labels.finishedOn,
        value: 'finishedOn'
      })
    }

    this.init().then(async () => {
      if (this.isEdit && this.$route.params.id) {
        this.loading = true
        getProcess({ id: this.$route.params.id })
          .then(async (res) => {
            if (res.status !== 200) {
              this.addSnackbar({
                message: res?.response?.data?.statusText || 'Error',
                timeout: 5000,
                color: 'error'
              })

              this.loading = false

              return
            }

            this.process = res.data.data && res.data.data.steps ? res.data.data : { ...res.data.data, steps: { steps: [] } }

            this.originalProcessNameOnEdit = this.process.name

            this.editRolesIds = this.process.roles
              .filter((x) => x.permissionType === 'EDIT')
              .map((y) => y.role.id)
            this.useRolesIds = this.process.roles
              .filter((x) => x.permissionType === 'USE')
              .map((y) => y.role.id)
            this.viewRolesIds = this.process.roles
              .filter((x) => x.permissionType === 'VIEW')
              .map((y) => y.role.id)

            this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.process.isSystem)

            if (this.process.comment === null) {
              this.process.comment = ''
            }

            if (this.process.maxProcessingDurationInMSec !== null) {
              const unitResPDur = await predictBestTimeUnitFromMsValue(
                this.process.maxProcessingDurationInMSec
              )

              this.timeUnitPDur = unitResPDur.unit
              this.tempMaxProcessingDurationInMSec = unitResPDur.value
            }
            if (this.process.logsTtlInMSec !== null) {
              const unitResTtl = await predictBestTimeUnitFromMsValue(
                this.process.logsTtlInMSec
              )

              this.timeUnitTtl = unitResTtl.unit
              this.tempLogsTtlInMSec = unitResTtl.value
            }

            if (this.process.errorsTtlInMSec !== null) {
              const unitResErrTtl = await predictBestTimeUnitFromMsValue(
                this.process.errorsTtlInMSec
              )

              this.timeUnitErrTtl = unitResErrTtl.unit
              this.tempErrorsTtlInMSec = unitResErrTtl.value
            }

            // eslint-disable-next-line no-prototype-builtins
            if (
              this.process &&
              !Object.prototype.hasOwnProperty.call(this.process, 'isCacheStepLogsEnabled')
            ) {
              this.isCacheStepLogsEnabled = true
            }
            // eslint-disable-next-line no-prototype-builtins
            if (
              this.process &&
              !Object.prototype.hasOwnProperty.call(this.process, 'isStepLogsEnabled')
            ) {
              this.isStepLogsEnabled = true
            }

            // eslint-disable-next-line no-prototype-builtins
            if (
              this.process &&
              !Object.prototype.hasOwnProperty.call(this.process, 'isDebugLogEnabled')
            ) {
              this.isDebugLogEnabled = true
            }

            await this.previewData({ id: null }, '', null, '')

            this.originalProcess = JSON.parse(JSON.stringify(this.process))
            this.loading = false
            this.loadingStatistic = false

            if (this.$route.query && this.$route.query.eventId) {
              if (!isNaN(Number(this.$route.query.eventId))) {
                this.selectedEventId = this.$route.query.eventId

                this.fetchLogs(this.selectedEventId)
              }
            }

            if (window.location.hash) {
              const localSelectedItem = window.location.hash.replace('#', '')

              setTimeout(() => {
                this.selectStepFromLog(localSelectedItem)
              }, 500)
            }

            this.fetchStatistics()
          })
          .catch((err) => {
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })})
      } else {
        this.editRolesIds = user.roles
          .filter((x) => !x.isSystem)
          .map((x) => x.id)
        this.userCanEdit = true
        if (this.$route.params.copy) {
          getProcess({ id: this.$route.params.copy.id })
            .then(async (res) => {
              if (res.status !== 200) {
                this.addSnackbar({
                  message: res?.response?.data?.statusText || 'Error',
                  timeout: 5000,
                  color: 'error'
                })

                this.loading = false

                return
              }
              this.process = res.data.data

              this.process.name = `${
                res.data.data.name
              } - Copy - ${this.$options.filters.formatDateTime(new Date())}`

              if (this.process.comment === null) {
                this.process.comment = ''
              }

              if (this.process.maxProcessingDurationInMSec !== null) {
                const unitResPDur = await predictBestTimeUnitFromMsValue(
                  this.process.maxProcessingDurationInMSec
                )

                this.timeUnitPDur = unitResPDur.unit
                this.tempMaxProcessingDurationInMSec = unitResPDur.value
              }
              if (this.process.logsTtlInMSec !== null) {
                const unitResTtl = await predictBestTimeUnitFromMsValue(
                  this.process.logsTtlInMSec
                )

                this.timeUnitTtl = unitResTtl.unit
                this.tempLogsTtlInMSec = unitResTtl.value
              }

              if (this.process.errorsTtlInMSec !== null) {
                const unitResErrTtl = await predictBestTimeUnitFromMsValue(
                  this.process.errorsTtlInMSec
                )

                this.timeUnitErrTtl = unitResErrTtl.unit
                this.tempErrorsTtlInMSec = unitResErrTtl.value
              }

              // eslint-disable-next-line no-prototype-builtins
              if (
                this.process &&
                !Object.prototype.hasOwnProperty.call(this.process, 'isCacheStepLogsEnabled')
              ) {
                this.isCacheStepLogsEnabled = true
              }
              // eslint-disable-next-line no-prototype-builtins
              if (
                this.process &&
                !Object.prototype.hasOwnProperty.call(this.process, 'isStepLogsEnabled')
              ) {
                this.isStepLogsEnabled = true
              }

              // eslint-disable-next-line no-prototype-builtins
              if (
                this.process &&
                !Object.prototype.hasOwnProperty.call(this.process, 'isDebugLogEnabled')
              ) {
                this.isDebugLogEnabled = true
              }

              this.previewData({ id: null }, '', null, '')

              this.originalProcess = JSON.parse(JSON.stringify(this.process))
              this.loading = false
              this.loadingStatistic = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
              this.loading = false
              this.loadingStatistic = false
            })
        } else if (this.$route.params.copyFromMemory) {
          if (navigator.clipboard) {
            navigator.permissions
              .query({ name: 'clipboard-read' })
              .then((result) => {
                if (result.state === 'granted' || result.state === 'prompt') {
                  navigator.clipboard
                    .readText()
                    .then(async (text) => {
                      try {
                        const processLocal = JSON.parse(text)

                        const localMessage = this.validateDataStructure(processLocal)

                        if (localMessage[0].success) {
                          this.success = localMessage[0].message
                          this.addSnackbar({
                            message: localMessage[0].message,
                            timeout: 5000,
                            color: 'success'
                          })
                          await this.importFunction(processLocal)
                        } else {
                          this.addSnackbar({
                            message: localMessage[0].message,
                            timeout: 5000,
                            color: 'error'
                          })
                        }

                        this.originalProcess = JSON.parse(JSON.stringify(this.process))
                        this.loading = false
                        this.loadingStatistic = false
                      } catch (e) {
                        this.addSnackbar({
                          message: `Invalid JSON: ${e}`,
                          timeout: 5000,
                          color: 'error'
                        })
                        this.loading = false
                        this.loadingStatistic = false

                      }
                    })
                    .catch((err) => {
                      this.addSnackbar({
                        message: `Failed to read clipboard contents: ${err}`,
                        timeout: 5000,
                        color: 'error'
                      })
                      this.loading = false
                      this.loadingStatistic = false

                    })
                }
              })
          } else {

            this.addSnackbar({
              message: 'Clipboard not available in browser, please check if you have granted access to clipboard in your browser settings.',
              timeout: 5000,
              color: 'error'
            })

          }
        } else if (this.$route.params.copyFromModal) {
          this.showImport = true
          this.originalProcess = JSON.parse(JSON.stringify(this.process))
          this.loading = false
          this.loadingStatistic = false
        } else if (this.$route.params.restore) {
          this.process = this.$route.params.restore
          this.process.id = 0
          if (this.process.comment === null) {
            this.process.comment = ''
          }

          if (this.process.maxProcessingDurationInMSec !== null) {
            const unitResPDur = await predictBestTimeUnitFromMsValue(
              this.process.maxProcessingDurationInMSec
            )

            this.timeUnitPDur = unitResPDur.unit
            this.tempMaxProcessingDurationInMSec = unitResPDur.value
          }
          if (this.process.logsTtlInMSec !== null) {
            const unitResTtl = await predictBestTimeUnitFromMsValue(
              this.process.logsTtlInMSec
            )

            this.timeUnitTtl = unitResTtl.unit
            this.tempLogsTtlInMSec = unitResTtl.value
          }

          if (this.process.errorsTtlInMSec !== null) {
            const unitResErrTtl = await predictBestTimeUnitFromMsValue(
              this.process.errorsTtlInMSec
            )

            this.timeUnitErrTtl = unitResErrTtl.unit
            this.tempErrorsTtlInMSec = unitResErrTtl.value
          }

          // eslint-disable-next-line no-prototype-builtins
          if (
            this.process &&
            !Object.prototype.hasOwnProperty.call(this.process, 'isCacheStepLogsEnabled')
          ) {
            this.isCacheStepLogsEnabled = true
          }
          // eslint-disable-next-line no-prototype-builtins
          if (
            this.process &&
            !Object.prototype.hasOwnProperty.call(this.process, 'isStepLogsEnabled')
          ) {
            this.isStepLogsEnabled = true
          }

          // eslint-disable-next-line no-prototype-builtins
          if (
            this.process &&
            !Object.prototype.hasOwnProperty.call(this.process, 'isDebugLogEnabled')
          ) {
            this.isDebugLogEnabled = true
          }

          this.previewData({ id: null }, '', null, '')

          this.originalProcess = JSON.parse(JSON.stringify(this.process))
          this.loading = false
          this.loadingStatistic = false
        } else {
          this.originalProcess = JSON.parse(JSON.stringify(this.process))
          this.loading = false
          this.loadingStatistic = false
          this.selectProcessName()
        }
      }
    })
  },
  beforeDestroy() {
    clearTimeout(this.autoRefreshTimeout)
    this.autoRefreshTimeout = null
    window.removeEventListener('keydown', this.handleKeyDown)
    bus.$off('saveResource', this.submit)
  },
  methods: {
    castAllStepsValues,
    filterNumber,
    validateMaxProcessingDuration(value, unit) {
      const minMillisec = 1 // 1 millisecond
      const maxMillisec = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
      let convertedValue

      switch (unit) {
      case 'MSec':
        convertedValue = parseFloat(value)
        break
      case 'Sec':
        convertedValue = parseFloat(value) * 1000
        break
      case 'Min':
        convertedValue = parseFloat(value) * 60 * 1000
        break
      case 'Hr':
        convertedValue = parseFloat(value) * 60 * 60 * 1000
        break
      case 'Day':
        convertedValue = parseFloat(value) * 24 * 60 * 60 * 1000
        break
      default:
        return false // Return false if the unit is invalid
      }

      return convertedValue >= minMillisec && convertedValue <= maxMillisec
    },
    validateTtlLimit(value, unit) {
      const maxYears = 10
      const maxMillisec = maxYears * 365 * 24 * 60 * 60 * 1000 // 10 years in milliseconds
      let convertedValue

      switch (unit) {
      case 'MSec':
        convertedValue = parseFloat(value)
        break
      case 'Sec':
        convertedValue = parseFloat(value) * 1000
        break
      case 'Min':
        convertedValue = parseFloat(value) * 60 * 1000
        break
      case 'Hr':
        convertedValue = parseFloat(value) * 60 * 60 * 1000
        break
      case 'Day':
        convertedValue = parseFloat(value) * 24 * 60 * 60 * 1000
        break
      default:
        return false
      }

      return convertedValue <= maxMillisec
    },
    ...mapActions('app', ['addSnackbar', 'formValid']),
    canUserEditResource,
    removeAdditionalPropertiesFromSteps,
    stopExecutionOfEvents() {
      interruptEvents({ processId: this.process.id })
        .then((res) => {
          this.confirmStopExecutionShow = false
          if (res.status === 200) {
            this.addSnackbar({
              message: this.$lang.success.interruptEventsSuccess,
              timeout: 5000,
              color: 'success'
            })
          } else {
            this.addSnackbar({
              message: res?.response?.data?.statusText || this.$lang.error.interruptEventsError,
              timeout: 5000,
              color: 'error'
            })
          }
        })
        .catch((err) => {
          this.confirmStopExecutionShow = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    openTriggerItem(item) {
      const link = `/${localStorage.selectedLanguage || 'en'}/${item.resourceUrl}/${item.id}`

      window.open(link, '_blank')
    },
    routeLinkOfTriggerType(type) {
      return type === 'TRIGGER_CRON'
        ? 'triggers/cron/edit'
        : type === 'TRIGGER_REST'
          ? 'triggers/rest/edit'
          : type === 'TRIGGER_EVENT_HANDLER'
            ? 'triggers/event-handler/edit'
            : type === 'TRIGGER_MESSAGING'
              ? 'triggers/messaging/edit'
              : type === 'EXECUTE_PROCESS'
                ? 'processes/edit'
                : ''
    },
    async fetchActiveTriggersForProcess() {
      try {
        const triggersRes = await getTriggersByProcessId({ id: this.process.id, request: { page: 1, size: 10000 } })

        this.connectedTriggers = triggersRes.data.data.items.map((x) => {
          return {
            ...x,
            resourceUrl: this.routeLinkOfTriggerType(x.type)
          }
        })
        if (this.connectedTriggers.length === 0) {
          this.showChangedName = false
        }
      } catch (error) {
        this.addSnackbar({
          message: error,
          timeout: 5000,
          color: 'error'
        })
      }
    },
    async checkActiveTriggers(event) {
      this.processToDisable = event

      if (event.status === 'INACTIVE') {
        this.changeStatus()

        return
      }

      getTriggersByProcessId({ id: event.id, request: { page: 1, size: 10000 } })
        .then((res) => {
          if (res.data.data.items.length > 0) {
            this.yesNoDisableShow = true
            this.triggerListToDeactivate = res.data.data.items

            this.textForRunModal = res.data.data.items.map((x) => x.name).join(',\n')
          } else {
            this.changeStatus()
          }
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    deactivateSingleTrigger(trigger) {
      new Promise((resolve, reject) => {
        switch (trigger.type) {
        case 'TRIGGER_CRON':
          disableCron({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_REST':
          disableRest({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_EVENT_HANDLER':
          disableEventHandler({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_MESSAGING':
          disableMessaging({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        }
      })
    },
    deactivateTriggers() {
      const loopRes = this.triggerListToDeactivate.map((x) => {
        return this.deactivateSingleTrigger(x)
      })

      Promise.all(loopRes).then(() => {
        this.changeStatus()
      })
    },
    setValueOnAllSteps(type, value) {
      const formatSteps = (items) => {
        for (const item of items) {

          item[type] = value

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      formatSteps(this.process.steps.steps)

    },
    headerTopRemove(index) {
      if (this.topPanelIndex.includes(index)) {
        this.topPanelIndex.splice(this.topPanelIndex.indexOf(index), 1)
      }
      if (index === 1) this.previewData({ id: null }, '', null, '')
    },
    headerTopControl(index) {
      if (!this.topPanelIndex.includes(index)) {
        this.topPanelIndex.push(index)
      }
    },
    openValidation(id) {
      window.open(
        `/${localStorage.selectedLanguage || 'en'}/validations/edit/${id}`,
        '_blank'
      )
    },
    async importFunction(dataJson) {
      this.process = dataJson

      if (this.process.comment === null) {
        this.process.comment = ''
      }

      if (this.process.maxProcessingDurationInMSec !== null) {
        const unitResPDur = await predictBestTimeUnitFromMsValue(
          this.process.maxProcessingDurationInMSec
        )

        this.timeUnitPDur = unitResPDur.unit
        this.tempMaxProcessingDurationInMSec = unitResPDur.value
      }
      if (this.process.logsTtlInMSec !== null) {
        const unitResTtl = await predictBestTimeUnitFromMsValue(
          this.process.logsTtlInMSec
        )

        this.timeUnitTtl = unitResTtl.unit
        this.tempLogsTtlInMSec = unitResTtl.value
      }

      if (this.process.errorsTtlInMSec !== null) {
        const unitResErrTtl = await predictBestTimeUnitFromMsValue(
          this.process.errorsTtlInMSec
        )

        this.timeUnitErrTtl = unitResErrTtl.unit
        this.tempErrorsTtlInMSec = unitResErrTtl.value
      }

      // eslint-disable-next-line no-prototype-builtins
      if (
        this.process &&
        !Object.prototype.hasOwnProperty.call(this.process, 'isCacheStepLogsEnabled ')
      ) {
        this.isCacheStepLogsEnabled = true
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.process && !this.process.hasOwnProperty('isStepLogsEnabled')) {
        this.isStepLogsEnabled = true
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.process && !this.process.hasOwnProperty('isDebugLogEnabled')) {
        this.isDebugLogEnabled = true
      }

      this.previewData({ id: null }, '', null, '')
    },
    validateDataStructure(data) {
      const errors = []

      // Check if data has all required fields
      const requiredFields = [
        'id',
        'name',
        'steps',
        'isGdprRelevant',
        'isStepLogsEnabled',
        'isCacheStepLogsEnabled',
        'isDebugLogEnabled'
      ]

      for (const field of requiredFields) {
        if (!Object.prototype.hasOwnProperty.call(data, field)) {
          errors.push({
            success: false,
            message: `Missing required field: ${field}`
          })
        }
      }

      // Check if steps has all required fields
      const stepsRequiredFields = ['scriptEngine', 'steps']

      const { steps } = data

      for (const field of stepsRequiredFields) {
        if (!Object.prototype.hasOwnProperty.call(steps, field)) {
          errors.push({
            success: false,
            message: `Missing required field in 'steps': ${field}`
          })
        }
      }

      // Check if scriptEngine is 'JS'
      if (steps.scriptEngine !== 'JS') {
        errors.push({
          success: false,
          message: 'Property \'scriptEngine\' in \'steps\' must be \'JS\''
        })
      }

      if (errors.length > 0) {
        return errors
      } else {
        // All checks passed
        return [{
          success: true,
          message: 'Data structure is valid'
        }]
      }
    },
    searchValidationsFunction(val = '') {
      this.isLoadingValidations = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.isSystem = false

      getValidations(obj)
        .then((res) => {
          this.validations = res.data.data.items
          this.isLoadingValidations = false
        })
        .catch((err) => {
          this.isLoadingValidations = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })})
    },
    deleteProcessFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteProcess({ id: this.$route.params.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res?.response?.data?.statusText || 'Error',
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })})
    },
    async filterStepExceptions(tryCatchStep) {
      let allSelectedExceptions = []
      let addedExceptions = []

      const formatSteps = (items) => {
        for (const item of items) {
          if (item.type && stepExceptions[item.type])
            allSelectedExceptions = [
              ...allSelectedExceptions,
              ...stepExceptions[item.type]
            ]

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            // eslint-disable-next-line no-loop-func
            item.properties.catch.forEach((item2) => {
              if (item2.exceptions && item2.exceptions.length > 0)
                addedExceptions = [...addedExceptions, ...item2.exceptions]
              formatSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      if (tryCatchStep.properties?.try?.steps?.length > 0) {
        await formatSteps(tryCatchStep.properties.try.steps)
      }

      tryCatchStep.properties?.catch?.forEach((item) => {
        this.filteredExceptions[item.localId] = [
          ...new Set([
            ...allSelectedExceptions,
            ...addedExceptions,
            ...item.exceptions
          ])
        ]
        this.filteredExceptions[item.localId].push('Exception')
      })
    },
    async filterTryCatchSteps() {
      const formatSteps = (items) => {
        for (const item of items) {
          if (item.type === 'TRY_CATCH') {
            this.filterStepExceptions(item)
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            for (const item2 of item.properties.conditions) {
              formatSteps(item2.steps)
            }
          }
        }
      }

      await formatSteps(this.process.steps.steps)
    },
    goToStep(stepId) {
      this.findStepByMermaidId(stepId)
    },
    async findStepByMermaidId(mermaidId) {
      let found = null
      let maybeTryCatch = ''

      const formatSteps = (items) => {
        for (const item of items) {
          if (item.mermaidId === Number(mermaidId)) {
            found = JSON.parse(JSON.stringify(item))
          }

          if (item.type === 'TRY_CATCH') {
            if (item.properties?.try?.mermaidId === Number(mermaidId)) {
              found = JSON.parse(JSON.stringify(item))
              maybeTryCatch = 'try'
            }
            if (item.properties?.finally?.mermaidId === Number(mermaidId)) {
              found = JSON.parse(JSON.stringify(item))
              maybeTryCatch = 'finally'
            }
            if (
              item.properties &&
              item.properties.catch &&
              item.properties.catch.length > 0
            ) {
              // eslint-disable-next-line
              item.properties.catch.forEach((item2) => {
                if (item2.mermaidId === Number(mermaidId)) {
                  found = JSON.parse(JSON.stringify(item2))
                  maybeTryCatch = 'catch'
                }
              })
            }
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            for (const item2 of item.properties.conditions) {
              if (item2.mermaidId === Number(mermaidId)) {
                found = JSON.parse(JSON.stringify(item2))
              }
              formatSteps(item2.steps)
            }
          }
        }
      }

      await formatSteps(this.process.steps.steps)

      if (found) this.previewData(found, '', null, '', maybeTryCatch)
    },
    iconColorChanger() {
      if (
        this.selectedEventData &&
        ['FAILED', 'PROCESS_INACTIVE', 'TIMEOUT'].includes(
          this.selectedEventData.status
        )
      ) {
        return 'error'
      } else if (
        this.selectedEventData &&
        ['IN_PROGRESS', 'NEW'].includes(this.selectedEventData.status)
      ) {
        return 'info'
      } else if (
        this.selectedEventData &&
        ['ON_HOLD'].includes(this.selectedEventData.status)
      ) {
        return 'warning'
      } else if (
        this.selectedEventData &&
        ['SUCCESSFUL'].includes(this.selectedEventData.status)
      ) {
        return 'success'
      } else {
        return 'transparent'
      }
    },
    copyToClipboard(str) {
      if (str) {
        copy(str)
        this.addSnackbar({
          message: this.$lang.success.copiedClipboard,
          timeout: 5000,
          color: 'success'
        })
      } else {
        this.addSnackbar({
          message: this.$lang.errors.nothingToCopy,
          timeout: 5000,
          color: 'warning'
        })
      }
    },
    fetchEventsLogs() {
      const obj = {
        processId: this.process.id
      }

      this.lastEventsLogsLoader = true

      getEventsLogs(obj)
        .then((res) => {
          if (res?.data?.data?.eventId) {
            this.selectedEventId = res.data.data.eventId
            this.lastEventsLogsLoader = false
            this.fetchLogs(res.data.data.eventId)
          } else {
            this.lastEventsLogsLoader = false
          }
        })
        .catch((error) => {
          this.lastEventsLogsLoader = false
          console.log(error)
        })
    },
    async fetchStepDebugLog(id) {
      const res = await getDebugLog({ id })
      const { debugLog } = res.data.data

      this.debugLogData = debugLog
      this.showDebugLog = true
    },
    async fetchStepStackTrace(item) {
      const res = await getException({ id: item.id })
      const { message, stackTrace, name } = res.data.data

      this.selectedEst = {
        exceptionStackTrace: stackTrace,
        exceptionMessage: message,
        exceptionName: name
      }
      this.showSelectedEst = true
    },
    fetchStatistics() {
      this.statistics = null
      this.loadingStatistic = true
      getStatistics({ id: this.$route.params.id })
        .then((res) => {
          this.statistics = res.data.data
          this.statArr = [
            {
              text: this.$lang.labels.last15MinutesCount,
              value: this.statistics.last15MinutesCount
            },
            {
              text: this.$lang.labels.last60MinutesCount,
              value: this.statistics.last60MinutesCount
            },
            {
              text: this.$lang.labels.last24HoursCount,
              value: this.statistics.last24HoursCount
            },
            {
              text: this.$lang.labels.todayCount,
              value: this.statistics.todayCount
            }
          ]
          this.loadingStatistic = false
          setTimeout(() => (this.loading = false), 200)
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
          this.loadingStatistic = false
          setTimeout(() => (this.loading = false), 200)
        })
    },
    showError(text) {
      this.addSnackbar({
        message: text,
        timeout: 5000,
        color: 'error'
      })
    },
    download(content, fileName) {
      const file = new Blob([content], {
        type: 'application/json;charset=UTF-8'
      })

      const reader = new FileReader()

      reader.onload = function () {
        const popup = window.open()

        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }
      reader.readAsDataURL(file)
    },
    downloadCache() {
      if (this.cacheDownload) {
        this.cacheLoading = false
        this.cacheInnerLoading = false
        this.download(this.cacheDownload, 'Cache.json')
      }
    },
    openLogCache(id) {
      this.cacheLoading = true
      this.cacheInnerLoading = true
      this.cacheDownload = null
      getProcessStepLogCache({ id })
        .then((res) => {
          if (res && res.data && res.data.data && res.data.data.cache) {
            const size = new TextEncoder().encode(
              JSON.stringify(res.data.data.cache)
            ).length

            const kiloBytes = size / 1024

            this.cacheSize = Math.floor(kiloBytes)

            if (kiloBytes < MAX_KB_CACHE_TO_DISPLAY) {
              this.selectedCacheData = res.data.data.cache
            } else {
              this.cacheDownload = JSON.stringify(res.data.data.cache)
            }

            this.cacheLoading = false
            this.cacheInnerLoading = false
            this.showCacheDetails = true
          } else {
            this.cacheLoading = false
            this.cacheInnerLoading = false
            this.cacheDownload = null
            this.addSnackbar({
              message: this.$lang.errors.noCache,
              timeout: 5000,
              color: 'warning'
            })

          }
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    openProcessEvents() {
      const dateFrom = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
      const dateTill = format(new Date(), 'yyyy-MM-dd')

      const url = this.$router.resolve({
        name: 'logs-events',
        query: { processId: String(this.process.id), dateFrom, dateTill }
      })

      window.open(url.href, '_blank')
    },
    openStepLogs() {
      const dateFrom = format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
      const dateTill = format(new Date(), 'yyyy-MM-dd')

      const url = this.$router.resolve({
        name: 'logs-steps',
        query: { processId: String(this.process.id), dateFrom, dateTill }
      })

      window.open(url.href, '_blank')
    },
    fetchEvent() {
      if (!this.selectedEventId) return

      this.showExceptionDetails = true
    },
    fetchEventAutoRefresh(eventId) {
      getEvent({ id: eventId })
        .then((res) => {
          this.selectedEventData = null
          this.selectedEventData = res.data?.data || null
          if (
            ['IN_PROGRESS', 'NEW', 'ON_HOLD'].includes(res.data?.data?.status)
          ) {
            this.loadingAutoRefresh = true
            this.autoRefreshTimeout = setTimeout(
              () => this.fetchLogs(eventId),
              5000
            )
          } else {
            this.loadingAutoRefresh = false
            clearTimeout(this.autoRefreshTimeout)
          }
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    fetchLogs(eventId) {
      this.fetchEventAutoRefresh(eventId)

      const obj = {
        eventId: eventId,
        processId: this.$route.params.id,
        page: this.logsOptionsPagination?.page || 1,
        size: this.logsOptionsPagination?.itemsPerPage || 100
      };

      ['status', 'stepType', 'stepId'].forEach((param) => {
        if (this.logsOptions[param]) {
          obj[param] = this.logsOptions[param]
        }
      })

      getLogs(obj)
        .then((res) => {
          this.logs =
            res.data?.data?.items.map((x) => {
              x.isSelected = false
              x.duration = calculateDuration(x.createdOn, x.finishedOn)

              return x
            }) || []
          this.totalLogItems = res.data?.data?.meta?.totalItems || 0
          this.logs.forEach((x) => {
            this.visualIdSearch(x.stepId, x.status)
          })
          if (this.logs.length === 0) {
            if (window.location.hash) {
              window.history.replaceState(
                null,
                '',
                `?eventId=${window.location.hash}`
              )
            } else {
              window.history.replaceState(null, '', '?eventId=')
            }
          } else {
            if (window.location.hash) {
              window.history.replaceState(
                null,
                '',
                `?eventId=${eventId}${window.location.hash}`
              )
            } else {
              window.history.replaceState(null, '', `?eventId=${eventId}`)
            }
            if (this.$route.query.eventId !== eventId) {
              this.$router.replace({ query: { eventId } })
            }
            this.showDetailsWrapper = false
            this.showEventsAndStepWrapper = true
            this.mini = true
          }
          this.stepLogsSearch = true
          this.headerTopControl(0)
        })
        .catch((error) => {
          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
          console.log(error)
        })
    },
    selectedRow(item) {
      return `clickable-simple ${
        item.isSelected
          ? this.$vuetify.theme.dark
            ? 'is-log-selected-dark'
            : 'is-log-selected-light'
          : ''
      } animate__animated animate__bounceIn`
    },
    selectTableRow(item, data) {
      this.selectedLogIndex = data.index
      this.headerTopControl(1)
    },
    selectProcessName(tabNumber = 0) {
      this.showEventsAndStepWrapper = false
      this.showDetailsWrapper = true
      this.tab = tabNumber
      if (!this.topPanelIndex.includes(tabNumber)) {
        this.topPanelIndex.push(tabNumber)
      }
      this.mini = true
      this.selectedItem = null
      this.previewData({ id: null }, '', null, '')

      if (tabNumber === 0) {
        setTimeout(() => this.showProcessOrStepName = true, 100)
        setTimeout(() => this.$refs.processName.focus(), 200)
      } else {
        this.showProcessOrStepName = false
      }
    },
    fetchHistory(options) {
      if (!this.process.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.process.id
      obj.resourceType = 'PROCESS'

      getHistories(obj)
        .then((res) => {
          if (res.status !== 200) {
            this.addSnackbar({
              message: res.response.data.status + ' - ' + res.response.data.statusCode,
              timeout: 5000,
              color: 'error'
            })

            return
          }

          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async pasteStep(inputItem) {
      this.idIncreaseItem = null
      const pasteableItem = localStorage.itemStorage
        ? JSON.parse(localStorage.itemStorage)
        : null

      await this.idIncrease(pasteableItem)

      this.loading = true

      setTimeout(async () => {
        if (this.idIncreaseItem) {
          const pasteStep = (items, itemWhereToPaste) => {
            items.forEach((item, index) => {
              if (item.localId === itemWhereToPaste.localId) {
                const tempID = item.id ? item.id : null

                if (tempID) {
                  items[index] = { ...this.idIncreaseItem, id: tempID }
                } else {
                  items[index] = { ...this.idIncreaseItem }
                }

                this.previewData({ id: null }, '', null, '')

                this.loading = false

                return
              }

              if (
                item.properties &&
                item.properties.steps &&
                item.properties.steps.length > 0
              ) {
                pasteStep(item.properties.steps, itemWhereToPaste)
              } else if (
                item.properties &&
                item.properties.conditions &&
                item.properties.conditions.length > 0
              ) {
                item.properties.conditions.forEach((item2, index2) => {
                  if (inputItem.subType && inputItem.subType === 'QUERY') {
                    if (item2.localId === itemWhereToPaste.localId) {
                      if (!this.idIncreaseItem.subType) {
                        this.addSnackbar({
                          message: this.$lang.errors.wrongPaste,
                          timeout: 5000,
                          color: 'error'
                        })

                        return
                      }

                      item.properties.conditions[index2] = {
                        ...this.idIncreaseItem,
                        query: `${this.idIncreaseItem.query} - COPIED Q${this.idIncreaseItem.localId}`
                      }

                      this.previewData({ id: null }, '', null, '')

                      return
                    }
                  }
                  pasteStep(item2.steps, itemWhereToPaste)
                })
              }
            })
          }

          await pasteStep(this.process.steps.steps, inputItem)

          this.loading = false
        } else {
          this.addSnackbar({
            message: this.$lang.errors.noPaste,
            timeout: 5000,
            color: 'error'
          })
        }
      }, 400)
    },
    async idIncrease(item) {
      this.idIncreaseItem = JSON.parse(JSON.stringify(item))
      let localHighestId = this.highestId

      if (this.idIncreaseItem.id) {
        this.idIncreaseItem.id = localHighestId + 1
        localHighestId++
      }

      const idIncrease = (items) => {
        items.forEach((item2) => {
          item2.id = localHighestId + 1
          localHighestId++

          if (
            item2.properties &&
            item2.properties.steps &&
            item2.properties.steps.length > 0
          ) {
            idIncrease(item2.properties.steps)
          }
          if (
            item2.properties &&
            item2.properties.conditions &&
            item2.properties.conditions.length > 0
          ) {
            item2.properties.conditions.forEach((item3) => {
              idIncrease(item3.steps)
            })
          }
          if (
            item2.properties?.try?.steps &&
            item2.properties.try.steps.length > 0
          ) {
            idIncrease(item2.properties.item.properties.try.steps)
          }
          if (
            item2.properties &&
            item2.properties.catch &&
            item2.properties.catch.length > 0
          ) {
            item2.properties.catch.forEach((item2) => {
              idIncrease(item2.steps)
            })
          }
        })
      }

      if (
        this.idIncreaseItem.properties &&
        this.idIncreaseItem.properties.steps &&
        this.idIncreaseItem.properties.steps.length > 0
      ) {
        idIncrease(this.idIncreaseItem.properties.steps)
      } else if (
        this.idIncreaseItem.properties &&
        this.idIncreaseItem.properties.conditions &&
        this.idIncreaseItem.properties.conditions.length > 0
      ) {
        this.idIncreaseItem.properties.conditions.forEach((itemA) => {
          idIncrease(itemA.steps)
        })
      } else if (
        this.idIncreaseItem.steps &&
        this.idIncreaseItem.steps.length > 0
      ) {
        idIncrease(this.idIncreaseItem.steps)
      }
      if (this.idIncreaseItem.type === 'TRY_CATCH') {
        if (this.idIncreaseItem.properties?.try?.steps)
          idIncrease(this.idIncreaseItem.properties.try.steps)
        if (this.idIncreaseItem.properties?.catch)
          this.idIncreaseItem.properties.catch.forEach((item2) => {
            idIncrease(item2.steps)
          })
      }
    },
    async duplicateStep(inputItem) {
      this.duplicateLock = false

      const duplicateStep = (items, duplicateItem) => {
        items.forEach((item, index) => {
          if (item.localId === duplicateItem.localId && !this.duplicateLock) {
            this.duplicateLock = true

            items.splice(index + 1, 0, {
              ...JSON.parse(JSON.stringify(duplicateItem))
            })

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            duplicateStep(item.properties.steps, duplicateItem)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            duplicateStep(item.properties.try.steps, duplicateItem)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            duplicateStep(item.properties.finally.steps, duplicateItem)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2, index2) => {
              if (inputItem.subType && inputItem.subType === 'EXCEPTION') {
                if (
                  item2.localId === duplicateItem.localId &&
                  !this.duplicateLock
                ) {
                  this.duplicateLock = true

                  item.properties.catch.splice(index2 + 1, 0, {
                    ...JSON.parse(JSON.stringify(duplicateItem)),
                    exceptions: []
                  })

                  this.previewData({ id: null }, '', null, '')

                  return
                }
              }
              duplicateStep(item2.steps, duplicateItem)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            item.properties.conditions.forEach((item2, index2) => {
              if (inputItem.subType && inputItem.subType === 'QUERY') {
                if (
                  item2.localId === duplicateItem.localId &&
                  !this.duplicateLock
                ) {
                  this.duplicateLock = true

                  item.properties.conditions.splice(index2 + 1, 0, {
                    ...JSON.parse(JSON.stringify(duplicateItem)),
                    query: `${duplicateItem.query} - COPIED Q${item2.localId}`
                  })

                  this.previewData({ id: null }, '', null, '')

                  return
                }
              }
              duplicateStep(item2.steps, duplicateItem)
            })
          }
        })
      }

      this.idIncreaseItem = null
      await this.idIncrease(inputItem)
      setTimeout(() => {
        duplicateStep(this.process.steps.steps, this.idIncreaseItem)
      }, 200)
    },
    dragSteps(newSteps) {
      this.dragLock = true

      this.process.steps.steps = newSteps

      this.previewData({ id: null }, '', null, '')
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    doesHaveChildren(item) {
      if (
        item.properties &&
        item.properties.conditions &&
        item.properties.conditions.length > 0
      )
        return true
      if (
        item.properties &&
        item.properties.steps &&
        item.properties.steps.length > 0
      )
        return true
      if (
        item.properties &&
        item.properties.try &&
        item.properties.try.steps &&
        item.properties.try.steps.length > 0
      )
        return true

      return !!(
        item.properties &&
        item.properties.finally &&
        item.properties.finally.steps &&
        item.properties.finally.steps.length > 0
      )
    },
    async deleteStep() {
      const deleteStep = (items, deleteItem) => {
        items.forEach((item, index) => {
          if (String(item.localId) === String(deleteItem.localId)) {
            items.splice(index, 1)

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            deleteStep(item.properties.steps, deleteItem)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            deleteStep(item.properties.try.steps, deleteItem)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            deleteStep(item.properties.finally.steps, deleteItem)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            deleteStep(item.properties.catch, deleteItem)
            item.properties.catch.forEach((item2) => {
              deleteStep(item2.steps, deleteItem)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            deleteStep(item.properties.conditions, deleteItem)
            item.properties.conditions.forEach((item2) => {
              deleteStep(item2.steps, deleteItem)
            })
          }
        })
      }

      await deleteStep(this.process.steps.steps, this.selectedDeleteItem)

      this.yesNoShow = false
    },
    compare(a, b) {
      if (a.finishedOn < b.finishedOn) {
        return -1
      }
      if (a.finishedOn > b.finishedOn) {
        return 1
      }

      return 0
    },
    // saveSelected(item) {
    //   this.isNewSelected = false
    //   this.selectedItem = item
    // },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.processDebugLogs) preFill.processDebugLogs = {}

        preFill.processDebugLogs.stepId = this.logsOptions.stepId
        preFill.processDebugLogs.stepType = this.logsOptions.stepType
        preFill.processDebugLogs.status = this.logsOptions.status
        preFill.processDebugLogs.logsOptionsPagination = { ...this.logsOptionsPagination, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    createStep(type) {
      this.previewLock = false
      this.previewData(
        { id: this.highestId + 1 },
        'add',
        this.beforeStep ? this.beforeStep.id : 0,
        type
      )
      this.showTypeSelector = false
    },
    addStep(data) {
      this.beforeStep = data.beforeItem
      this.addIsSpecial = data.isSpecial
      this.addIsInner = data.innerStep
      this.addIsAfter = data.isAfter
      this.tryCatch = data.tryCatch
      if (data.isSpecial) {
        this.previewLock = false
        this.previewData(
          { id: this.highestId + 1 },
          'add',
          this.beforeStep.id,
          ''
        )
      } else if (data.isPasteAfterOrBefore) {
        this.previewLock = false
        this.previewData(
          { id: this.highestId + 1 },
          'add',
          this.beforeStep?.id,
          'PASTE'
        )
      } else {
        this.showTypeSelector = true
      }
    },
    moveStep(data) {
      this.moveItemsAround(data.item, data.direction)
    },
    async restoreProcess(data) {
      this.loading = true
      this.process = data

      if (!this.process.comment) this.process.comment = ''

      if (this.process && this.process.roles) {
        this.editRolesIds = this.process.roles
          .filter((x) => x.permissionType === 'EDIT')
          .map((y) => y.role.id)
        this.useRolesIds = this.process.roles
          .filter((x) => x.permissionType === 'USE')
          .map((y) => y.role.id)
        this.viewRolesIds = this.process.roles
          .filter((x) => x.permissionType === 'VIEW')
          .map((y) => y.role.id)

        this.userCanEdit = this.canUserEditResource(this.isSuperUser, !!this.editRolesIds.find((x) => this.userRolesIds.includes(x)), this.process.isSystem)
      }

      if (this.process && this.process.maxProcessingDurationInMSec !== null) {
        const unitResPDur = await predictBestTimeUnitFromMsValue(
          this.process.maxProcessingDurationInMSec
        )

        this.timeUnitPDur = unitResPDur.unit
        this.tempMaxProcessingDurationInMSec = unitResPDur.value
      }
      if (this.process && this.process.logsTtlInMSec !== null) {
        const unitResTtl = await predictBestTimeUnitFromMsValue(
          this.process.logsTtlInMSec
        )

        this.timeUnitTtl = unitResTtl.unit
        this.tempLogsTtlInMSec = unitResTtl.value
      }

      if (this.process && this.process.errorsTtlInMSec !== null) {
        const unitResErrTtl = await predictBestTimeUnitFromMsValue(
          this.process.errorsTtlInMSec
        )

        this.timeUnitErrTtl = unitResErrTtl.unit
        this.tempErrorsTtlInMSec = unitResErrTtl.value
      }

      this.previewData({ id: null }, '', null, '')
      this.loading = false
    },
    // PREVIEW METHOD
    async previewData(
      defaultItem = null,
      operation = '',
      id = null,
      type = '',
      isInnerTryCatch = ''
    ) {
      this.isNewSelected = true
      this.selectedItem = null
      let itemToPaste = null

      this.mermaidId = 0

      if (operation && operation === 'add') this.highestId = this.highestId + 1

      if (type === 'PASTE') {
        const itemToPasteTemp = localStorage.itemStorage
          ? JSON.parse(localStorage.itemStorage)
          : null

        if (!itemToPasteTemp) {
          this.addSnackbar({
            message: this.$lang.errors.noPaste,
            timeout: 5000,
            color: 'error'
          })

          return
        }

        if (itemToPasteTemp.subType) {
          this.addSnackbar({
            message: this.$lang.errors.wrongPaste,
            timeout: 5000,
            color: 'error'
          })

          return
        }
        this.idIncreaseItem = null
        await this.idIncrease(itemToPasteTemp)
        itemToPaste = this.idIncreaseItem
      }

      const formatSteps = (
        items,
        status = false,
        parentIndex,
        firstLevel = false,
        insideIsInnerTryCatch = ''
      ) => {
        if (items && items.length === 0) {
          if (operation && operation === 'add' && !this.addIsSpecial) {
            if (type === 'EMAIL') {
              this.newStep.properties.variables = {
                subject: ''
              }
            } else if (type === 'USER') {
              this.newStep.properties.fields = {
                email: '',
                name: '',
                roles: '',
                targetObject: '',
                apiKey: '',
                password: ''
              }
            } else if (type === 'S3') {
              this.newStep.properties.fields = {
                targetObject: '',
                path: '',
                key: '',
                content: ''
              }
            } else if (type === 'REST') {
              this.newStep.properties.headers = {
                'Content-Type': ''
              }
            } else if (type === 'IMAP') {
              this.newStep.properties.fields = {
                body: '',
                maxResults: '',
                readType: '',
                recipient: '',
                sender: '',
                subject: '',
                emailId: ''
              }
            } else if (type === 'CSV') {
              this.newStep.properties.includeHeaders = true
            } else if (type === 'STORAGE') {
              this.newStep.properties = {
                action: 'GET',
                scriptEngine: 'JS',
                fields: {
                  targetObject: ''
                }
              }
            } else if (type === 'ENCODER') {
              this.newStep.properties = {
                action: 'ENCODE_HTML_SPECIAL_CHARS',
                converts: []
              }
            } else {
              this.newStep.properties = {}
            }

            if (id === 0 && !this.previewLock) {
              this.previewLock = true
              if (itemToPaste && !itemToPaste.subType) {
                this.process.steps.steps.push({
                  ...itemToPaste,
                  id: 1,
                  localId: 1
                })
              } else {
                this.process.steps.steps.push({
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: 1,
                  type,
                  localId: 1,
                  name: '1'
                })
              }
              this.selectedItem = {
                ...JSON.parse(JSON.stringify(this.newStep)),
                id: 1,
                type,
                localId: 1,
                name: '1'
              }
              this.beforeStep = null
            }
          }

          return
        }

        items.forEach((item, index) => {
          if (item.id && item.id > this.highestId) this.highestId = item.id

          item.localId = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
          item.mermaidId = this.mermaidId + 1
          this.mermaidId++
          if (item.name === '')
            item.name = firstLevel ? index + 1 : `${parentIndex}.${index + 1}`
          if (item.type === 'TRY_CATCH') {
            if (item.properties?.try?.name === '')
              item.properties.try.name = `T${parentIndex}`
            if (item.properties?.try) {
              item.properties.try.localId = `T${parentIndex}`
              item.properties.try.mermaidId = this.mermaidId + 5000
              this.mermaidId++
            }
            if (item.properties?.finally?.name === '')
              item.properties.finally.name = `F${parentIndex}`
            if (item.properties?.finally) {
              item.properties.finally.localId = `F${parentIndex}`
              item.properties.finally.mermaidId = this.mermaidId + 10000
              this.mermaidId++
            }
          }

          if (
            operation &&
            operation === 'add' &&
            !this.addIsSpecial &&
            !this.addIsInner
          ) {
            if (type === 'EMAIL') {
              this.newStep.properties.variables = {
                subject: ''
              }
            } else if (type === 'USER') {
              this.newStep.properties.fields = {
                email: '',
                name: '',
                roles: '',
                targetObject: '',
                apiKey: '',
                password: ''
              }
            } else if (type === 'S3') {
              this.newStep.properties.fields = {
                targetObject: '',
                path: '',
                key: '',
                content: ''
              }
            } else if (type === 'REST') {
              this.newStep.properties.headers = {
                'Content-Type': ''
              }
            } else if (type === 'IMAP') {
              this.newStep.properties.fields = {
                body: '',
                maxResults: '',
                readType: '',
                recipient: '',
                sender: '',
                subject: '',
                emailId: ''
              }
            } else if (type === 'CSV') {
              this.newStep.properties.includeHeaders = true
            } else if (type === 'STORAGE') {
              this.newStep.properties = {
                action: 'GET',
                scriptEngine: 'JS',
                fields: {
                  targetObject: ''
                }
              }
            } else if (type === 'ENCODER') {
              this.newStep.properties = {
                action: 'ENCODE_HTML_SPECIAL_CHARS',
                converts: []
              }
            } else if (type === 'TRY_CATCH') {
              delete this.newStep.steps
              this.newStep.properties = {
                try: {
                  name: '',
                  steps: [],
                  subType: 'TRY'
                },
                catch: [
                  {
                    name: '',
                    exceptions: [],
                    steps: [],
                    subType: 'EXCEPTION'
                  }
                ],
                finally: {
                  name: '',
                  steps: [],
                  subType: 'FINALLY'
                }
              }
            } else {
              this.newStep.properties = {}
            }

            if (id === 0 && !this.previewLock) {
              this.previewLock = true
              if (itemToPaste && !itemToPaste.subType) {
                this.process.steps.steps.push({
                  ...itemToPaste,
                  localId: firstLevel
                    ? index + 1
                    : `${parentIndex}.${index + 1}`
                })
                this.selectedItem = {
                  ...itemToPaste,
                  localId: firstLevel
                    ? index + 1
                    : `${parentIndex}.${index + 1}`
                }
              } else {
                this.process.steps.steps.push({
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: this.highestId,
                  type,
                  localId: firstLevel
                    ? index + 1
                    : `${parentIndex}.${index + 1}`
                })
                this.selectedItem = {
                  ...JSON.parse(JSON.stringify(this.newStep)),
                  id: this.highestId,
                  type,
                  localId: firstLevel
                    ? index + 1
                    : `${parentIndex}.${index + 1}`
                }
              }
              this.beforeStep = null
              item.localId = firstLevel
                ? index + 1
                : `${parentIndex}.${index + 1}`
              item.mermaidId = this.mermaidId + 1
              this.mermaidId++
            }
            if (id === item.id && !this.previewLock) {
              this.previewLock = true
              if (this.addIsAfter) {
                if (this.tryCatch && this.tryCatch === 'try') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `T${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (this.tryCatch && this.tryCatch === 'finally') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `F${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (this.tryCatch && this.tryCatch === 'catch') {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `T${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  }
                } else if (!this.tryCatch) {
                  if (itemToPaste && !itemToPaste.subType) {
                    items.splice(index + 1, 0, {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }
                  } else {
                    if (items.length - 1 === index) {
                      items.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `${parentIndex}.${index + 2}`
                      })
                    } else {
                      items.splice(index + 1, 0, {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: firstLevel
                          ? index + 2
                          : `${parentIndex}.${index + 2}`
                      })
                    }
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: firstLevel
                        ? index + 2
                        : `${parentIndex}.${index + 2}`
                    }

                    if (firstLevel) {
                      for (let k = 0; k < items.length; k++) {
                        items[k].localId = String(k + 1)
                      }
                    }
                  }
                }
              } else {
                if (itemToPaste && !itemToPaste.subType) {
                  items.splice(index, 0, {
                    ...itemToPaste,
                    localId: firstLevel
                      ? index + 1
                      : `${parentIndex}.${index + 1}`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: firstLevel
                      ? index + 1
                      : `${parentIndex}.${index + 1}`
                  }
                } else {
                  items.splice(index, 0, {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: firstLevel
                      ? index + 1
                      : `${parentIndex}.${index + 1}`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: firstLevel
                      ? index + 1
                      : `${parentIndex}.${index + 1}`
                  }
                }
              }
              this.beforeStep = null
              item.localId = firstLevel
                ? index + 1
                : `${parentIndex}.${index + 1}`
              item.mermaidId = this.mermaidId + 1
              this.mermaidId++
              if (!itemToPaste) {
                if (item.name === '')
                  item.name = firstLevel
                    ? index + 1
                    : `${parentIndex}.${index + 1}`
              }
            }
          }

          if (
            operation &&
            operation === 'add' &&
            this.addIsInner &&
            !this.previewLock
          ) {
            if (this.beforeStep && item.localId === this.beforeStep.localId) {
              this.previewLock = true
              if (!item.properties.steps && !this.tryCatch)
                item.properties.steps = []
              if (itemToPaste && !itemToPaste.subType) {
                if (item.type === 'TRY_CATCH') {
                  if (this.tryCatch === 'finally') {
                    if (!item.properties.finally)
                      item.properties.finally = {
                        name: '',
                        steps: [],
                        subType: 'FINALLY',
                        localId: `F${item.localId}.1`
                      }
                    item.properties.finally.steps.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${
                        item.properties.try.steps.length === 0 ? '1' : index + 2
                      }`
                    })
                  }
                  if (this.tryCatch === 'try') {
                    if (!item.properties.try)
                      item.properties.try = {
                        name: '',
                        steps: [],
                        subType: 'TRY',
                        localId: `T${item.localId}.1`
                      }
                    item.properties.try.steps.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${
                        item.properties.try.steps.length === 0 ? '1' : index + 2
                      }`
                    })
                  }
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${
                      item.properties.try.steps.length === 0 ? '1' : index + 2
                    }`
                  }
                } else {
                  item.properties.steps.push({
                    ...itemToPaste,
                    localId: `${item.localId}.${
                      item.properties.steps.length === 0 ? '1' : index + 2
                    }`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${
                      item.properties.steps.length === 0 ? '1' : index + 2
                    }`
                  }
                }
              } else {
                if (this.tryCatch === 'try') {
                  if (!item.properties.try)
                    item.properties.try = {
                      name: '',
                      steps: [],
                      subType: 'TRY',
                      localId: `T${item.localId}.1`
                    }
                  item.properties.try.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `T${item.localId}.1`
                  }
                } else if (this.tryCatch === 'finally') {
                  if (!item.properties.finally)
                    item.properties.finally = {
                      name: '',
                      steps: [],
                      subType: 'FINALLY',
                      localId: `F${item.localId}.1`
                    }
                  item.properties.finally.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `F${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                } else if (!this.tryCatch) {
                  item.properties.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
              }
              this.beforeStep = null
            }
          }

          if (
            this.beforeStep &&
            item.localId === this.beforeStep.localId &&
            this.tryCatch === 'catch' &&
            !this.previewLock &&
            item.properties.catch?.length === 0
          ) {
            if (!item.properties.catch) item.properties.catch = []
            item.properties.catch.push({
              ...JSON.parse(JSON.stringify(this.newException)),
              id: this.highestId,
              type,
              localId: `C${item.localId}.1`
            })
            this.selectedItem = {
              ...JSON.parse(JSON.stringify(this.newException)),
              id: this.highestId,
              type,
              localId: `C${item.localId}.1`
            }
          }

          if (defaultItem.localId === item.localId) {
            if (isInnerTryCatch === 'try') {
              item.isSelected = status
              this.selectedItem = item.properties.try
              item.properties.try.isSelected = true
              if (item.properties?.finally)
                item.properties.finally.isSelected = false
              item.properties.try.subType = 'TRY'
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            } else if (isInnerTryCatch === 'finally') {
              item.isSelected = status
              this.selectedItem = item.properties.finally
              item.properties.finally.isSelected = true
              if (item.properties?.try) item.properties.try.isSelected = false
              item.properties.finally.subType = 'FINALLY'
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            } else {
              if (item.properties?.finally)
                item.properties.finally.isSelected = true
              if (item.properties?.try) item.properties.try.isSelected = true
              item.isSelected = true
              this.selectedItem = item
              item.enabled = !item.enabled
              item.enabled = !item.enabled
            }
          } else {
            item.isSelected = status
            if (item.properties?.finally)
              item.properties.finally.isSelected = status
            if (item.properties?.try) item.properties.try.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          const returnTryOrFinallyStatus = (whereAreWe) => {
            if (
              item.properties.try &&
              isInnerTryCatch === 'try' &&
              whereAreWe === 'try'
            ) {
              return item.properties.try.isSelected || item.isSelected
            } else if (
              item.properties.finally &&
              isInnerTryCatch === 'finally' &&
              whereAreWe === 'finally'
            ) {
              return item.properties.finally.isSelected || item.isSelected
            } else {
              return item.isSelected
            }
          }

          const returnTryOrFinallyStatusOnlyIfSelected = () => {
            if (
              item.properties.try &&
              (isInnerTryCatch === 'try' || insideIsInnerTryCatch === 'try')
            ) {
              return item.properties.try.isSelected || item.isSelected
            } else if (
              item.properties.finally &&
              (isInnerTryCatch === 'finally' ||
                insideIsInnerTryCatch === 'finally')
            ) {
              return item.properties.finally.isSelected || item.isSelected
            } else {
              return item.isSelected
            }
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(
              item.properties.steps,
              returnTryOrFinallyStatus(),
              item.localId,
              false
            )
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(
              item.properties.try.steps,
              returnTryOrFinallyStatus('try'),
              `T${item.localId}`,
              false
            )
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(
              item.properties.finally.steps,
              returnTryOrFinallyStatus('finally'),
              `F${item.localId}`,
              false
            )
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2, index2) => {
              if (item2.id && item2.id > this.highestId)
                this.highestId = item2.id

              item2.localId = `C${item.localId}.${index2 + 1}`
              item2.mermaidId = this.mermaidId + 20000
              this.mermaidId++

              if (
                operation &&
                operation === 'add' &&
                this.addIsSpecial &&
                !this.previewLock
              ) {
                if (
                  item2 &&
                  item2.localId === this.beforeStep.localId &&
                  !this.previewLock
                ) {
                  this.previewLock = true
                  if (!item.properties.catch) item.properties.catch = []
                  if (itemToPaste && !itemToPaste.subType) {
                    item.properties.catch.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${index2 + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: `${item.localId}.${index2 + 2}`
                    }
                  } else {
                    item.properties.catch.push({
                      ...this.newException,
                      localId: `C${item.localId}.${index2 + 2}`
                    })
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newException)),
                      localId: `C${item.localId}.${index2 + 2}`
                    }
                  }
                  this.beforeStep = null
                }
              }

              if (
                operation &&
                operation === 'add' &&
                !this.addIsSpecial &&
                !this.previewLock
              ) {
                if (
                  item2 &&
                  this.beforeStep &&
                  item2.localId === this.beforeStep.localId &&
                  !this.previewLock &&
                  id === -1
                ) {
                  this.previewLock = true
                  if (this.tryCatch === 'try') {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.try.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `T${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      }
                      item2.try.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `T${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      })
                    }
                  } else if (this.tryCatch === 'finally') {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.finally.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `F${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      }
                      item2.finally.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `F${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      })
                    }
                  } else {
                    if (itemToPaste && !itemToPaste.subType) {
                      item2.steps.push({ ...itemToPaste })
                      this.selectedItem = { ...itemToPaste }
                    } else {
                      this.selectedItem = {
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      }
                      item2.steps.push({
                        ...JSON.parse(JSON.stringify(this.newStep)),
                        id: this.highestId,
                        type,
                        localId: `${item2.localId}.${
                          item2.steps.length === 0 ? '1' : index2 + 2
                        }`
                      })
                    }
                  }
                  this.beforeStep = null
                }
              }

              item2.isSelected =
                item2.localId === defaultItem.localId || item.isSelected
              item2.enabled = !item.enabled
              item2.enabled = !item.enabled
              item2.subType = 'EXCEPTION'
              if (item2.localId === defaultItem.localId)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                item.isSelected || item2.isSelected,
                item2.localId,
                false
              )
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            item.properties.conditions.forEach((item2, index2) => {
              if (item2.id && item2.id > this.highestId)
                this.highestId = item2.id

              item2.localId = `${item.localId}.${index2 + 1}`
              item2.mermaidId = this.mermaidId + 1
              this.mermaidId++

              if (
                operation &&
                operation === 'add' &&
                this.addIsSpecial &&
                !this.previewLock
              ) {
                if (
                  item2 &&
                  item2.localId === this.beforeStep.localId &&
                  !this.previewLock
                ) {
                  this.previewLock = true
                  if (!item.properties.conditions)
                    item.properties.conditions = []
                  if (itemToPaste && !itemToPaste.subType) {
                    item.properties.conditions.push({
                      ...itemToPaste,
                      localId: `${item.localId}.${index2 + 2}`
                    })
                    this.selectedItem = {
                      ...itemToPaste,
                      localId: `${item.localId}.${index2 + 2}`
                    }
                  } else {
                    item.properties.conditions.push({
                      ...this.newQuery,
                      localId: `${item.localId}.${index2 + 2}`
                    })
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newQuery)),
                      localId: `${item.localId}.${index2 + 2}`
                    }
                  }
                  this.beforeStep = null
                }
              }

              if (
                operation &&
                operation === 'add' &&
                !this.addIsSpecial &&
                !this.previewLock
              ) {
                if (
                  item2 &&
                  this.beforeStep &&
                  item2.localId === this.beforeStep.localId &&
                  !this.previewLock &&
                  id === -1
                ) {
                  this.previewLock = true
                  if (itemToPaste && !itemToPaste.subType) {
                    item2.steps.push({ ...itemToPaste })
                    this.selectedItem = { ...itemToPaste }
                  } else {
                    this.selectedItem = {
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: `${item2.localId}.${
                        item2.steps.length === 0 ? '1' : index2 + 2
                      }`
                    }
                    item2.steps.push({
                      ...JSON.parse(JSON.stringify(this.newStep)),
                      id: this.highestId,
                      type,
                      localId: `${item2.localId}.${
                        item2.steps.length === 0 ? '1' : index2 + 2
                      }`
                    })
                  }
                  this.beforeStep = null
                }
              }

              item2.isSelected =
                item2.localId === defaultItem.localId
                  ? true
                  : returnTryOrFinallyStatus()
              item2.enabled = !item.enabled
              item2.enabled = !item.enabled
              item2.subType = 'QUERY'
              if (item2.localId === defaultItem.localId)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                returnTryOrFinallyStatusOnlyIfSelected() || item2.isSelected,
                item2.localId,
                false
              )
            })
          } else if (
            item.properties &&
            !item.properties.conditions &&
            !this.tryCatch
          ) {
            if (
              operation &&
              operation === 'add' &&
              this.addIsSpecial &&
              !this.previewLock
            ) {
              if (
                this.beforeStep &&
                item.localId === this.beforeStep.localId &&
                !this.previewLock
              ) {
                this.previewLock = true
                if (!item.properties.conditions)
                  item.properties.conditions = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.properties.conditions.push({
                    ...itemToPaste,
                    localId: `${item.localId}.${index + 2}`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${index + 2}`
                  }
                } else {
                  item.properties.conditions.push({
                    ...this.newQuery,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newQuery)),
                    localId: `${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }

            if (
              operation &&
              operation === 'add' &&
              !this.addIsSpecial &&
              !this.previewLock
            ) {
              if (
                this.beforeStep &&
                item.localId === this.beforeStep.localId &&
                !this.previewLock &&
                id === -1
              ) {
                this.previewLock = true
                if (!item.steps) item.steps = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.steps.push({
                    ...itemToPaste,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.1`
                  }
                } else {
                  item.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }
          } else if (
            item.properties &&
            !item.properties.catch &&
            this.tryCatch === 'catch'
          ) {
            if (
              operation &&
              operation === 'add' &&
              this.addIsSpecial &&
              !this.previewLock
            ) {
              if (
                this.beforeStep &&
                item.localId === this.beforeStep.localId &&
                !this.previewLock
              ) {
                this.previewLock = true
                if (!item.properties.catch) item.properties.catch = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.properties.catch.push({
                    ...itemToPaste,
                    localId: `${item.localId}.${index + 2}`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.${index + 2}`
                  }
                } else {
                  item.properties.catch.push({
                    ...this.newException,
                    localId: `C${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newException)),
                    localId: `C${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }

            if (
              operation &&
              operation === 'add' &&
              !this.addIsSpecial &&
              !this.previewLock
            ) {
              if (
                this.beforeStep &&
                item.localId === this.beforeStep.localId &&
                !this.previewLock &&
                id === -1
              ) {
                this.previewLock = true
                if (!item.steps) item.steps = []
                if (itemToPaste && !itemToPaste.subType) {
                  item.steps.push({
                    ...itemToPaste,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...itemToPaste,
                    localId: `${item.localId}.1`
                  }
                } else {
                  item.steps.push({
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  })
                  this.selectedItem = {
                    ...JSON.parse(JSON.stringify(this.newStep)),
                    id: this.highestId,
                    type,
                    localId: `${item.localId}.1`
                  }
                }
                this.beforeStep = null
              }
            }
          }
        })
      }

      await formatSteps(this.process.steps.steps, false, 1, true)

      if (operation === 'add') {
        this.tryCatch = ''
        await formatSteps(this.process.steps.steps, false, 1, true)
        this.newStep = {
          enabled: true,
          id: 0,
          isSelected: true,
          name: '',
          properties: {},
          type: '',
          enableStepLog: true,
          enableCacheLog: false,
          enableDebugLog: true
        }
        if (this.addIsSpecial) {
          this.newQuery = {
            query: '',
            isSelected: true,
            steps: [],
            subType: 'QUERY'
          }
          this.newException = {
            exceptions: [],
            isSelected: true,
            steps: [],
            subType: 'EXCEPTION'
          }
        }
        if (this.addIsInner) {
          this.tableKey++
          this.addIsInner = false
          this.selectItem(this.selectedItem)
        }
        setTimeout(() => {
          const el = this.selectedItem
            ? document.getElementById(`select-${this.selectedItem.localId}`)
            : null

          if (el) el.click()

          setTimeout(() => {
            this.selectedItem.enabled = !this.selectedItem.enabled
            this.selectedItem.enabled = !this.selectedItem.enabled
          }, 40)
        }, 100)

        if (this.logs && this.logs.length > 0) {
          this.logs.forEach((x) => {
            this.visualIdSearch(x.stepId, x.status)
          })
          setTimeout(() => this.logKey++, 200)
        }
      }

      if (
        this.logs &&
        this.logs.length > 0 &&
        this.selectedItem &&
        this.selectedItem.id
      ) {
        const findLogStep = this.logs.find(
          (x) => x.stepId === this.selectedItem.id
        )

        const logIndex = this.logs.indexOf(findLogStep)

        if (logIndex > -1) {
          this.selectedLogIndex = logIndex
        }

        if (findLogStep) {
          this.logs.map((x) => (x.isSelected = false))
          findLogStep.isSelected = true
        } else {
          this.logs.map((x) => (x.isSelected = false))
        }
      }

      this.filterTryCatchSteps()

      if (!this.loading && this.selectedItem) {
        this.headerTopControl(1)
        this.mini = true
        this.showDetailsWrapper = false
        this.showEventsAndStepWrapper = true
      }
    },
    async selectItem(selectedStep) {
      const formatSteps = (items, status = false) => {
        items.forEach((item) => {
          if (String(selectedStep.localId) === String(item.localId)) {
            item.isSelected = true
            item.enabled = !item.enabled
            item.enabled = !item.enabled
            this.selectedItem = item
          } else {
            item.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(
              item.properties.steps,
              selectedStep.id === item.id && item.isSelected
            )
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            item.properties.try.isSelected =
              item.properties.try.localId === selectedStep.localId ||
              item.isSelected
            formatSteps(
              item.properties.try.steps,
              selectedStep.id === item.id && item.isSelected
            )
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            item.properties.finally.isSelected =
              item.properties.finally.localId === selectedStep.localId ||
              item.isSelected
            formatSteps(
              item.properties.finally.steps,
              selectedStep.id === item.id && item.isSelected
            )
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              item2.isSelected =
                item2.localId === selectedStep.localId || item.isSelected
              item2.subType = 'EXCEPTION'
              if (item2.localId === selectedStep.localId)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                (selectedStep.subType && item2.isSelected) || item.isSelected
              )
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            item.properties.conditions.forEach((item2) => {
              item2.isSelected = item2.localId === selectedStep.localId
              item2.subType = 'QUERY'
              if (item2.localId === selectedStep.localId)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                selectedStep.subType && item2.isSelected
              )
            })
          }
        })
      }

      await formatSteps(this.process.steps.steps)

      if (!this.loading && this.selectedItem) {
        this.headerTopControl(1)
      }
    },
    showHideChildren(selectedStep) {
      const formatSteps = (items, status = false) => {
        items.forEach((item) => {
          if (selectedStep.item.localId === item.localId) {
            item.isSelected = true
            item.hideChildren = selectedStep.hideChildren
            item.hideChildrenTry = selectedStep.hideChildrenTry || false
            item.hideChildrenFinally =
              selectedStep.hideChildrenFinally || false
            item.enabled = !item.enabled
            item.enabled = !item.enabled
            this.selectedItem = item
          } else {
            item.isSelected = status
            item.enabled = !item.enabled
            item.enabled = !item.enabled
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(
              item.properties.steps,
              (selectedStep.item.id === item.id && item.isSelected) || status
            )
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(
              item.properties.try.steps,
              (selectedStep.item.id === item.id && item.isSelected) || status
            )
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(
              item.properties.finally.steps,
              (selectedStep.item.id === item.id && item.isSelected) || status
            )
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              item2.isSelected =
                item2.localId === selectedStep.item.localId || status
              if (item2.localId === selectedStep.item.localId) {
                item2.hideChildren = selectedStep.hideChildren
              }
              item2.subType = 'EXCEPTION'
              if (item2.exceptions === selectedStep.exceptions)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                (selectedStep.subType && item2.isSelected) || status
              )
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            item.properties.conditions.forEach((item2) => {
              item2.isSelected =
                item2.localId === selectedStep.item.localId || status
              if (item2.localId === selectedStep.item.localId) {
                item2.hideChildren = selectedStep.hideChildren
              }
              item2.subType = 'QUERY'
              if (item2.localId === selectedStep.item.localId)
                this.selectedItem = item2
              formatSteps(
                item2.steps,
                (selectedStep.item.subType && item2.isSelected) || status
              )
            })
          }
        })
      }

      formatSteps(this.process.steps.steps)

      if (!this.loading && this.selectedItem) this.headerTopControl(1)
    },
    openStepFromDebug(stepId, eventId) {
      if (!stepId) return

      this.selectStepFromLog(
        this.foundLogsSteps[stepId] || 'Not present',
        false
      )

      setTimeout(() => {
        const obj = {}

        obj.name = this.selectedItem.properties.processName

        getProcesses(obj)
          .then((res) => {
            const exactProcessNameSearch = res.data.data.items.find(
              (x) => x.name === obj.name
            )

            if (!exactProcessNameSearch) return

            const routeData = this.$router.resolve({
              name: 'processEdit',
              params: { id: exactProcessNameSearch.id },
              query: {
                eventId
              }
            })

            window.open(routeData.href, '_blank')
          })
          .catch((err) => {
            this.isLoadingProcesses = false
            this.addSnackbar({
              message: err,
              timeout: 5000,
              color: 'error'
            })          })
      }, 200)
    },
    selectStepFromLog(localStepId, isSelect = true) {
      if (localStepId === 'Not Present') {
        this.selectedItem = null
        this.previewData({ id: null }, '', null, '')
        this.tab = 0

        return
      }

      const searchStep = (items, searchableStepId) => {
        let itemFound = ''

        items.forEach((item) => {
          if (String(item.localId) === String(searchableStepId)) {
            itemFound = item
            this.selectedItem = itemFound
            this.selectItem(itemFound)

            setTimeout(() => {
              const element = document.getElementById(searchableStepId)

              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
              }
            }, 100)
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.steps, searchableStepId)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.try.steps, searchableStepId)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.finally.steps, searchableStepId)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0 &&
            !itemFound
          ) {
            item.properties.catch.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0 &&
            !itemFound
          ) {
            item.properties.conditions.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
        })
      }

      searchStep(this.process.steps.steps, localStepId)
    },
    unselectAllStepsFromLog() {
      this.logs.map((x) => {
        x.isSelected = false

        return x
      })
    },
    visualIdSearch(stepId, logStatus) {
      const searchStep = (items, searchableStepId) => {
        let itemFound = ''

        items.forEach((item) => {
          if (item.id && item.id === searchableStepId) {
            itemFound = item
            item.logStatus = logStatus ? logStatus : ''
            this.foundLogsSteps[searchableStepId] = item.localId
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.steps, searchableStepId)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.try.steps, searchableStepId)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0 &&
            !itemFound
          ) {
            searchStep(item.properties.finally.steps, searchableStepId)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0 &&
            !itemFound
          ) {
            item.properties.catch.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0 &&
            !itemFound
          ) {
            item.properties.conditions.forEach((item2) => {
              searchStep(item2.steps, searchableStepId)
            })
          }
        })
      }

      searchStep(this.process.steps.steps, stepId)

      this.process.steps.steps.forEach((item, index) => {
        this.$set(this.stepsToDisplay, index, item)
      })
    },
    moveItemsAround(moveItem, direction) {
      this.moveLock = false

      const formatSteps = (items) => {
        items.forEach((item, index) => {
          if (item.localId === moveItem.localId && !this.moveLock) {
            this.moveLock = true
            const element = items[index]

            items.splice(index, 1)
            items.splice(
              direction === 'down' ? index + 1 : index - 1,
              0,
              element
            )

            this.previewData({ id: null }, '', null, '')

            return
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            formatSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            formatSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            formatSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            if (moveItem.subType === 'EXCEPTION') {
              formatSteps(item.properties.catch)
            }
            item.properties.catch.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            if (moveItem.subType === 'QUERY') {
              formatSteps(item.properties.conditions)
            }
            item.properties.conditions.forEach((item2) => {
              formatSteps(item2.steps)
            })
          }
        })
      }

      formatSteps(this.process.steps.steps)
    },
    setBorderWidth() {
      const i = this.$refs.resizeme.querySelector('.border-me')

      i.style.zIndex = 1
      i.style.width = this.borderSize + 'px'
      i.style.cursor = 'ew-resize'
    },
    setEvents() {
      const minSize = 320
      const el = this.$refs.resizeme
      const elSteps = this.$refs.steps

      const resizemeBorder = el.querySelector('.border-me')
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this

      function resize(e) {
        document.body.style.cursor = 'ew-resize'
        const f = document.body.scrollWidth - e.clientX

        el.style.width = f + 'px'
      }

      resizemeBorder.addEventListener(
        'mousedown',
        (e) => {
          el.classList.add('noselect')
          elSteps.classList.add('noselect')
          if (e.offsetX < minSize) {
            el.style.transition = 'initial'
            document.addEventListener('mousemove', resize, false)
          }
        },
        false
      )

      document.addEventListener(
        'mouseup',
        () => {
          el.classList.remove('noselect')
          elSteps.classList.remove('noselect')
          el.style.transition = ''
          vm.width = el.style.width
          this.resizeMeWidth = vm.width
          document.body.style.cursor = ''
          document.removeEventListener('mousemove', resize, false)
        },
        false
      )
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    openStepFromError(stepId) {
      if (!stepId) return

      const allSteps = getAllStepsData(this.process?.steps?.steps || []) || []

      const selectedItem = allSteps.find((x) => x.id === stepId)

      if (selectedItem) this.previewData(selectedItem)
    },
    async fetchValidationRecursive(validation) {
      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      const fetchedValidations = []
      const tempValidation = (validationId) => {
        getValidationRule({ id: validationId })
          .then((res) => {
            fetchedValidations.push(res.data.data.fields.filter((y) => y.isRequired))
            if (res.data.data.includes && res.data.data.includes.length > 0) {
              res.data.data.includes.forEach((include) => {
                tempValidation(include)
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }

      await tempValidation(validation)

      await pause(1200)

      return fetchedValidations.flat(Infinity).map((x) => `$.${x.name}`)
    },
    async checkReqFields() {
      let errors = ''
      const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

      this.errorItems = []
      this.errorsNew = []

      let errorItemsLength = 0

      if (this.editRolesIds.length < 1 && !this.isSuperUser)
        errors = errors + `${this.$lang.errors.editRoleCreate}.<br>`

      const checkSteps = async (items) => {
        for (const item of items) {
          if (
            item &&
            item.properties &&
            item.properties.credentialName &&
            item.properties.credentialName.name
          ) {
            item.properties.credentialName =
              item.properties.credentialName.name
          }

          switch (item.type) {
          case 'I_CALENDAR':
            if (!item.name)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            // field organizer
            if (!item.properties || !item.properties.organizer)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Organizer field set.`,
                value: item.localId
              })

            // field summary
            if (!item.properties || !item.properties.summary)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Summary field set.`,
                value: item.localId
              })

            // field startTime
            if (!item.properties || !item.properties.startTime)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Start Time field set.`,
                value: item.localId
              })

            // field endTime
            if (!item.properties || !item.properties.endTime)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have End Time field set.`,
                value: item.localId
              })

            // field alarm.alarmTime
            if (!item.properties || !item.properties.alarm || !item.properties.alarm.alarmTime)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Alarm Time field set.`,
                value: item.localId
              })

            // field recurrence.frequency
            if (!item.properties || !item.properties.recurrence || !item.properties.recurrence.frequency)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Recurrence Frequency field set.`,
                value: item.localId
              })

            // field recurrence.frequencyInterval
            if (!item.properties || !item.properties.recurrence || !item.properties.recurrence.frequencyInterval)
              this.errorsNew.push({
                text: `iCalendar step local Id: ${item.localId} doesn't have Recurrence Frequency Interval field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `iCalendar step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `iCalendar step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            if (item.properties && item.properties.participants.length < 1) {
              this.errorsNew.push({
                text: `iCalendar step ${item.name} (local Id: ${item.localId}) doesn't have Participants set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.participants.length > 0) {
              item.properties.participants.forEach((participant, index) => {
                if (!participant.email)
                  this.errorsNew.push({
                    text: `iCalendar step ${item.name} (local Id: ${item.localId}) Participant ${index + 1} doesn't have Email set.`,
                    value: item.localId
                  })

                if (!participant.name)
                  this.errorsNew.push({
                    text: `iCalendar step ${item.name} (local Id: ${item.localId}) Participant ${index + 1} doesn't have Name set.`,
                    value: item.localId
                  })
              })
            }

            break
          case 'LDAP':
            if (!item.name)
              this.errorsNew.push({
                text: `LDAP step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.credentialName)
              this.errorsNew.push({
                text: `LDAP step local Id: ${item.localId} doesn't have Credential set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.action)
              this.errorsNew.push({
                text: `LDAP step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })

            if (item.properties.action && item.properties.fields) {
              if (item.properties.action === 'SEARCH') {
                if (!item.properties.fields.targetObject)
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                    value: item.localId
                  })

                if (item.properties.fields.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.fields.targetObject, true)) this.errorsNew.push({
                  text: `LDAP step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                  value: item.localId
                })

                if (!item.properties.fields.filter)
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have Filter field set.`,
                    value: item.localId
                  })
              }
              if (item.properties.action === 'BIND') {
                if (!item.properties.fields.dn)
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have DN field set.`,
                    value: item.localId
                  })

                if (!item.properties.fields.attributeValues || (item.properties.fields.attributeValues && item.properties.fields.attributeValues.length < 1))
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have Attributes Values set.`,
                    value: item.localId
                  })

                if (!item.properties.fields.objectClassAttributes || (item.properties.fields.objectClassAttributes && item.properties.fields.attributeValues.length < 1))
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have Object Class Attributes set.`,
                    value: item.localId
                  })
              }

              if (item.properties.action === 'MODIFY') {
                if (!item.properties.fields.dn)
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have DN field set.`,
                    value: item.localId
                  })

                if (!item.properties.fields.attributeValues || (item.properties.fields.attributeValues && item.properties.fields.attributeValues.length < 1))
                  this.errorsNew.push({
                    text: `LDAP step ${item.name} (local Id: ${item.localId}) doesn't have Attributes Values set.`,
                    value: item.localId
                  })
              }
            }

            break
          case 'SSH':
            if (!item.name)
              this.errorsNew.push({
                text: `SSH step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.credentialName)
              this.errorsNew.push({
                text: `SSH step local Id: ${item.localId} doesn't have Credential set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.command)
              this.errorsNew.push({
                text: `SSH step local Id: ${item.localId} doesn't have Command field set.`,
                value: item.localId
              })

            break
          case 'XLS':
            if (!item.name)
              this.errorsNew.push({
                text: `XLS step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.source)
              this.errorsNew.push({
                text: `XLS step local Id: ${item.localId} doesn't have Source field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `XLS step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `XLS step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            break
          case 'YAML':
            if (!item.name)
              this.errorsNew.push({
                text: `YAML step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (!item.properties || !item.properties.source)
              this.errorsNew.push({
                text: `YAML step local Id: ${item.localId} doesn't have Source field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `YAML step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `YAML step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            break
          case 'CHAT_GPT':
            if (!item.name)
              this.errorsNew.push({
                text: `ChatGPT step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `ChatGPT step local Id: ${item.localId} doesn't have Credential field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.model)
              this.errorsNew.push({
                text: `ChatGPT step local Id: ${item.localId} doesn't have Model field set.`,
                value: item.localId
              })

            if ((item.properties && !item.properties.messages) || (item.properties && item.properties.messages && item.properties.messages.length < 1))
              this.errorsNew.push({
                text: `ChatGPT step local Id: ${item.localId} doesn't have Messages set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `ChatGPT step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `ChatGPT step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            break
          case 'QR':
            if (!item.name)
              this.errorsNew.push({
                text: `QR step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `QR step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `QR step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            if (item.properties && !item.properties.content) {
              this.errorsNew.push({
                text: `QR step ${item.name} (local Id: ${item.localId}) doesn't have Content field set.`,
                value: item.localId
              })
            }

            if (item.properties && !item.properties.size) {
              this.errorsNew.push({
                text: `QR step ${item.name} (local Id: ${item.localId}) doesn't have Size field set.`,
                value: item.localId
              })
            }

            break
          case 'OCR':
            if (!item.name)
              this.errorsNew.push({
                text: `OCR step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `OCR step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `OCR step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            if (item.properties && !item.properties.image) {
              this.errorsNew.push({
                text: `OCR step ${item.name} (local Id: ${item.localId}) doesn't have Image field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.image && !this.$options.filters.javaVariableConventionRules(item.properties.image, true)) this.errorsNew.push({
              text: `OCR step ${item.name} (local Id: ${item.localId}) Image is invalid.`,
              value: item.localId
            })

            if (item.properties && !item.properties.language) {
              this.errorsNew.push({
                text: `OCR step ${item.name} (local Id: ${item.localId}) doesn't have Language field set.`,
                value: item.localId
              })
            }

            break
          case 'XML':
            if (!item.name)
              this.errorsNew.push({
                text: `XML step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            break
          case 'FTP':
            if (!item.name)
              this.errorsNew.push({
                text: `FTP step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.credentialName) {
              this.errorsNew.push({
                text: `FTP step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name field set.`,
                value: item.localId
              })
            }

            if (item.properties && !item.properties.path) {
              this.errorsNew.push({
                text: `FTP step ${item.name} (local Id: ${item.localId}) doesn't have Path field set.`,
                value: item.localId
              })
            }

            if (item.properties && !item.properties.action) {
              this.errorsNew.push({
                text: `FTP step ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
                value: item.localId
              })
            }

            if (item.properties && !item.properties.targetObject) {
              this.errorsNew.push({
                text: `FTP step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            }

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `FTP step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })

            break
          case 'ESC_CHARS':
            if (!item.name) this.errorsNew.push({
              text: `Escape characters step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })

            if (item.properties && !item.properties.sourceObject) this.errorsNew.push({
              text: `Escape characters step ${item.name} (local Id: ${item.localId}) doesn't have Source Object field set.`,
              value: item.localId
            })

            if (item.properties && item.properties.sourceObject && !this.$options.filters.javaVariableConventionRules(item.properties.sourceObject, true)) this.errorsNew.push({
              text: `Escape characters step ${item.name} (local Id: ${item.localId}) Source Object is invalid.`,
              value: item.localId
            })

            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `Escape characters step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })

            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `Escape characters step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            break
          case 'GRAPHQL':
            if (!item.name) this.errorsNew.push({
              text: `GraphQL step local Id: ${item.localId} doesn't have Name field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.scriptEngine) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.timeout) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) doesn't have Timeout field set.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetObject) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetObject && !this.$options.filters.javaVariableConventionRules(item.properties.targetObject, true)) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
              value: item.localId
            })
            if (item.properties && !item.properties.targetUrl) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) doesn't have Target URL field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.targetUrl) {
              const first4 = item.properties.targetUrl.substring(0, 4)

              if (first4 === 'http') item.properties.targetUrl = `"${item.properties.targetUrl}"`
            }
            if (item.properties && item.properties.credentialName && item.properties.credentialName.name) {
              item.properties.credentialName = item.properties.credentialName.name
            }
            if (item.properties && !item.properties.responseCode) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) doesn't have Response Code field set.`,
              value: item.localId
            })
            if (item.properties && item.properties.responseCode && !this.$options.filters.javaVariableConventionRules(item.properties.responseCode, true)) this.errorsNew.push({
              text: `GraphQL step ${item.name} (local Id: ${item.localId}) Response Code is invalid.`,
              value: item.localId
            })
            break
          case 'XSLT':
            if (!item.name)
              this.errorsNew.push({
                text: `XSLT step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `XSLT step local Id: ${item.localId} doesn't have Target Object set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.xml)
              this.errorsNew.push({
                text: `XSLT step local Id: ${item.localId} doesn't have XML field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.xslt)
              this.errorsNew.push({
                text: `XSLT step local Id: ${item.localId} doesn't have XSLT field set.`,
                value: item.localId
              })
            break
          case 'PYTHON':
            if (!item.name)
              this.errorsNew.push({
                text: `Python step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              (item.properties && !item.properties.set) ||
                (item.properties &&
                  item.properties.set &&
                  Object.keys(item.properties.set).length === 0)
            )
              this.errorsNew.push({
                text: `Python step local Id: ${item.localId} doesn't have Values set.`,
                value: item.localId
              })
            if (item.properties && item.properties.set) {
              for (const row in item.properties.set) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.set.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Python step ${item.name} (local Id: ${item.localId}) key '${row}' is invalid.`,
                      value: item.localId
                    })
                  if (
                    item.properties.set[row] &&
                      !item.properties.set[row].result
                  )
                    this.errorsNew.push({
                      text: `Python step ${item.name} (local Id: ${item.localId}) Result for '${row}' is missing.`,
                      value: item.localId
                    })
                    // Removing for now, until we figure out complete validation - MD - 23.08.2022
                    // if (!this.$options.filters.checkComplexRegex(item.properties.set[row])) this.errorsNew.push({ text: `JavaScript step ${item.name} (local Id: ${item.localId}) Set value '${row}' is invalid.`, value: item.localId })
                }
              }
            }
            break
          case 'FORMATTING':
            if (!item.name)
              this.errorsNew.push({
                text: `Formatting step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Formatting step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })

            if (
              item.properties &&
                item.properties.converts &&
                item.properties.converts.length > 0
            ) {
              item.properties.converts.forEach((convert) => {
                if (!convert.sourceObject)
                  this.errorsNew.push({
                    text: `Formatting step local Id: ${item.localId} doesn't have Converts Source Object field set.`,
                    value: item.localId
                  })
                if (
                  convert.sourceObject &&
                    !this.$options.filters.javaVariableConventionRules(
                      convert.sourceObject,
                      true
                    )
                )
                  this.errorsNew.push({
                    text: `Formatting step local Id: ${item.localId} Converts Source Object field is not valid.`,
                    value: item.localId
                  })
                if (!convert.targetObject)
                  this.errorsNew.push({
                    text: `Formatting step local Id: ${item.localId} doesn't have Converts Target Object field set.`,
                    value: item.localId
                  })
                if (
                  convert.targetObject &&
                    !this.$options.filters.javaVariableConventionRules(
                      convert.targetObject,
                      true
                    )
                )
                  this.errorsNew.push({
                    text: `Formatting step local Id: ${item.localId} Converts Target Object field is not valid.`,
                    value: item.localId
                  })
              })
            }

            if (item.properties && !item.properties.fields)
              this.errorsNew.push({
                text: `Formatting step local Id: ${item.localId} doesn't have Fields set.`,
                value: item.localId
              })

            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'DECIMAL_FORMAT'
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.locale
              )
                this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} doesn't have Locale set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.pattern
              )
                this.errorsNew.push({
                  text: `Formatting step local Id: ${item.localId} doesn't have Pattern set.`,
                  value: item.localId
                })
            }
            break
          case 'STORAGE':
            if (!item.name)
              this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.key)
              this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Key field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.fields)
              this.errorsNew.push({
                text: `Storage step local Id: ${item.localId} doesn't have Fields set.`,
                value: item.localId
              })

            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'GET'
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.targetObject
              )
                this.errorsNew.push({
                  text: `Storage step local Id: ${item.localId} doesn't have Target Object set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.targetObject &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.targetObject,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `Storage step local Id: ${item.localId} Target Object field is not valid.`,
                  value: item.localId
                })
            }

            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'UPSERT'
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.expiresIn
              )
                this.errorsNew.push({
                  text: `Storage step local Id: ${item.localId} doesn't have Expires In set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields['value']
              )
                this.errorsNew.push({
                  text: `Storage step local Id: ${item.localId} doesn't have Value set.`,
                  value: item.localId
                })
            }
            break
          case 'ENCODER':
            if (!item.name)
              this.errorsNew.push({
                text: `Encoder step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Encoder step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if ((item.properties && !item.properties.converts) || (item.properties && item.properties.converts.length === 0))

              this.errorsNew.push({
                text: `Encoder step local Id: ${item.localId} doesn't have Converts set.`,
                value: item.localId
              })
            if (item.properties && item.properties.action && item.properties.action === 'CHANGE_ENCODING') {
              if (item.properties && !item.properties.converts.some((x) => x.sourceEncoding))

                this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} doesn't have Source Encoding field set.`,
                  value: item.localId
                })
              if (item.properties && !item.properties.converts.some((x) => x.targetEncoding))
                this.errorsNew.push({
                  text: `Encoder step local Id: ${item.localId} doesn't have Target Encoding field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.converts &&
                item.properties.converts.length > 0
            ) {
              item.properties.converts.forEach((convert) => {
                if (!convert.sourceObject)
                  this.errorsNew.push({
                    text: `Encoder step local Id: ${item.localId} doesn't have Converts Source Object field set.`,
                    value: item.localId
                  })
                if (
                  convert.sourceObject &&
                    !this.$options.filters.javaVariableConventionRules(
                      convert.sourceObject,
                      true
                    )
                )
                  this.errorsNew.push({
                    text: `Encoder step local Id: ${item.localId} Converts Source Object field is not valid.`,
                    value: item.localId
                  })
                if (!convert.targetObject)
                  this.errorsNew.push({
                    text: `Encoder step local Id: ${item.localId} doesn't have Converts Target Object field set.`,
                    value: item.localId
                  })
                if (
                  convert.targetObject &&
                    !this.$options.filters.javaVariableConventionRules(
                      convert.targetObject,
                      true
                    )
                )
                  this.errorsNew.push({
                    text: `Encoder step local Id: ${item.localId} Converts Target Object field is not valid.`,
                    value: item.localId
                  })
              })
            }
            break
          case 'LOG':
            if (!item.name)
              this.errorsNew.push({
                text: `Log step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.message)
              this.errorsNew.push({
                text: `Log step local Id: ${item.localId} doesn't have Message field set.`,
                value: item.localId
              })
            break
          case 'ZIP':
            if (!item.name)
              this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Target object field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `ZIP step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'COMPRESS'
            ) {
              if (item.properties && !item.properties.fields)
                this.errorsNew.push({
                  text: `ZIP step local Id: ${item.localId} doesn't have Entries set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.entries &&
                  item.properties.fields.entries.length === 0
              )
                this.errorsNew.push({
                  text: `ZIP step local Id: ${item.localId} doesn't have Entries set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.entries &&
                  item.properties.fields.entries.length > 0
              ) {
                item.properties.fields.entries.forEach((item2, index2) => {
                  if (!item2.content)
                    this.errorsNew.push({
                      text: `ZIP step local Id: ${
                        item.localId
                      } doesn't have Content ${index2 + 1} set.`,
                      value: item.localId
                    })
                  if (!item2.name)
                    this.errorsNew.push({
                      text: `ZIP step local Id: ${
                        item.localId
                      } doesn't have Name ${index2 + 1} set.`,
                      value: item.localId
                    })
                })
              }
            }

            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'DECOMPRESS'
            ) {
              if (item.properties && !item.properties.fields)
                this.errorsNew.push({
                  text: `ZIP step local Id: ${item.localId} doesn't have Source Object set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.sourceObject
              )
                this.errorsNew.push({
                  text: `ZIP step local Id: ${item.localId} doesn't have Source Object set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.sourceObject &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.sourceObject,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `ZIP step local Id: ${item.localId} Source Object is not valid.`,
                  value: item.localId
                })
            }

            break
          case 'QUERY_BUILDER':
            if (!item.name)
              this.errorsNew.push({
                text: `Query builder step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Query builder step local Id: ${item.localId} doesn't have Target object field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.queryConditions &&
                item.properties.queryConditions.length === 0
            )
              this.errorsNew.push({
                text: `Query builder step local Id: ${item.localId} doesn't have Query Conditions set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.queryConditions &&
                item.properties.queryConditions.length > 0
            ) {
              item.properties.queryConditions.forEach((item2, index2) => {
                if (!item2.condition)
                  this.errorsNew.push({
                    text: `Query builder step local Id: ${
                      item.localId
                    } doesn't have Condition ${index2 + 1} set.`,
                    value: item.localId
                  })
                if (!item2.query)
                  this.errorsNew.push({
                    text: `Query builder step local Id: ${
                      item.localId
                    } doesn't have Query ${index2 + 1} set.`,
                    value: item.localId
                  })
              })
            }

            if (item.properties.delimiter && item.properties.delimiter.length > 16) {
              this.errorsNew.push({
                text: `Query builder step local Id: ${item.localId} Delimiter is too long.`,
                value: item.localId
              })
            }

            break
          case 'CREDENTIAL':
            if (!item.name)
              this.errorsNew.push({
                text: `Credential step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Credential step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })

            if (item.properties && item.properties.action === 'CREATE') {
              if (!item.properties.fields.targetObject)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                  value: item.localId
                })
              if (!item.properties.fields.type) {
                if (!item.properties.targetObject) {
                  this.errorsNew.push({
                    text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                    value: item.localId
                  })
                }
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Type field set.`,
                  value: item.localId
                })
              }
              if (!item.properties.fields.name)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Name field set.`,
                  value: item.localId
                })
              if (!item.properties.fields.roles)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Roles field set.`,
                  value: item.localId
                })
              if (
                item.properties.fields.values &&
                  Object.keys(item.properties.fields.values).length === 0
              )
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Values set.`,
                  value: item.localId
                })
            }
            if (item.properties && item.properties.action === 'UPDATE') {
              if (!item.properties.fields.queryName)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Query Name field set.`,
                  value: item.localId
                })
              if (!item.properties.fields.type) {
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Type field set.`,
                  value: item.localId
                })
              }
              if (!item.properties.fields.name)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Name field set.`,
                  value: item.localId
                })
              if (!item.properties.fields.roles)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Roles field set.`,
                  value: item.localId
                })
              if (
                item.properties.fields.values &&
                  Object.keys(item.properties.fields.values).length === 0
              )
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Values set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                ['ENABLE', 'DISABLE'].includes(item.properties.action)
            ) {
              if (!item.properties.fields.queryName)
                this.errorsNew.push({
                  text: `Credential step ${item.name} (local Id: ${item.localId}) doesn't have Query Name field set.`,
                  value: item.localId
                })
            }
            break
          case 'UUID':
            if (!item.name)
              this.errorsNew.push({
                text: `UUID step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `UUID step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            break
          case 'PAYMENT_SENSE_CONNECT_E':
            if (!item.name)
              this.errorsNew.push({
                text: `Paymentsense Connect E step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'PAYMENT_METHODS'
            ) {
              if (item.properties && item.properties.parameters)
                item.properties.parameters = null
            }
            if (
              item.properties &&
                item.properties.action &&
                ['ACCESS_TOKENS'].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.currencyCode
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Currency Code field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.merchantUrl
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Merchant Url field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.orderId
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Order Id field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.transactionType
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Transaction Type field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                [
                  'PAYMENT_DETAILS',
                  'RESUME_PAYMENT',
                  'REVOKE_ACCESS_TOKEN'
                ].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.accessToken
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Access Token field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                ['CROSS_REFERENCE_PAYMENT'].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.accessToken
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Access Token field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.crossReferencePayment
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Cross Reference Payment fields set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.parameters &&
                  item.properties.parameters.crossReferencePayment &&
                  !item.properties.parameters.crossReferencePayment
                    .crossReference
              )
                this.errorsNew.push({
                  text: `Paymentsense Connect E step ${item.name} (local Id: ${item.localId}) doesn't have Cross Reference field set.`,
                  value: item.localId
                })
            }
            break
          case 'PAYMENT_SENSE_PAC':
            if (!item.name)
              this.errorsNew.push({
                text: `Paymentsense PAC step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'GET_TERMINALS'
            ) {
              if (item.properties && item.properties.parameters)
                item.properties.parameters = null
            }
            if (
              item.properties &&
                item.properties.action &&
                !['GET_TERMINALS'].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.terminalId
              )
                this.errorsNew.push({
                  text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Terminal Id field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                [
                  'START_TRANSACTION',
                  'GET_TRANSACTION',
                  'SIGNATURE',
                  'CANCEL_TRANSACTION',
                  'GET_REPORT'
                ].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.requestId
              )
                this.errorsNew.push({
                  text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Request Id field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                ['START_REPORT'].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.reportType
              )
                this.errorsNew.push({
                  text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Report Type field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                ['START_TRANSACTION'].includes(item.properties.action)
            ) {
              if (
                item.properties &&
                  item.properties.parameters &&
                  !item.properties.parameters.transaction
              )
                this.errorsNew.push({
                  text: `Paymentsense PAC step ${item.name} (local Id: ${item.localId}) doesn't have Transaction object set.`,
                  value: item.localId
                })
            }
            break
          case 'MESSAGING':
            if (!item.name)
              this.errorsNew.push({
                text: `Messaging step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.service)
              this.errorsNew.push({
                text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Service set.`,
                value: item.localId
              })
            if (item.properties.service === 'KAFKA') {
              if (item.properties && !item.properties.config.messageKey)
                this.errorsNew.push({
                  text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Message Key set.`,
                  value: item.localId
                })
              delete item.properties.config.topic
            }
            if (item.properties && !item.properties.message)
              this.errorsNew.push({
                text: `Messaging step ${item.name} (local Id: ${item.localId}) doesn't have Message set.`,
                value: item.localId
              })
            break
          case 'TRY_CATCH':
            if (!item.name)
              this.errorsNew.push({
                text: `Try catch step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                (!item.properties.try ||
                  (item.properties.try &&
                    item.properties.try.steps &&
                    item.properties.try.steps.length === 0))
            )
              this.errorsNew.push({
                text: `Try catch step local Id: ${item.localId} doesn't have any Try steps set.`,
                value: item.localId
              })
            if (
              item.properties &&
                (!item.properties.catch ||
                  (item.properties.catch && item.properties.catch.length === 0))
            )
              this.errorsNew.push({
                text: `Try catch step local Id: ${item.localId} doesn't have any Try steps set.`,
                value: item.localId
              })

            // eslint-disable-next-line no-case-declarations
            let hasAll = 0

            if (
              item.properties &&
                item.properties.catch &&
                item.properties.catch.length > 0
            ) {
              item.properties.catch.forEach((item2, i) => {
                if (item2 && item2.steps?.length === 0)
                  this.errorsNew.push({
                    text: `Try catch step local Id: ${item.localId} Catch Exception ${i} doesn't have any steps set.`,
                    value: item.localId
                  })
                if (item2 && item2.exceptions?.length === 0)
                  this.errorsNew.push({
                    text: `Try catch step local Id: ${item.localId} Catch Exception ${i} doesn't have any exceptions set.`,
                    value: item.localId
                  })
                if (item2.exceptions.includes('ALL')) hasAll++
                if (hasAll > 1)
                  this.errorsNew.push({
                    text: `Try catch step ${item.name} (local Id: ${item.localId}) Catch Exception ${i} has multiple ALL conditions.`,
                    value: item.localId
                  })
              })
            }
            if (
              item.properties &&
              item.properties.finally &&
              item.properties.finally.steps &&
              item.properties.finally.steps.length === 0
            ) {
              item.properties.finally = null
            }
            break
          case 'EXECUTE_EXTERNAL_COMMAND':
            if (!item.name)
              this.errorsNew.push({
                text: `Execute external command step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.command)
              this.errorsNew.push({
                text: `Execute external command step ${item.name} (local Id: ${item.localId}) doesn't have Command field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Execute external command step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `Execute external command step ${item.name} (local Id: ${item.localId}) Target Object field is invalid.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.resultCode &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.resultCode,
                  true
                )
            )
              this.errorsNew.push({
                text: `Execute external command step ${item.name} (local Id: ${item.localId}) Result Code field is invalid.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.resultErrors &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.resultErrors,
                  true
                )
            )
              this.errorsNew.push({
                text: `Execute external command step ${item.name} (local Id: ${item.localId}) Result Errors field is invalid.`,
                value: item.localId
              })

            break
          case 'IMAGE':
            if (!item.name)
              this.errorsNew.push({
                text: `Image step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'RESCALE'
            ) {
              if (!item.properties.fields || Object.keys(item.properties.fields).length === 0) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.width)
                  this.errorsNew.push({
                    text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Width set.`,
                    value: item.localId
                  })
                if (!item.properties.fields.height)
                  this.errorsNew.push({
                    text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Height set.`,
                    value: item.localId
                  })
              }
            }
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'CONVERT'
            ) {
              if (!item.properties.fields || Object.keys(item.properties.fields).length === 0) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.type)
                  this.errorsNew.push({
                    text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Type set.`,
                    value: item.localId
                  })
              }
            }
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'FROM_TEMPLATE'
            ) {
              if (!item.properties.fields || Object.keys(item.properties.fields).length === 0) {
                this.errorsNew.push({
                  text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Fields set.`,
                  value: item.localId
                })
              } else {
                if (!item.properties.fields.textTemplate)
                  this.errorsNew.push({
                    text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Template set.`,
                    value: item.localId
                  })
                if (!item.properties.fields.width)
                  this.errorsNew.push({
                    text: `Image step ${item.name} (local Id: ${item.localId}) doesn't have Width set.`,
                    value: item.localId
                  })
              }
            }
            break
          case 'CSV':
            if (!item.name)
              this.errorsNew.push({
                text: `CSV step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.source)
              this.errorsNew.push({
                text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Source set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Action set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.delimiter &&
                item.properties.delimiter === 'CUSTOM'
            ) {
              if (item.properties && !item.properties.customDelimiter)
                this.errorsNew.push({
                  text: `CSV step ${item.name} (local Id: ${item.localId}) doesn't have Custom Delimiter set.`,
                  value: item.localId
                })
            }
            if (item.properties && item.properties.filters) {
              const filtersKeys = Object.keys(item.properties.filters)

              if (filtersKeys.length > 0) {
                filtersKeys.forEach((filter) => {
                  if (!item.properties.filters[filter])
                    this.errorsNew.push({
                      text: `CSV step ${item.name} (local Id: ${item.localId}) filter name ${filter} value is not set.`,
                      value: item.localId
                    })
                })
              }
            }

            break
          case 'PDF':
            if (!item.name)
              this.errorsNew.push({
                text: `PDF step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (!item.properties?.targetObject)
              this.errorsNew.push({
                text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (item.properties?.action === 'GENERATE' && !item.properties?.fields?.textTemplate)
              this.errorsNew.push({
                text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have Text Template set.`,
                value: item.localId
              })
            if (
              item.properties?.action === 'GENERATE' &&
                item.properties?.fields?.locale?.length !== 2
            )
              this.errorsNew.push({
                text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have correct Locale set.`,
                value: item.localId
              })
            if (item.properties?.fields?.locale === '')
              delete item.properties.fields.locale

            if (item.properties?.action === 'GENERATE' && item.properties?.fields?.variables && Object.keys(item.properties.fields.variables).length > 0) {
              const variablesKeys = Object.keys(item.properties.fields.variables)

              variablesKeys.forEach((variable) => {
                if (!item.properties.fields.variables[variable])
                  this.errorsNew.push({
                    text: `PDF step ${item.name} (local Id: ${item.localId}) variable name ${variable} value is not set.`,
                    value: item.localId
                  })
                if (!this.$options.filters.javaVariableConventionRules(
                  variable,
                  true
                )) {
                  this.errorsNew.push({
                    text: `PDF step ${item.name} (local Id: ${item.localId}) variable name ${variable} is not valid.`,
                    value: item.localId
                  })
                }
              })
            }
            if (['GET_PAGE_COUNT', 'GET_PAGE_AS_PICTURE', 'GET_TEXT'].includes(item.properties?.action) 
              && !item.properties?.fields?.sourceObject) {
              this.errorsNew.push({
                text: `PDF step ${item.name} (local Id: ${item.localId}) doesn't have Source Object field set.`,
                value: item.localId
              })
            }
            break
          case 'IMAP':
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Action field set`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action === 'SEARCH'
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.targetObject
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.targetObject &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.targetObject,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Target Object field is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.readType
              )
                delete item.properties.fields.readType
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.sender &&
                  !this.$options.filters.checkComplexRegex(
                    item.properties.fields.sender
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Sender field is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.recipient &&
                  !this.$options.filters.checkComplexRegex(
                    item.properties.fields.recipient
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Recipient field is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.subject &&
                  !this.$options.filters.checkComplexRegex(
                    item.properties.fields.subject
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Subject field is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.body &&
                  !this.$options.filters.checkComplexRegex(
                    item.properties.fields.body
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Body field is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.folder &&
                  !this.$options.filters.checkComplexRegex(
                    item.properties.folder
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Folder field is invalid.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.action &&
                item.properties.action !== 'SEARCH'
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.emailId
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) doesn't have Email Id field set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.emailId &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.emailId,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `Imap step ${item.name} (local Id: ${item.localId}) Email Id field is invalid.`,
                  value: item.localId
                })
            }
            break
          case 'SECURITY':
            if (!item.name)
              this.errorsNew.push({
                text: `Security step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Security step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.method)
              this.errorsNew.push({
                text: `Security step local Id: ${item.localId} doesn't have Method field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.variables)
              this.errorsNew.push({
                text: `Security step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
                value: item.localId
              })
            if (item.properties && item.properties.variables) {
              const variablesKeys = Object.keys(item.properties.variables)

              if (variablesKeys && variablesKeys.length === 0)
                this.errorsNew.push({
                  text: `Security step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
                  value: item.localId
                })

              if (variablesKeys.length > 0) {
                variablesKeys.forEach((variable) => {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      variable,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Security step ${item.name} (local Id: ${item.localId}) variable name ${variable} is invalid.`,
                      value: item.localId
                    })
                  if (!item.properties.variables[variable])
                    this.errorsNew.push({
                      text: `Security step ${item.name} (local Id: ${item.localId}) variable name ${variable} value is not set.`,
                      value: item.localId
                    })
                })
              }
            }
            break
          case 'UNSET_VARIABLES':
            if (!item.name)
              this.errorsNew.push({
                text: `Unset step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.variables)
              this.errorsNew.push({
                text: `Unset step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.variables &&
                item.properties.variables.length === 0
            )
              this.errorsNew.push({
                text: `Unset step ${item.name} (local Id: ${item.localId}) doesn't have any variable set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.variables &&
                item.properties.variables.length > 0
            ) {
              item.properties.variables.forEach((variable) => {
                if (
                  !this.$options.filters.javaVariableConventionRules(
                    variable,
                    true
                  )
                )
                  this.errorsNew.push({
                    text: `Unset step ${item.name} (local Id: ${item.localId}) variable name ${variable} is invalid.`,
                    value: item.localId
                  })
              })
            }
            break
          case 'S3':
            if (!item.name)
              this.errorsNew.push({
                text: `S3 step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `S3 step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (item.action && item.action === 'READ') {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.targetObject
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.targetObject &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.targetObject,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.key
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                  value: item.localId
                })
              delete item.properties.fields.content
            }
            if (item.action && item.action === 'DELETE') {
              delete item.properties.fields.content
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.key
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                  value: item.localId
                })
            }
            if (item.action && item.action === 'CREATE') {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.key
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Key set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.content
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Content set.`,
                  value: item.localId
                })
            }
            if (item.action && item.action === 'LIST') {
              delete item.properties.fields.content
              delete item.properties.fields.key
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.targetObject
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.targetObject &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.targetObject,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `S3 step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                  value: item.localId
                })
            }
            break
          case 'USER':
            if (!item.name)
              this.errorsNew.push({
                text: `User step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `User step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (
              item.action &&
                ![
                  'GET_USER_DETAILS',
                  'GET_USER_ROLES',
                  'GET_USERS_BY_ROLES'
                ].includes(item.action)
            ) {
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.email
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Email set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.fields &&
                !item.properties.fields.targetObject
            )
              this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.fields &&
                item.properties.fields.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.fields.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            if (item.action && item.action === 'GET_USERS_BY_ROLES') {
              delete item.properties.fields.name
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
              delete item.properties.fields.email
            }
            if (item.action && item.action === 'CHECK_EMAIL_AVAILABILITY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
            }
            if (item.action && item.action === 'CREATE_USER') {
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              delete item.properties.fields.userId
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.name
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Name set.`,
                  value: item.localId
                })
            }
            if (item.action && item.action === 'VALIDATE_API_KEY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.password
              delete item.properties.fields.userId
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.apiKey
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Api Key set.`,
                  value: item.localId
                })
            }
            if (item.action && item.action === 'GENERATE_API_KEY') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.userId
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.password
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Password set.`,
                  value: item.localId
                })
            }
            if (item.action && item.action === 'VALIDATE_PASSWORD') {
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.userId
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.password
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have Password set.`,
                  value: item.localId
                })
            }
            if (
              item.action &&
                ['GET_USER_DETAILS', 'GET_USER_ROLES'].includes(item.action)
            ) {
              delete item.properties.fields.email
              delete item.properties.fields.name
              delete item.properties.fields.roles
              delete item.properties.fields.apiKey
              delete item.properties.fields.password
              if (
                item.properties &&
                  item.properties.fields &&
                  !item.properties.fields.userId
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) doesn't have User ID set.`,
                  value: item.localId
                })
              if (
                item.properties &&
                  item.properties.fields &&
                  item.properties.fields.userId &&
                  !this.$options.filters.javaVariableConventionRules(
                    item.properties.fields.userId,
                    true
                  )
              )
                this.errorsNew.push({
                  text: `User step ${item.name} (local Id: ${item.localId}) User ID field is invalid.`,
                  value: item.localId
                })
            }
            break
          case 'JDBC':
            if (!item.name)
              this.errorsNew.push({
                text: `Jdbc step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.query)
              this.errorsNew.push({
                text: `Jdbc step ${item.name} (local Id: ${item.localId}) doesn't have Query field set.`,
                value: item.localId
              })
            if (
              item.properties.action &&
                item.properties.query &&
                [
                  'BEGIN TRANSACTION',
                  'COMMIT TRANSACTION',
                  'ROLLBACK TRANSACTION'
                ].includes(item.properties.action)
            ) {
              item.properties.action = 'CUSTOM'
            }
            break
          case 'MONGODB':
            if (!item.name)
              this.errorsNew.push({
                text: `MongoDb step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              ['FIND', 'UPDATE', 'DELETE'].includes(item.properties.action)
            ) {
              if (!item.properties.parameters.query)
                this.errorsNew.push({
                  text: `MongoDb step ${item.name} (local Id: ${item.localId}) doesn't have Query field set.`,
                  value: item.localId
                })
            }
            if (['INSERT', 'UPDATE'].includes(item.properties.action)) {
              if (!item.properties.parameters.queryUpdate)
                this.errorsNew.push({
                  text: `MongoDb step ${item.name} (local Id: ${item.localId}) doesn't have Query Update field set.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `MongoDb step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })

            if (!item.properties?.parameters?.skip) item.properties.parameters.skip = null
            if (!item.properties?.parameters?.limit) item.properties.parameters.limit = null
            if (!item.properties?.parameters?.sort) item.properties.parameters.sort = {
              field: null,
              direction: null
            }
            if (!item.properties?.parameters?.sort?.field) item.properties.parameters.sort.field = null
            if (!item.properties?.parameters?.sort?.direction) item.properties.parameters.sort.direction = null
            if (!item.properties?.parameters?.query) item.properties.parameters.query = null
            if (!item.properties?.parameters?.queryUpdate) item.properties.parameters.queryUpdate = null
            if (!item.properties?.parameters?.projection) item.properties.parameters.projection = null

            break
          case 'EMAIL':
            if (!item.name)
              this.errorsNew.push({
                text: `Email step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (!item.properties)
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) not valid.`,
                value: item.localId
              })
            if (!item.properties.credentialName)
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (!item.properties.textTemplate)
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Text Template set.`,
                value: item.localId
              })

            if (item.properties && !item.properties.to)
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have To field set.`,
                value: item.localId
              })

            if (item.properties && item.properties.to) {
              if (
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.to,
                  true
                ) &&
                  !this.emailRules(item.properties.to)
              )
                this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct To field.`,
                  value: item.localId
                })
            }

            if (item.properties && item.properties.from) {
              if (
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.from,
                  true
                ) &&
                  !this.emailRules(item.properties.from)
              )
                this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct From field.`,
                  value: item.localId
                })
            }

            if (item.properties.cc && item.properties.cc.length > 0) {
              item.properties.cc.forEach((ccItem) => {
                if (
                  !this.$options.filters.javaVariableConventionRules(
                    ccItem,
                    true
                  ) &&
                    !this.emailRules(ccItem)
                )
                  this.errorsNew.push({
                    text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct CC field.`,
                    value: item.localId
                  })
              })
            }

            if (item.properties.bcc && item.properties.bcc.length > 0) {
              item.properties.bcc.forEach((bccItem) => {
                if (
                  !this.$options.filters.javaVariableConventionRules(
                    bccItem,
                    true
                  ) &&
                    !this.emailRules(bccItem)
                )
                  this.errorsNew.push({
                    text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have correct BCC field.`,
                    value: item.localId
                  })
              })
            }

            if (
              !item.properties.variables ||
                (item.properties.variables &&
                  !item.properties.variables.subject)
            ) {
              this.errorsNew.push({
                text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have Subject variable set.`,
                value: item.localId
              })
            }

            if (item.properties.variables) {
              const tempVars = []

              for (const row in item.properties.variables) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.variables.hasOwnProperty(row)) {
                  tempVars.push({
                    text: row,
                    value: item.properties.variables[row]
                  })
                }
              }
              if (this.hasDuplicates(tempVars))
                this.errorsNew.push({
                  text: `Email step ${item.name} (local Id: ${item.localId}) has duplicate variable keys.`,
                  value: item.localId
                })
            }

            if (
              item.properties &&
                item.properties.variables &&
                !item.properties.variables.locale
            ) {
              delete item.properties.locale
            }
            if (
              item.properties &&
                item.properties.variables &&
                item.properties.variables.locale
            ) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            if (
              item.properties.attachments &&
                item.properties.attachments.length > 0
            ) {
              item.properties.attachments.forEach((x) => {
                if (!x.name)
                  this.errorsNew.push({
                    text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Name set.`,
                    value: item.localId
                  })
                if (!x.content)
                  this.errorsNew.push({
                    text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Content set.`,
                    value: item.localId
                  })
                if (!x.contentType)
                  this.errorsNew.push({
                    text: `Email step ${item.name} (local Id: ${item.localId}) doesn't have attachment Content Type set.`,
                    value: item.localId
                  })
              })
            } else {
              item.properties.attachments = null
            }
            break
          case 'SLACK':
            if (!item.name)
              this.errorsNew.push({
                text: `Slack step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Slack step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                !item.properties.message &&
                !item.properties.textTemplate
            )
              this.errorsNew.push({
                text: `Slack step ${item.name} (local Id: ${item.localId}) doesn't have Message or Template set.`,
                value: item.localId
              })
            if (item.properties.variables) {
              const tempVars = []

              for (const row in item.properties.variables) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.variables.hasOwnProperty(row)) {
                  tempVars.push({
                    text: row,
                    value: item.properties.variables[row]
                  })
                }
              }
              if (this.hasDuplicates(tempVars))
                this.errorsNew.push({
                  text: `Slack step ${item.name} (local Id: ${item.localId}) has duplicate variable keys.`,
                  value: item.localId
                })
            }
            if (
              item.properties &&
                item.properties.variables &&
                !item.properties.variables.locale
            ) {
              delete item.properties.locale
            }
            if (
              item.properties &&
                item.properties.variables &&
                item.properties.variables.locale
            ) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            if (
              item.properties &&
                item.properties.message &&
                !this.$options.filters.checkComplexRegex(
                  item.properties.message
                )
            )
              this.errorsNew.push({
                text: `Slack step ${item.name} (local Id: ${item.localId}) Message field is invalid.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.channelId &&
                !this.$options.filters.checkComplexRegex(
                  item.properties.channelId
                )
            )
              this.errorsNew.push({
                text: `Slack step ${item.name} (local Id: ${item.localId}) Channel Id field is invalid.`,
                value: item.localId
              })
            break
          case 'JWT':
            if (!item.name)
              this.errorsNew.push({
                text: `Jwt step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Jwt step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `Jwt step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            break
          case 'FOREACH':
            if (!item.name)
              this.errorsNew.push({
                text: `ForEach step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.query)
              this.errorsNew.push({
                text: `ForEach step ${item.name} (local Id: ${item.localId}) doesn't have Query.`,
                value: item.localId
              })
            if (item.properties && !item.properties.recordName)
              this.errorsNew.push({
                text: `ForEach step ${item.name} (local Id: ${item.localId}) doesn't have Record Name field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.recordName &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.recordName,
                  true
                )
            )
              this.errorsNew.push({
                text: `ForEach step ${item.name} (local Id: ${item.localId}) Record Name is invalid.`,
                value: item.localId
              })
            if (
              (item.properties &&
              item.properties.steps &&
              item.properties.steps.length === 0) ||
              (item.properties && !item.properties.steps)
            )
              this.errorsNew.push({
                text: `ForEach step ${item.name} (local Id: ${item.localId}) needs at least one step.`,
                value: item.localId
              })
            break
          case 'EXECUTE_PROCESS':
            if (!item.name)
              this.errorsNew.push({
                text: `Execute process step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.childEventIdPath &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.childEventIdPath,
                  true
                )
            )
              this.errorsNew.push({
                text: `Execute process ${item.name} (local Id: ${item.localId}) Child event ID target object is invalid.`,
                value: item.localId
              })

            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
                value: item.localId
              })

            // Not all Execute processes need to have input
            // if (
            //   (item.properties && !item.properties.input) ||
            //     (item.properties &&
            //       item.properties.input &&
            //       Object.keys(item.properties.input).length === 0)
            // )
            //   this.errorsNew.push({
            //     text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have any Input set.`,
            //     value: item.localId
            //   })

            if (item.properties && item.properties.input) {
              for (const row in item.properties.input) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.input.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Execute process ${item.name} (local Id: ${item.localId}) Input key '${row}' is invalid.`,
                      value: item.localId
                    })
                }
              }
            }

            if (
              item.properties.action &&
                item.properties.action !== 'ASYNC'
            ) {
              if (item.properties && item.properties.output) {
                for (const row in item.properties.output) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (item.properties.output.hasOwnProperty(row)) {
                    if (
                      !this.$options.filters.javaVariableConventionRules(
                        row,
                        true
                      )
                    )
                      this.errorsNew.push({
                        text: `Execute process ${item.name} (local Id: ${item.localId}) Output key '${row}' is invalid.`,
                        value: item.localId
                      })
                  }
                }
              }
            }

            if (
              item.properties.action &&
                item.properties.action === 'INLINE'
            ) {
              delete item.properties.priority
            }

            getProcesses({ name: item.properties.processName })
              .then((res) => {
                let foundValidations = null
                const localProcess = res.data.data.items.find((x) => x.name === item.properties.processName)

                if (!localProcess) {
                  this.showExecuteInputs = true

                  return
                }

                getProcess({ id: localProcess.id })
                  .then(async (res) => {
                    if (res?.data?.data?.inputValidationRuleId) {
                      foundValidations = await this.fetchValidationRecursive(
                        res.data.data.inputValidationRuleId
                      )

                      if (item.properties && item.properties.input) {
                        const inputKeys = Object.keys(item.properties.input)
                        const containsAllValidations = foundValidations.every(
                          (key) => inputKeys.includes(key)
                        )

                        if (!containsAllValidations) {
                          this.errorsNew.push({
                            text: `Execute process ${item.name} (local Id: ${item.localId}) doesn't have all required Input fields  set.`,
                            value: item.localId
                          })
                        }
                      }
                    } else {
                      this.showExecuteInputs = true
                    }
                  })
                  .catch((err) => {
                    this.isLoadingProcesses = false
                    this.addSnackbar({
                      message: err,
                      timeout: 5000,
                      color: 'error'
                    })                   })
              })
              .catch((err) => {
                this.isLoadingProcesses = false
                this.addSnackbar({
                  message: err,
                  timeout: 5000,
                  color: 'error'
                })              })
            break
          case 'REST':
            if (!item.name)
              this.errorsNew.push({
                text: `Rest step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Action field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.timeout)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Timeout field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Target Object field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetUrl)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Target URL field set.`,
                value: item.localId
              })
            if (item.properties && item.properties.targetUrl) {
              const first4 = item.properties.targetUrl.substring(0, 4)

              if (first4 === 'http')
                item.properties.targetUrl = `"${item.properties.targetUrl}"`
            }
            if (item.properties && item.properties.bodyScript) {
              item.properties.body = null
            }
            if (
              item.properties &&
                item.properties.credentialName &&
                item.properties.credentialName.name
            ) {
              item.properties.credentialName =
                  item.properties.credentialName.name
            }
            if (item.properties && !item.properties.encoding)
              this.errorsNew.push({
                text: `Rest step ${item.name} (local Id: ${item.localId}) doesn't have Encoding field set.`,
                value: item.localId
              })
            if (item.properties && item.properties.authType === 'NONE') item.properties.authType = null
            if (item.properties && item.properties.authType === '') item.properties.authType = null
            break
          case 'TWILIO':
            if (!item.name)
              this.errorsNew.push({
                text: `Twilio step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.credentialName)
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.to)
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have To field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.to &&
                !this.$options.filters.checkComplexRegex(item.properties.to)
            )
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) To field is invalid.`,
                value: item.localId
              })
            if (item.properties && !item.properties.from)
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have From field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.from &&
                !this.$options.filters.checkComplexRegex(item.properties.from)
            )
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) From field is invalid.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
            if (
              item.properties &&
                !item.properties.message &&
                !item.properties.textTemplate
            )
              this.errorsNew.push({
                text: `Twilio step ${item.name} (local Id: ${item.localId}) doesn't have Message or Template set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.variables &&
                !item.properties.variables.locale
            ) {
              delete item.properties.locale
            }
            if (
              item.properties &&
                item.properties.variables &&
                item.properties.variables.locale
            ) {
              item.properties.locale = item.properties.variables.locale
              delete item.properties.variables.locale
            }
            break
          case 'WHILE':
            if (!item.name)
              this.errorsNew.push({
                text: `While step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (!item.properties.query)
              this.errorsNew.push({
                text: `While step ${item.name} (local Id: ${item.localId}) doesn't have Query.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `While step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
            break
          case 'SWITCH':
            if (!item.name)
              this.errorsNew.push({
                text: `Switch step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.scriptEngine)
              this.errorsNew.push({
                text: `Switch step ${item.name} (local Id: ${item.localId}) doesn't have Script Engine field set.`,
                value: item.localId
              })
              // eslint-disable-next-line no-case-declarations
            let hasElse = 0

            item.properties.conditions.forEach((query) => {
              if (query.query === 'else') hasElse++
              if (hasElse > 1)
                this.errorsNew.push({
                  text: `Switch step ${item.name} (local Id: ${item.localId}) has multiple else-conditions.`,
                  value: item.localId
                })
              if (!query.query)
                this.errorsNew.push({
                  text: `Switch step ${item.name} (local Id: ${item.localId}) Query Q${query.localId} doesn't have Query set.`,
                  value: item.localId
                })
              if (query.steps && query.steps.length === 0)
                this.errorsNew.push({
                  text: `Switch step ${item.name} (local Id: ${item.localId}) Query Q${query.localId} doesn't have any Steps set.`,
                  value: item.localId
                })
            })
            break
          case 'PROCESS_SETTING':
            if (!item.name)
              this.errorsNew.push({
                text: `Process Setting step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.processSettingName)
              this.errorsNew.push({
                text: `Process Setting step ${item.name} (local Id: ${item.localId}) doesn't have Setting Name set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.targetObject)
              this.errorsNew.push({
                text: `Process Setting step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                value: item.localId
              })
            if (
              item.properties &&
                item.properties.targetObject &&
                !this.$options.filters.javaVariableConventionRules(
                  item.properties.targetObject,
                  true
                )
            )
              this.errorsNew.push({
                text: `User step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            break
          case 'PLUGIN':
            if (!item.name)
              this.errorsNew.push({
                text: `Plugin step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.action)
              this.errorsNew.push({
                text: `Plugin step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (item.properties && !item.properties.pluginName)
              this.errorsNew.push({
                text: `Plugin step ${item.name} (local Id: ${item.localId}) doesn't have Plugin field set.`,
                value: item.localId
              })
            if (item.properties && item.properties.input) {
              for (const row in item.properties.input) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.input.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Plugin step ${item.name} (local Id: ${item.localId}) Input key '${row}' is invalid.`,
                      value: item.localId
                    })
                }
              }
            }
            if (item.properties && item.properties.output) {
              for (const row in item.properties.output) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.output.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Plugin step ${item.name} (local Id: ${item.localId}) Output key '${row}' is invalid.`,
                      value: item.localId
                    })
                }
              }
            }
            break
          case 'JS':
            if (!item.name)
              this.errorsNew.push({
                text: `JavaScript step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              (item.properties && !item.properties.set) ||
                (item.properties &&
                  item.properties.set &&
                  Object.keys(item.properties.set).length === 0)
            )
              this.errorsNew.push({
                text: `JavaScript step local Id: ${item.localId} doesn't have Values set.`,
                value: item.localId
              })
            if (item.properties && item.properties.set) {
              for (const row in item.properties.set) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.set.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `JavaScript step ${item.name} (local Id: ${item.localId}) Variable key '${row}' is invalid.`,
                      value: item.localId
                    })
                    // Removing for now, until we figure out complete validation - MD - 23.08.2022
                    // if (!this.$options.filters.checkComplexRegex(item.properties.set[row])) this.errorsNew.push({ text: `JavaScript step ${item.name} (local Id: ${item.localId}) Set value '${row}' is invalid.`, value: item.localId })
                }
              }
            }
            break
          case 'GROOVY':
            if (!item.name)
              this.errorsNew.push({
                text: `Groovy step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (
              (item.properties && !item.properties.set) ||
                (item.properties &&
                  item.properties.set &&
                  Object.keys(item.properties.set).length === 0)
            )
              this.errorsNew.push({
                text: `Groovy step local Id: ${item.localId} doesn't have Values set.`,
                value: item.localId
              })
            if (item.properties && item.properties.set) {
              for (const row in item.properties.set) {
                // eslint-disable-next-line no-prototype-builtins
                if (item.properties.set.hasOwnProperty(row)) {
                  if (
                    !this.$options.filters.javaVariableConventionRules(
                      row,
                      true
                    )
                  )
                    this.errorsNew.push({
                      text: `Groovy step ${item.name} (local Id: ${item.localId}) Variable key '${row}' is invalid.`,
                      value: item.localId
                    })
                    // Removing for now, until we figure out complete validation - MD - 23.08.2022
                    // if (!this.$options.filters.checkComplexRegex(item.properties.set[row])) this.errorsNew.push({ text: `Groovy step ${item.name} (local Id: ${item.localId}) Set value '${row}' is invalid.`, value: item.localId })
                }
              }
            }
            break
          case 'CLOUD_FRONT':
            if (!item.name)
              this.errorsNew.push({
                text: `CloudFront step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (!item?.properties?.credentialName)
              this.errorsNew.push({
                text: `CloudFront step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (!item?.properties?.action)
              this.errorsNew.push({
                text: `CloudFront step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if (item?.properties?.action === 'DELETE' || item?.properties?.action === 'UPDATE') {
              if (!item?.properties?.fields?.distributionId)
                this.errorsNew.push({
                  text: `CloudFront step ${item.name} (local Id: ${item.localId}) doesn't have Distribution ID set.`,
                  value: item.localId
                })
            }
            if (item?.properties?.action === 'CREATE') {
              if (!item?.properties?.fields?.domainName)
                this.errorsNew.push({
                  text: `CloudFront step ${item.name} (local Id: ${item.localId}) doesn't have Domain Name set.`,
                  value: item.localId
                })
              if (!item?.properties?.fields?.domainId)
                this.errorsNew.push({
                  text: `CloudFront step ${item.name} (local Id: ${item.localId}) doesn't have Domain ID set.`,
                  value: item.localId
                })
            }
            if (!item?.properties?.fields?.targetObject) {
              this.errorsNew.push({
                text: `CloudFront step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                value: item.localId
              })
            } else if (!this.$options.filters.javaVariableConventionRules(
              item.properties.fields.targetObject,
              true
            )) {
              this.errorsNew.push({
                text: `CloudFront step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                value: item.localId
              })
            }
            break
          case 'OUTLOOK_CALENDAR':
            if (!item.name)
              this.errorsNew.push({
                text: `Outlook Calendar step local Id: ${item.localId} doesn't have Name field set.`,
                value: item.localId
              })
            if (!item?.properties?.credentialName)
              this.errorsNew.push({
                text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                value: item.localId
              })
            if (!item?.properties?.action)
              this.errorsNew.push({
                text: `Outlook Calendar step local Id: ${item.localId} doesn't have Action field set.`,
                value: item.localId
              })
            if ((item?.properties?.action === 'DELETE'
              || item?.properties?.action === 'UPDATE')
                && !item?.properties?.fields?.eventId)
              this.errorsNew.push({
                text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Event ID set.`,
                value: item.localId
              })

            if ((item?.properties?.action === 'DELETE'
                || item?.properties?.action === 'CREATE'
                || item?.properties?.action === 'UPDATE')
                  && !item?.properties?.fields?.userId)
              this.errorsNew.push({
                text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have User ID set.`,
                value: item.localId
              })

            if (item?.properties?.action === 'CREATE' || item?.properties?.action === 'UPDATE') {
              if (!item?.properties?.fields?.subject)
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Credential Name set.`,
                  value: item.localId
                })
              if (!item?.properties?.fields?.body?.content)
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Content set.`,
                  value: item.localId
                })
              if (!item?.properties?.fields?.attendees?.length) {
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have any Attendees set.`,
                  value: item.localId
                })
              } else {
                item.properties.fields.attendees.forEach((attendee) => {
                  if (!attendee.address)
                    this.errorsNew.push({
                      text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) Attendee doesn't have Address set.`,
                      value: item.localId
                    })
                  if (!attendee.name)
                    this.errorsNew.push({
                      text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) Attendee doesn't have Name set.`,
                      value: item.localId
                    })
                })
              }
              if (!item?.properties?.fields?.start?.dateTime)
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Start Time set.`,
                  value: item.localId
                })
              if (!item?.properties?.fields?.end?.dateTime)
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have End Time set.`,
                  value: item.localId
                })
            }
            if (properties.action === 'LIST') {
              if (!item?.properties?.fields?.targetObject) {
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) doesn't have Target Object set.`,
                  value: item.localId
                })
              } else if (!this.$options.filters.javaVariableConventionRules(
                item.properties.fields.targetObject,
                true
              )) {
                this.errorsNew.push({
                  text: `Outlook Calendar step ${item.name} (local Id: ${item.localId}) Target Object is invalid.`,
                  value: item.localId
                })
              }
            }
            break
          default:
          }

          if (errorItemsLength !== this.errorsNew.length) {
            this.errorItems.push(item)
            errorItemsLength = this.errorsNew.length
          }

          if (
            item.properties &&
            item.properties.steps &&
            item.properties.steps.length > 0
          ) {
            checkSteps(item.properties.steps)
          }
          if (
            item.properties?.try?.steps &&
            item.properties.try.steps.length > 0
          ) {
            checkSteps(item.properties.try.steps)
          }
          if (
            item.properties?.finally?.steps &&
            item.properties.finally.steps.length > 0
          ) {
            checkSteps(item.properties.finally.steps)
          }
          if (
            item.properties &&
            item.properties.conditions &&
            item.properties.conditions.length > 0
          ) {
            item.properties.conditions.forEach((item2) => {
              checkSteps(item2.steps)
            })
          }
          if (
            item.properties &&
            item.properties.catch &&
            item.properties.catch.length > 0
          ) {
            item.properties.catch.forEach((item2) => {
              checkSteps(item2.steps)
            })
          }
        }
      }

      checkSteps(this.process.steps.steps)

      await pause(2000)

      return errors
    },
    // TODO chenge to the function in helpers
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                testEmail.trim().toLowerCase()
              )
          }
        })

        if (correct) return true

        return false
      } else {
        return (
          v &&
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v.toLowerCase()
          )
        )
      }
    },
    handleKeyDown(event) {
      const focusedElement = document.activeElement

      if (focusedElement.tagName === 'INPUT'
      || focusedElement.tagName === 'TEXTAREA'
      || focusedElement.isContentEditable) {
        // Don't make custom undo or redo
        // if a text field or contenteditable element is focused
        return
      }
      if (event.ctrlKey && event.key === 'z') {
        this.undo()
      } else if (event.ctrlKey && event.key === 'y') {
        this.redo()
      }
    },
    undo() {
      this.$store.dispatch('app/undoProcess')
      delete this.process.steps
      this.afterUndoOrRedo = true
      this.process = merge( { ...this.process }, { ...this.processCurrent } )
      if (this.process?.steps?.steps && this.selectedItem) {
        this.selectedItem = this.process.steps.steps.find((step) => step.id === this.selectedItem.id)
      }
    },
    redo() {
      this.$store.dispatch('app/redoProcess')
      delete this.process.steps
      this.afterUndoOrRedo = true
      this.process = merge( { ...this.process }, { ...this.processCurrent } )
      if (this.process?.steps?.steps && this.selectedItem) {
        this.selectedItem = this.process.steps.steps.find((step) => step.id === this.selectedItem.id)
      }
    },
    async submit(isInDialog = false) { // we will not need this argument, see next line
      // We will not need it after BE will handle triggers renaming
      if  (this.isEdit && this.originalProcessNameOnEdit !== this.process.name) {
        await this.fetchActiveTriggersForProcess()

        if (this.connectedTriggers.length && !isInDialog) {
          this.showChangedName = true

          return
        } else {
          this.showChangedName = false
        }
      }
      // End of what we will not need

      this.isError = ''
      this.isErrorShow = false
      this.lock = true
      this.loading = true

      if (this.process.name.length < 2 || this.process.name.length > 250) {
        this.isError = this.$lang.errors.nameTooShortOrLong
        this.isErrorShow = true
        this.lock = false
        this.loading = false

        return
      }

      const isError = await this.checkReqFields()

      if (isError || this.errorsNew.length > 0) {
        this.isError = isError
        this.isErrorShow = true
        this.lock = false
        this.loading = false

        return
      }

      const doesNameExists = await doesProcessNameExist(this.process.name)

      if (doesNameExists && doesNameExists.id !== this.process.id) {
        const errMessage = `Resource PROCESS with key (name)=(${this.process.name}) already exists.`

        this.addSnackbar({
          message: errMessage,
          timeout: 8000,
          color: 'error'
        })

        this.lock = false
        this.loading = false

        return
      }

      if (
        Number(this.process.simultaneousExecutionsPerInstance) >
        Number(this.process.overallSimultaneousExecutions)
      ) {
        this.isError = this.$lang.errors.mustLessExecutions
        this.isErrorShow = true
        this.lock = false
        this.loading = false

        return
      }

      let res = null

      const { id } = this.process

      if (!this.isEdit) {
        this.process.status = 'ACTIVE'
      }

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.process.roles = tempRoles

      this.process.logsTtlInMSec = this.$options.filters.unitFormatter(
        this.tempLogsTtlInMSec,
        this.timeUnitTtl,
        'MSec'
      )
      this.process.errorsTtlInMSec = this.$options.filters.unitFormatter(
        this.tempErrorsTtlInMSec,
        this.timeUnitErrTtl,
        'MSec'
      )
      this.process.maxProcessingDurationInMSec =
        this.$options.filters.unitFormatter(
          this.tempMaxProcessingDurationInMSec,
          this.timeUnitPDur,
          'MSec'
        )

      if (Number(this.process.maxProcessingDurationInMSec) > 604800000 || Number(this.process.maxProcessingDurationInMSec) < 500) {
        const maxProcessingDurationInSelectedUnit = this.$options.filters.unitFormatter(
          604800000,
          'MSec',
          this.timeUnitPDur
        )

        const minProcessingDurationInSelectedUnit = this.$options.filters.unitFormatter(
          500,
          'MSec',
          this.timeUnitPDur
        )

        const errorMessage = 'Max processing duration must be between ' + minProcessingDurationInSelectedUnit + ' and ' + maxProcessingDurationInSelectedUnit + ' ' + this.timeUnitPDur

        this.addSnackbar({
          message: errorMessage,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.loading = false

        return
      }

      if (
        Number(this.process.logsTtlInMSec) <
        Number(this.process.maxProcessingDurationInMSec) ||
        Number(this.process.errorsTtlInMSec) <
        Number(this.process.maxProcessingDurationInMSec)
      ) {
        this.addSnackbar({
          message: this.$lang.errors.logsTimeLessThanMax,
          timeout: 8000,
          color: 'error'
        })
        this.lock = false
        this.loading = false

        return
      }

      this.process.steps.steps = await this.castAllStepsValues(this.process.steps.steps)

      try {
        res = this.isEdit
          ? await updateProcess({
            id,
            body: this.process
          })
          : await createProcess({ body: this.process })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {
            // The below two functions are a rough solution, when time allows, may be united to one
            const getTheLastErrorMessage = (error) => {
              const parsedError = safeJSONParse(error)

              if (parsedError?.message) {
                return getTheLastErrorMessage(parsedError.message)
              } else {
                return error
              }
            }
            const getTheLastErrorStepId = (error) => {
              const parsedError = error.error ? safeJSONParse(error.error) : safeJSONParse(error)

              if (parsedError?.error?.includes('stepId') || parsedError?.message?.includes('stepId')) {
                return getTheLastErrorStepId(parsedError.message)
              } else {
                return parsedError?.stepId || error.stepId || null
              }
            }

            res?.response?.data?.data?.forEach((error) => {
              this.errorsNew.push({
                text: getTheLastErrorMessage(error.error),
                value: getTheLastErrorStepId(error)
              })
            })
            if (!this.errorsNew || this.errorsNew.length === 0) {
              this.errorsNew.push({
                text: this.$lang.errors.processCreate,
                value: 0
              })
            }
            this.isErrorShow = true

          } else {
            const errMessage =
              res?.response?.data?.statusText ||
              (this.isEdit
                ? this.$lang.errors.processUpdate
                : this.$lang.errors.processCreate)

            this.addSnackbar({
              message: errMessage ? errMessage : this.$lang.errors.processCreate,
              timeout: 5000,
              color: 'error'
            })
          }

          this.lock = false
          this.loading = false

          return
        }
        const successMessage = this.isEdit
          ? this.$lang.success.processUpdated
          : this.$lang.success.processCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.lock = false
        this.loading = false

        // Enable after BE changes will be done (and then noo need to show triggers window before submit)
        // if  (this.isEdit && this.originalProcessNameOnEdit !== this.process.name) {
        //   this.showChangedName = true
        //   this.fetchActiveTriggersForProcess()
        // }

        setTimeout(() => {
          this.originalProcess = JSON.parse(JSON.stringify(this.process))
          this.originalProcessNameOnEdit = this.process.name
          this.isDirty = false
        }, 100)

        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'processEdit',
            params: {
              id: res.data.data.id
            }
          })
        }
      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
        this.loading = false
      }
    },
    async changeStatus() {
      this.lock = true
      try {
        const res =
          this.process.status === 'ACTIVE'
            ? await disableProcess({ id: this.process.id })
            : await enableProcess({ id: this.process.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.processStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage =
          this.process.status !== 'ACTIVE'
            ? this.$lang.success.processActivated
            : this.$lang.success.processDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.process.status = res.data.data.status

        this.yesNoDisableShow = false
        this.processToDisable = null
        this.triggerListToDeactivate = []

        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>
<style lang="scss">

.grayOnHover:hover{
  background-color: var(--v-customBtnBgColor-base) !important;
}

.process-details-container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  .container {
    padding-right: 2px !important;
  }

  .border-me {
    position: absolute;
    left: 10px;
    top: 2px;
    height: 106%;
    background-color: var(--v-primary-base);
    opacity: 0.3;
    border-radius: 1px;
  }

  .process-name {
    color: var(--v-primary-base);
    cursor: pointer;
    font-size: 18px !important;
    padding-left: 0.5rem;
    line-height: 2.2rem;
  }

  .is-log-selected-dark {
    background: var(--v-surface-base) 0 0 no-repeat padding-box !important;
    background-color: var(--v-customStepLogSelectBg-darken2) !important;

    td > div > span {
      color: var(--v-primary-lighten2) !important;
    }
  }

  .is-log-selected-light {
    background: var(--v-surface-base) 0 0 no-repeat padding-box !important;
    background-color: var(--v-customStepLogSelectBg-lighten2) !important;

    td > div > span {
      color: var(--v-primary-darken2) !important;
    }
  }

  .table-bg {
    background-color: var(--v-surface-base) !important;
  }

  .bland-bg {
    background-color: var(--v-customDrawerBg-base) !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .error-highlight {
    &:hover {
      opacity: 0.6;
    }
  }

  .this-screen-max-height {
    max-height: 100% !important;
    overflow-y: hidden;
  }

  .v-main {
    overflow: hidden !important;
    padding-bottom: 0 !important;
  }

  .toggle-left {
    position: absolute;
    z-index: 2;
    left: 8px;
    top: 8px;
    border-radius: 20px;
    background: transparent;
    padding: 2px;
    cursor: pointer;
  }

  .toggle-right {
    position: absolute;
    z-index: 2;
    left: -14px;
    top: 26px;
    border-radius: 20px;
    background: transparent;
    padding: 2px;
    cursor: pointer;
  }

  .toggle-right-list {
    transition: all 0.2s ease;
    min-width: 538px;
    width: 33%;
    position: relative;
    overflow-y: auto;

    &.is-close {
      width: 40px !important;
      min-width: 40px;
      overflow-y: hidden;
    }

    & [class*="_container"] {
      max-height: calc(100vh - 202px);
    }
  }

  .toggle-menu {
    .v-size--default {
      width: 28px !important;
      height: 28px !important;
    }
  }

  .visibility-hidden {
    visibility: hidden;
    width: 0 !important;
    max-width: 0 !important;
    overflow: hidden;
  }

  .process-container {
    height: calc(100vh - 70px) !important;
    overflow-y: hidden;
  }

  @media screen and (max-width: 1510px) {
    .flex-fill {
      min-width: 110px !important;
    }

    .toggle-right-list {
      transition: all 0.2s ease;
      min-width: 538px;
      width: 33%;
      position: relative;
      overflow-y: auto;
    }
  }

  .mermaid-style {
    background-color: var(--v-timelineBg-base) !important;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;
  }

  #expansionProcessAdvanced {
    .v-expansion-panel-header {
      padding: 12px 6px !important;
    }

    .v-expansion-panel-content__wrap {
      padding: 8px 8px !important;
    }
  }

  .no-box-shadow {
    box-shadow: none !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel {
    border: none !important;
  }

  .v-expansion-panel::before {
    box-shadow: none !important;
  }

  .v-messages__message {
    font-size: 10px;
    padding-top: 2px;
  }

  #expansionProcessAdvanced .v-expansion-panel-header {
    padding: 12px 0 !important;
  }

  .process-button,
  .events-button {
    margin: 2px 8px 0 8px;
    padding: 0 !important;
    color: var(--v-primary-base) !important;
    background-color: unset !important;
    box-shadow: none;
  }

  .v-btn:before {
    background-color: transparent;
  }

  .btn-size {
    width: 90px !important;
  }

  .v-main__wrap {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }

  .v-text-field input {
    font-size: 14px !important;
  }

  .v-text-field input::placeholder {
    font-size: 14px !important;
  }

  .v-text-field--outlined.v-input--dense .v-label {
    font-size: 14px !important;
  }

  .v-label {
    font-size: 14px !important;
  }

  .copy-name-icon .v-icon {
    color: #009fb7;
  }

  .v-select.v-input--dense .v-select__selection--comma {
    margin: 0 !important;
  }

  .v-input {
    font-size: 12px !important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel--active {
    border: 0 !important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel {
    border: 0 !important;
  }

  #expansionProcessAdvanced .v-expansion-panel-header {
    padding: 0 !important;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel--active {
    background-color: transparent !important;
  }

  .theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel--active {
    background-color: transparent !important;
  }

  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent !important;
  }
}

.actions-btn {
  background-color: #fff;

  &-container {
    min-width: 435px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .actions-btn-container {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
