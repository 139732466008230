<template>
  <v-card class="pa-2">
    <v-card-title :class="title ? 'pb-2' : 'w-100'">
      <span v-if="title">{{ title }}</span>
      <v-spacer v-if="title"></v-spacer>
      <v-btn
        icon
        color="primary"
        small
        class="ml-auto"
        @click="$emit('closeDialog', true)"
      >
        <v-icon size="22">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="loading" class="pl-1 pb-2">
      <v-row no-gutters>
        <v-col cols="2">
          <v-progress-circular indeterminate color="primary"/>
        </v-col>
        <v-col cols="8">
          {{ loadingText || $lang.actions.loading }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else class="pb-2">{{ actionText }}</v-card-text>
    <v-row v-if="!loading" no-gutters justify="space-between">
      <v-btn
        class="d-flex align-center justify-start"
        color="error"
        @click="$emit('closeDialog', true)"
      >
        <v-icon dark size="20" class="mr-2">mdi-close</v-icon>
        {{ $lang.actions.no }}
      </v-btn>
      <v-btn
        class="d-flex align-center justify-start"
        outlined
        color="primary"
        @click="$emit('submit', true)"
      >
        <v-icon dark size="20" class="mr-2">mdi-check</v-icon>
        {{ $lang.actions.yes }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: () => true
    },
    title: {
      type: String,
      default: () => ''
    },
    loadingText: {
      type: String,
      default: () => ''
    },
    actionText: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {}
  }
}
</script>
